@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-grey: grey;
  --color-light-grey: #eee;
  --color-med-grey: #aaa;
  --color-dark-red: rgb(114, 28, 89);
  --color-light-red: rgb(248, 215, 218);
  --color-blue: rgb(13, 110, 253);
}

/* .sidebar_container {
  padding-top: 2rem;
  background-color: #2f4050;
  color: #808080;
  max-width: 215px;
  width: 100%;
  min-height: 100vh;
}

.sidebar li {
  width: 100%;
  padding: 1rem;
}

.sidebar li:hover {
  background-color: #293846;
  border-left: 5px solid rgb(3, 97, 3);
}
.sidebar li:hover a {
  color: #fff;
}

.sidebar li > * {
  text-decoration: none;
  color: #a7b1c2;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #efefef;
  list-style-type: none;
  padding: 1.5rem 0;
  font-size: 1.1rem;
}

.navbar li {
    margin: 0 1.5rem;
}

.navbar li > * {
  text-decoration: none;
  color: rgb(108,117,125);
}

.link-side {
  display: block;
}

.link-side:hover {
  color: #93a5c5;
}

.Layout {
  display: flex;
  position: relative;
  width: 100%;
}

.Authpage { */
/* background-color: var(--color-light-grey); */
/* }

.logout-btn {
  position: absolute;
  right: 1rem;
}

.logout-color {
  color: red !important;
}
.logout-color:hover {
  color: rgb(110, 6, 6) !important;
}

.outlet {
  max-width: calc(100% - 338px);
  width: 100%;
}

.main-content-section {
  padding: 0 0 0 30px;
}

.icon-margin {
  margin-right: 0.5rem;
} */

/* common form input css start */

.zu_common_form_input_content {
  position: relative;
}
.zu_common_form_input_content .zu_common_form_input_label {
  color: #4b4279;
  display: block;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 5px;
}
.zu_common_form_input_content .zu_common_form_input {
  border-radius: 0px;
  background: #ffffff;
  font-family: "Proxima-Nova-Regular";
  color: #212121;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  font-size: 16px;
  padding: 16px 12px;
  width: 100%;
  outline: 0;
  /* border-color: transparent; */
  /* border-radius: 16px; */
  /* color: #5f0166; */
}

.zu_email_text {
  color: #7a7a7a !important;
}
.zu_common_form_input_content .zu_common_form_input::placeholder {
  color: #b3b8bd;
}
.zu_common_form_input_content .zu_common_form_input_btn {
  right: 25px;
  top: 50%;
  border: 0;
  width: 21px;
  height: 21px;
  background: transparent;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.zu_common_form_input_content .zu_common_form_input_btn svg {
  width: 21px;
  height: 21px;
}

.zu_common_form_input_content select.zu_common_form_input {
  background-image: url(/static/media/BottomArrow.d2ca9f24.svg);
  filter: hue-rotate(260deg);
  background-size: 16px 9px;
  background-repeat: no-repeat;
  background-position: right 16px center;
  padding-right: 46px;
  box-shadow: none;
  border-color: #e7e6e9 !important;
}
.zu_common_form_input_content.zu_common_form_button_input_content
  .zu_common_form_input {
  padding-right: 55px;
}

.zu_common_form_check_box label {
  position: relative;
  color: #4b4279;
  font-family: "Proxima-Nova-Bold";
  font-size: 20px;
  padding-left: 32px;
  cursor: pointer;
}

.zu_common_form_check_box label::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  outline: 2px solid #4b4279;
  background: #fff;
  outline-offset: 0;
}
.zu_common_form_check_box input:checked ~ label::before {
  left: 5px;
  top: 10px;
  width: 10px;
  height: 10px;
  outline: 5px solid #4b4279;
}

.zu_common_form_input_content .zu_common_form_input_check_box_row {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}
.zu_common_form_input_content
  .zu_common_form_input_check_box_row
  .zu_common_form_check_box {
  margin: 0 10px;
}

/* common form input css end */

/* sidebar css start */

.zu_sidebar_bg_overlay {
  position: fixed;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d17cc;
  z-index: 999;
  opacity: 73%;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all 0.3s ease-in-out;
  display: none;
}

.zu_open_sidebar .zu_sidebar_bg_overlay {
  left: 0;
}

.zu_sidebar_section {
  position: fixed;
  left: 0px;
  top: 0px;
  background: #ffffff;
  max-width: 275px;
  width: 100%;
  min-height: 100vh;
  /* box-shadow: 0px 4px 20px 0px #25265e1a; */
  border-right: 1px solid #e2e2e2;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}
.zu_sidebar_content .zu_sidebar_logo {
  padding: 15px 30px;
}
.zu_sidebar_content .zu_sidebar_logo a {
  display: block;
  height: 55px;
}
.zu_sidebar_content .zu_sidebar_logo a svg {
  width: 100%;
  height: 100%;
}
.zu_sidebar_content .zu_sidebar_nav {
  list-style: none;
  padding: 0px;
  margin: 0px;
  max-height: calc(100vh - 85px);
  min-height: calc(100vh - 85px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link {
  padding: 18px 30px;
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-family: "Proxima-Nova-Regular";
  background: transparent;
  border: 0;
  width: 100%;
  position: relative;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link
  .zu_sidebar_link_pandding_msg {
  background: #f00000;
  min-width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 20px;
  color: #ffffff;
  position: absolute;
  left: 22px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 5px;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link span {
  margin-left: 16px;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link svg {
  width: 26px;
  height: 26px;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link svg path {
  transition: all 0.2s ease-in-out;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link.active,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link:hover,
.zu_sidebar_bottom_link .active {
  background: #5f0166;
  color: #ffffff;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path,
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path {
  fill: #ffffff;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path[fill="#6ec49a"],
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path[fill="#6ec49a"] {
  fill: #6ec49a;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path[stroke="#000000"],
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path[stroke="#000000"] {
  stroke: #ffffff;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path[stroke="#6ec49a"],
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path[stroke="#6ec49a"] {
  stroke: #6ec49a;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link {
  margin-top: auto;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link a,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link button {
  padding: 15px 30px;
  display: block;
  color: #000000;
  font-size: 15px;
  font-family: "Proxima-Nova-Bold";
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link a:hover,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link button:hover,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link .active {
  background: #5f0166;
  color: #ffffff;
}

.zu_top_header_toggal_close_btn {
  background: transparent;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 25px;
  top: 29px;
  background: #4b4279;
  border-radius: 3px;
  padding: 8px;
}
.zu_top_header_toggal_close_btn svg {
  width: 100%;
  height: 100%;
}

/* sidebar css end */

/* top header css start */

.zu_top_header_section .zu_top_header_logo {
  max-width: 175px;
  width: 100%;
}
.zu_top_header_section .zu_top_header_toggal_btn {
  margin-left: auto;
  width: 30px;
  height: 22px;
  background: transparent;
  border: 0;
  position: relative;
}
.zu_top_header_section .zu_top_header_toggal_btn span,
.zu_top_header_section .zu_top_header_toggal_btn span::before,
.zu_top_header_section .zu_top_header_toggal_btn span::after {
  background: #5f0166;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  display: block;
}
.zu_top_header_section .zu_top_header_toggal_btn span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.zu_top_header_section .zu_top_header_toggal_btn span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
.zu_top_header_section .zu_top_header_logo,
.zu_top_header_section .zu_top_header_toggal_btn {
  display: none;
}

.zu_top_header_section {
  background: #ffffff;
  width: calc(100% - 275px);
  box-shadow: 0px 4px 20px 0px #25265e1a;
  padding: 20px 30px;
  margin-left: auto;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.zu_top_header_section .zu_top_header_user_dropdown_menu {
  padding-left: 30px;
  position: relative;
}
.zu_top_header_section .zu_top_header_user_dropdown_menu::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 108px;
  background: #f1f1f5;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 0px;
  border-radius: 0px;
  position: relative;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown
  p {
  color: #25265e;
  margin-bottom: 0px;
  font-family: "Proxima-Nova-Regular";
  margin-right: 27px;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown
  p
  span {
  color: #5f0166;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown
  img {
  box-shadow: 0px 2px 5px 0px #00000063;
  border-radius: 45px;
  min-width: 45px;
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown::after {
  border: 0px !important;
  margin: 0px !important;
  background-image: url(/static/media/BottomArrow.d2ca9f24.svg);
  background-size: 12px 7px;
  height: 7px;
  width: 13px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}
.zu_top_header_section .zu_top_header_icon_menu {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0px 30px 0px 0px;
  margin: 0px;
}
.zu_loginas-btn {
  color: #5f0166;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
  border: 0;
  background: transparent;
}
.zu_login_btn {
  margin-right: 0.5rem;
}
.zu_top_header_section .zu_top_header_icon_menu .zu_top_header_icon_menu_item {
  margin: 0px 17px;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item:first-child {
  margin-left: 0px;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item:last-child {
  margin-right: 0px;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link {
  text-decoration: none;
  position: relative;
  display: block;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  background: transparent;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link::after {
  display: none;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link:focus {
  box-shadow: none;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link.zu_top_header_msg_unread::before {
  content: "";
  position: absolute;
  top: 0px;
  right: -5px;
  width: 5.27px;
  height: 5.27px;
  border-radius: 10px;
  background: #6ec49a;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link
  svg {
  width: 25px;
  /* height: 25px; */
}
.zu_top_header_icon_menu_dropdown .dropdown-menu {
  padding: 0;
  margin-top: 8px;
  min-width: 350px;
}
.zu_top_header_icon_menu_dropdown .dropdown-menu::before {
  content: "";
  border-top: 6px solid #ffffff00;
  border-right: 6px solid #ffffff00;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid #ffffff00;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  right: 5px;
  top: -12px;
}
.zu_top_header_icon_menu_dropdown .dropdown-menu::after {
  content: "";
  border-top: 7px solid #ffffff00;
  border-right: 7px solid #ffffff00;
  border-bottom: 7px solid #cecece;
  border-left: 7px solid #ffffff00;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  right: 4px;
  top: -14px;
  z-index: -1;
}

.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_dropdown_header {
  padding: 8px 15px;
  border-bottom: 1px solid #e2e2e2;
}
.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_mark_all_read_btn {
  color: #5f0166;
  background: transparent;
  padding: 0;
  border: 0;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  font-size: 13px;
  text-decoration: underline;
  font-family: "Proxima-Nova-Regular";
}
.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_dropdown_footer {
  padding: 8px 15px;
  border-top: 1px solid #e2e2e2;
}
.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_dropdown_footer
  .zu_notification_dropdown_footer_link {
  color: #5f0166;
  background: transparent;
  padding: 0;
  border: 0;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  font-size: 13px;
  text-decoration: underline;
  font-family: "Proxima-Nova-Regular";
}

.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_top_header_icon_menu_dropdown_content {
  max-height: 250px;
  /* height: 250px; */
  overflow-y: auto;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 20px;
}
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item:last-child {
  border-bottom: 0;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item h4 {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 4px;
  line-height: 1.1;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item p {
  color: #3d3d3d;
  font-size: 12px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 6px;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item p a {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item span {
  color: #3d3d3d;
  font-size: 10px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
  line-height: 1.2;
  display: block;
}

.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread {
  background: #2e4765;
}
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  h4,
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  span,
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  p
  a {
  color: #ffffff;
}
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  p {
  color: #e3e3e3;
}

.zu_top_header_user_dropdown_menu .zu_top_header_user_dropdown_nav_menu {
  padding: 0;
  overflow: hidden;
  margin-top: 10px;
}
.zu_top_header_user_dropdown_nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.zu_top_header_user_dropdown_nav .zu_top_header_user_dropdown_list {
  border-bottom: 1px solid #00000026;
}
.zu_top_header_user_dropdown_nav .zu_top_header_user_dropdown_list:last-child {
  border-bottom: 0;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  padding: 10px 20px;
  color: #707070;
  text-decoration: none;
  border: 0;
  width: 100%;
  background: transparent;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link
  svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #25265e;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list:last-child
  .zu_top_header_user_dropdown_link,
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list:last-child
  .zu_top_header_user_dropdown_link
  svg {
  color: #d64657;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link:hover {
  color: #ffffff;
  background: #5f0166;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link:hover
  svg {
  color: #ffffff;
  background: #5f0166;
}

/* top header css end */

/* common banner image css start */

.zu_common_banner_content {
  width: calc(100% + 80px);
  height: 350px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 40px 80px;
  margin: -30px -40px 0;
  position: relative;
  z-index: 9;
}
.zu_common_banner_content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000004f;
}
.zu_common_banner_content img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.zu_common_banner_content h1 {
  font-size: 50px;
  color: #ffffff;
  font-family: "Proxima-Nova-Alt-Light";
  margin: 0;
  position: relative;
  z-index: 9;
}

/* common banner image css end */

/* job column css start */

.zu_jobs_column {
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: stretch;
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px #52565c1a;
}
.zu_jobs_column:last-child {
  margin-bottom: 0px;
}
.zu_jobs_column .zu_jobs_column_left_content {
  padding: 30px 50px 35px 26px;
  display: flex;
  align-items: flex-start;
  width: calc(100% - 342px);
}
.zu_jobs_column .zu_jobs_column_left_content .zu_jobs_column_left_user_img {
  min-width: 45px;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 45px;
  /* border: 1px solid #e2e2e2; */
  box-shadow: 0px 2px 5px 0px #00000063;
  margin-right: 15px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text {
  width: 100%;
  max-width: 798px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  h3 {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 0px;
  margin-right: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  span {
  color: #2e4765;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  display: block;
  text-decoration: underline;
  margin-right: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #2e4765;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  p
  svg {
  width: 12px;
  height: 16px;
  margin-right: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_description_text {
  width: 100%;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  margin-bottom: 20px;
}
.zu_jobs_column_brief_pre {
  white-space: pre-wrap;
  font-family: "Proxima-Nova-Regular";
}
.readmore_link {
  color: #5f0166;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
}

.readmore_link2 {
  color: #d4d4d4;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
}

.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_label_row {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  flex-wrap: wrap;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_label_row
  .zu_common_label {
  margin: 0 10px;
  min-width: 160px;
  margin-bottom: 10px;
}
.zu_jobs_column .zu_jobs_column_left_content .zu_jobs_column_price_more_detail {
  max-width: 160px;
  width: 100%;
  margin-left: auto;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_price_more_detail
  p,
.zu_jobs_column_price_time_text p {
  color: #2e4765;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 14px;
  text-align: right;
  line-height: 1.2;
}
.zu_jobs_column_price_time_text {
  display: none;
}
.zu_jobs_column_price_more_detail_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  color: #2e4765;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  text-decoration: none;
  line-height: 1.2;
}

.zu_find_jobs_column_more_detail {
  border: none;
  background: transparent;
  padding: 5px;
  background: #e7cee9a3;
  color: #5f0166 !important;
  border-radius: 3px;
}

.zu_find_jobs_column_more_detail:hover {
  color: #ffffff !important;
  background: #5f0166;
}

.zu_jobs_column_price_more_detail_btn svg {
  width: 18px;
  height: 10px;
  margin-left: 19px;
}

.zu_jobs_column .zu_jobs_column_right_content {
  padding: 30px 50px 35px 70px;
  max-width: 342px;
  width: 100%;
  border-left: 2px solid #f5f6fa;
  position: relative;
}
.zu_jobs_column
  .zu_jobs_column_right_content
  .zu_jobs_column_right_payment_text {
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #2e4765;
  display: block;
  margin-bottom: 10px;
}
.zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_price_text {
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  color: #2e4765;
  margin-bottom: 0;
}

.zu_jobs_column .zu_jobs_column_right_content .zu_admin_cancel_text {
  color: #a41212;
  display: flex;
  justify-content: center;
}

.zu_job_status_crew_name {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 16px 7px !important;
}

.zu_job_crew_name {
  font-size: 12px !important;
  font-family: "Proxima-Nova-Regular";
}

.zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_text_row {
  margin-bottom: 15px;
}

.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_price_more_detail_btn {
  display: none;
}

.zu_jobs_column .zu_find_jobs_column_right_content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.zu_jobs_column .zu_find_jobs_column_right_content .zu_common_btn,
.zu_jobs_column .zu_jobs_column_right_content .zu_common_btn {
  padding: 16px 16px 17px;
  font-family: "Proxima-Nova-Bold";
}

.zu_cancel_link {
  background: transparent;
  border: none;
  text-decoration: underline;
}
.zu_jobs_column
  .zu_find_jobs_column_right_content
  .zu_jobs_column_right_payment_text {
  margin-top: 5px;
  text-align: center;
  margin-bottom: 10px;
}
.zu_jobs_column
  .zu_find_jobs_column_right_content
  .zu_jobs_column_price_more_detail_btn {
  justify-content: center;
  margin-top: auto;
}
.zu_jobs_column .zu_jobs_column_bookmark_btn {
  position: absolute;
  right: 25px;
  top: 36px;
  width: 15px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  outline: 0;
}
.zu_jobs_column .zu_jobs_column_bookmark_btn svg {
  width: 15px;
  height: 19px;
}
.zu_jobs_column
  .zu_jobs_column_bookmark_btn
  ~ .zu_jobs_column_right_text_row
  .zu_jobs_column_right_payment_text {
  max-width: calc(100% - 25px);
}
/* job column css end */

/* leads column css start */

.zu_leads_data_column {
  background: #2e4765;
  border: 1px solid #dde4ea;
  padding: 34px 53px 49px 38px;
  display: flex;
}
.zu_leads_data_column .zu_leads_data_column_left_text {
  max-width: 230px;
  min-width: 200px;
  width: 100%;
  border-right: 1px solid #dde4ea;
  padding-right: 20px;
}
.zu_leads_data_column_date_text {
  margin-bottom: 16px;
}
.zu_leads_data_column_date_text p {
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 4px;
  color: #ffffff;
  line-height: 1.2;
}
.zu_leads_data_column_date_text span {
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  color: #ffffff;
}
.zu_leads_data_column_type_text {
  margin-bottom: 20px;
}
.zu_leads_data_column_type_text p {
  color: #ffffff;
  font-size: 15px;
  font-family: "Proxima-Nova-Bold";
  line-height: 1.2;
  margin-bottom: 5px;
}
.zu_leads_data_column_type_text p:last-child {
  margin-bottom: 0px;
}
.zu_leads_data_column_lead_contact h4 {
  color: #ffffff;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
}
.zu_leads_data_column_lead_contact .zu_leads_data_column_lead_contact_text {
  margin-bottom: 15px;
}
.zu_leads_data_column_lead_contact
  .zu_leads_data_column_lead_contact_text:last-child {
  margin-bottom: 0;
}
.zu_leads_data_column_lead_contact .zu_leads_data_column_lead_contact_text p {
  color: #ffffff;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
  line-height: 1.2;
}
.zu_leads_data_column_lead_contact
  .zu_leads_data_column_lead_contact_text
  span {
  color: #ffffff;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  word-break: break-word;
}

.zu_leads_data_column_lead_contact_text .zu_leads_data_column_lead_note_text {
  color: #5dff5d;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  word-break: break-word;
  font-style: italic;
}

.zu_leads_data_column .zu_leads_data_column_right_text {
  max-width: 100%;
  width: 100%;
  padding-left: 30px;
  display: flex;
}
.zu_leads_data_column
  .zu_leads_data_column_right_text
  .zu_leads_data_column_event_info {
  width: 100%;
  padding-right: 30px;
}
.zu_leads_data_column_event_info .zu_leads_data_column_event_info_heading {
  color: #f5f6fa;
  font-size: 24px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 20px;
}
.zu_leads_data_column_event_info .zu_leads_data_column_event_info_row {
  display: flex;
  align-items: flex-start;
}
.zu_leads_data_column_event_info
  .zu_leads_data_column_event_info_row
  .zu_leads_data_column_event_info_left_text {
  max-width: 160px;
  width: 100%;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content {
  margin-bottom: 25px;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content:last-child {
  margin-bottom: 0;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content
  h4 {
  color: #f5f6fa;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content
  p {
  color: #f5f6fa;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_leads_data_column_event_info
  .zu_leads_data_column_event_info_row
  .zu_leads_data_column_event_info_detail_text {
  width: 100%;
  padding-left: 30px;
}
.zu_leads_data_column_event_info_detail_text h4 {
  color: #f5f6fa;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
}
.zu_leads_data_column_event_info_detail_text pre {
  color: #f5f6fa;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 13px;
  letter-spacing: 1.26px;
  line-height: 1.56;
  white-space: pre-wrap;
}

.zu_leads_data_column_event_info_detail_text pre:last-child {
  margin-bottom: 0px;
}

.zu_leads_data_column_event_info_detail_text_svg {
  font-size: 3rem;
  color: #ffffff;
}

.zu_leads_data_column
  .zu_leads_data_column_right_text
  .zu_leads_data_column_btn_content {
  max-width: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn_heading {
  color: #f5f6fa;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  text-align: right;
  margin-bottom: 15px;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn {
  margin-top: auto;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn p {
  color: #f5f6fa;
  font-size: 16px;
  font-family: "Proxima-Nova-Thin";
  text-align: right;
  margin-bottom: 0;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn .zu_common_btn {
  font-family: "Proxima-Nova-Bold";
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn p ~ .zu_common_btn {
  margin-top: 17px;
}

.zu_leads_data_column_btn_content .zu_leads_data_column_edit_archiv_btn {
  max-width: 188px;
  width: 100%;
  margin-left: auto;
  margin-top: 16px;
}
.zu_leads_data_column_btn_content
  .zu_leads_data_column_edit_archiv_btn
  .zu_common_btn {
  margin-bottom: 17px;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
}
.zu_leads_data_column_btn_content
  .zu_leads_data_column_edit_archiv_btn
  .zu_common_btn:last-child {
  margin-bottom: 0px;
}

/* leads column css end */

/* profile user detail css start */

.zu_profile_user_detail_section {
  margin-bottom: 40px;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_profile_user_detail_content .zu_profile_user_detail_row {
  display: flex;
  align-items: flex-start;
}
.zu_profile_user_detail_photo_container {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.zu_profile_user_detail_content .zu_profile_user_detail_photo {
  height: 150px;
  width: 150px;
  min-width: 150px;
  border-radius: 150px;
  box-shadow: 0 4px 8px 1px #25265e35;
  overflow: hidden;
  margin-right: 25px;
  position: relative;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_photo
  .zu_open_image_picker {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #0000005c;
  border-radius: 7px;
  transform: translate(-50%, -50%) scale(0.75);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_photo
  .zu_open_image_picker
  label {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0 10px 20px 0 #00000035;
  display: flex;
  min-width: 30px;
  padding: 4px;
  cursor: pointer;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_photo:hover
  .zu_open_image_picker {
  transform: translate(-50%, -50%) scale(1);
  pointer-events: all;
  opacity: 1;
}

.zu_profile_user_detail_content .zu_profile_user_detail_photo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.zu_profile_user_detail_content .zu_profile_user_detail_text_content {
  margin-right: auto;
  width: 100%;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_sub_heading {
  letter-spacing: 0.11px;
  color: #000000;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  position: relative;
  /* padding: 2px 15px;
  background: #5f036630;
  border-radius: 100px;
  border: 1px solid #5f0366; */
  margin-bottom: 10px;
  /* display: block;
  width: fit-content;
  text-transform: uppercase; */
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_heading {
  letter-spacing: 0.3px;
  color: #000000;
  font-size: 32px;
  margin-bottom: 10px;
  font-family: "Proxima-Nova-Bold";
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_row
  svg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
  /* color: #ee9617; */
  color: #2e4765cc;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_row
  svg:last-child {
  margin-right: 0;
  color: #2e4765cc;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_text {
  color: #9b9b9b;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  margin-bottom: 0;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_common_btn {
  margin-top: 15px;
  max-width: 237px;
  width: 100%;
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
}

.zu_profile_user_detail_content .zu_profile_user_detail_text {
  width: 100%;
}

.zu_profile_user_detail_content
  .zu_profile_user_detail_text
  .zu_profile_user_detail_text_row {
  display: flex;
  align-items: flex-start;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text
  .zu_profile_user_detail_text_row
  .zu_profile_user_detail_message_text {
  max-width: 615px;
  width: 100%;
  margin-left: 15px;
}
.zu_profile_user_detail_content .zu_profile_user_detail_message_text h3 {
  color: #354052;
  font-size: 26px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 10px;
}
.zu_profile_user_detail_content .zu_profile_user_detail_message_text p {
  color: #ffffff;
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0px;
  padding: 20px;
  background: #818181;
  border-radius: 10px;
  min-height: 206px;
  max-height: 206px;
  overflow-y: auto;
}
.zu_profile_user_detail_content .zu_profile_user_detail_about_text {
  margin-top: 20px;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_about_text
  .zu_profile_user_detail_about_text_heading {
  position: relative;
  letter-spacing: 0.16px;
  color: #000000;
  font-family: "Proxima-Nova-Bold";
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_about_text
  .zu_profile_user_detail_about_text_heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 43px;
  height: 2px;
  background: #000000;
}
.zu_public_bio_pre_content {
  white-space: pre-wrap;
}
.zu_profile_user_detail_content .zu_profile_user_detail_about_text p {
  letter-spacing: 0.9px;
  color: #000000;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  margin-bottom: 0;
}
.zu_profile_user_detail_content.zu_profile_user_detail_message
  .zu_profile_user_detail_about_text {
  margin-top: 44px;
}

.zu_profile_user_detail_content .zu_profile_user_bottom_btn_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  gap: 20px;
  max-width: 550px;
  width: 100%;
  margin-top: 30px;
}
.zu_profile_user_detail_content .zu_profile_user_bottom_btn_row .zu_common_btn {
  /* border-radius: 8px; */
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
  /* padding: 19px 19px 16px; */
  /* line-height: 1.2; */
  max-width: 100%;
}
/* .zu_profile_user_detail_content
  .zu_profile_user_bottom_btn_row
  .zu_profile_user_send_msg_btn {
  font-size: 20px;
}
.zu_profile_user_detail_content
  .zu_profile_user_bottom_btn_row
  .zu_profile_user_offer_btn {
  font-size: 35px;
} */

.zu_send_offer_btn {
  margin-left: 0 !important;
}

/* profile user detail css end */

.zu_public_bio_applicant_detail {
  white-space: pre-wrap;
}

/* showcase gallery css start */

.zu_showcase_section {
  margin-bottom: 40px;
}

.zu_showcase_content .react-photo-gallery--gallery > div {
  display: grid !important;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 70px 60px;
  gap: 70px 60px;
}

.zu_showcase_content .react-photo-gallery--gallery > div img {
  width: 100%;
  height: 225px;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 !important;
  object-fit: cover;
  -o-object-fit: cover;
}

/* showcase gallery css end */

/* upload image section css start */

.zu_upload_image_section {
  margin-bottom: 40px;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_upload_image_content .zu_upload_image_heading {
  color: #4b4279;
  display: block;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 20px;
}
.zu_upload_image_dropbox .zu_upload_image_dropbox_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 63px 30px 59px;
  border: 2px dashed #c2cdda;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_shap {
  width: 61px;
  height: 49px;
  margin-bottom: 20px;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_heading {
  color: #555a6b;
  font-family: "Proxima-Nova-Bold";
  font-size: 18px;
  margin-bottom: 14px;
  text-align: center;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_text {
  color: #666666;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_btn {
  background: #5f0166;
  border: 1px solid #5f0166;
  color: #f5f6fa;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  padding: 12px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  max-width: 150px;
  width: 100%;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 100%;
  background: #39003d;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_btn:hover::before {
  width: 100%;
}

.zu_upload_image_dropbox .zu_upload_image_priview_row {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 20px;
  gap: 20px;
  margin-top: 24px;
}
.zu_upload_image_dropbox
  .zu_upload_image_priview_row.zu_upload_image_priview_no_data {
  margin-top: 0;
}
.zu_upload_image_priview_column {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.zu_upload_image_priview_column::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000035;
}
.zu_upload_image_priview_column .zu_upload_image_priview_column_img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  background: #00000035;
}
.zu_images_close_btn {
  width: 20px;
  height: 20px;
  border: 2px solid #d64657;
  border-radius: 4px;
  background: #ffffff;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zu_images_close_btn:hover {
  background: #d64657;
}

.zu_images_close_btn svg {
  width: 10px;
  min-width: 10px;
  height: 10px;
}
.zu_images_close_btn svg path {
  fill: #d64657;
  transition: all 0.3s ease-in-out;
}
.zu_images_close_btn:hover svg path {
  fill: #ffffff;
}

/* upload image section css end */

/* common modal css start */

.zu_common_modal_bg,
.zu_sidemenu_bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d17cc;
  z-index: 999;
  opacity: 73%;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.zu_common_modal {
  /* position: fixed;
  left: 0;
  top: 0; */
  /* background: #FFFFFF; */
  /* z-index: 9999; */
  /* max-height: calc(100vh - 30px); */
  /* overflow-y: auto; */
}
.zu_common_modal .zu_common_modal_content {
  /* position: fixed;
  left: 0;
  top: 0; */
  background: #ffffff;
  /* z-index: 9999; */
  /* max-height: calc(100vh - 30px); */
  /* overflow-y: auto; */
  /* box-shadow: 0 10px 40px 0 #878f971a; */
  position: relative;
}
.zu_common_center_modal {
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* padding: 63px; */
  /* width: 100%;
  padding: 15px; */
  /* max-height: 100vh; */
  /* height: 100vh; */
  /* overflow-y: auto; */
  /* display: flex;
  align-items: flex-start; */
}
.zu_common_center_modal .zu_common_modal_content {
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* padding: 63px;
  border-radius: 30px; */
  /* max-width: 683px; */
  width: 100%;
  margin: auto;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_common_modal .zu_common_modal_checkbox {
  display: flex;
  align-items: flex-start;
}
.zu_common_modal .zu_common_modal_checkbox input {
  min-width: 18px;
  width: 18px;
  height: 18px;
  border: 1px solid #efbff5;
  border-radius: 2px;
  background: #f8eff9;
  border-radius: 2px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 12px 10px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 3px;
}
.zu_common_modal .zu_common_modal_checkbox input:checked {
  background-image: url(/static/media/CheckBoxCheck.cb77e8a5.svg);
}
.zu_common_modal .zu_common_modal_checkbox label {
  padding-left: 10px;
  color: #9e9e9e;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  cursor: pointer;
}
.zu_common_modal .zu_common_modal_checkbox label a {
  color: #9e9e9e;
}

/* .zu_leave_review_modal .zu_common_modal_content {
  padding: 53px 63px 63px;
  border-radius: 30px;
} */
.zu_common_modal .zu_common_modal_heading_paragraph {
  border-bottom: 1px solid #707070;
  padding-bottom: 17px;
  margin-bottom: 11px;
}
.zu_common_modal .zu_common_modal_heading {
  color: #4b4279;
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_common_modal .zu_common_modal_heading_paragraph .zu_common_modal_paragraph {
  font-size: 16px;
  color: #343a40;
  font-family: "Proxima-Nova-Regular";
  margin: 0;
  text-align: center;
}

.zu_common_lead_purchase .zu_credit_card_container {
  justify-content: space-between;
}
.zu_common_lead_purchase .zu_credit_card_container .zu_card_input {
  width: 100%;
}
.zu_common_lead_purchase .zu_credit_card_container label {
  min-width: 55px;
}

.zu_common_lead_purchase .zu_credit_card_container #cvc {
  padding: 0 0 0 8px;
}
.zu_send_offer_paragraph {
  text-align: left !important;
}
.zu_common_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph
  a {
  color: #4b4279;
}
.zu_common_modal .zu_common_modal_second_heading {
  font-size: 30px;
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_common_modal .zu_common_modal_sub_heading {
  font-size: 19px;
  color: #5f0166;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_common_modal .zu_common_modal_rating_star_row {
  margin: 20px 0 25px;
}
.zu_common_modal .zu_common_modal_rating_star_row .react-stars span {
  line-height: 26px;
  margin-right: 10px;
}
.zu_common_modal .zu_common_modal_rating_star_row .react-stars span:last-child {
  margin-right: 0px;
}
.zu_common_modal .zu_common_modal_text_input .zu_common_modal_textarea {
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  background: #ffffff;
  padding: 16px 12px;
  font-size: 16px;
  width: 100%;
  max-height: 146px;
  height: 146px;
  line-height: 1.2;
  outline: 0;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none;
  resize: none;
}

.zu_extra_hour_div {
  margin-top: 12px;
}

.zu_add_extra_hour_input_label {
  color: #4b4279;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
}

.zu_add_custom_or_add_extra_hour_div {
  display: flex;
  justify-content: center;
}

.zu_add_hour_or_btn {
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
}

.zu_add_custom_or_add_extra_hour_btn {
  color: #5f0166 !important;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  margin-top: 8px;
  z-index: 9;
  border-radius: 4px;
  overflow: hidden;
  background: #e7cee9a3;
  border: 0;
  padding: 4px;
}
.zu_card_input_for_custom_amount {
  margin-top: 20px;
}

.zu_extra_amount_payable_text {
  color: #4b4279;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  margin-top: 10px;
  margin-bottom: -12px;
}
.zu_extra_amount_payable_text_small {
  color: #4b4279;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-top: 10px;
  margin-bottom: -12px;
}

/* font-size: 16px;
font-family: "Proxima-Nova-Regular";
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
background: #2e4765cc;
border-radius: 0px;
padding: 18px;
width: 100%;
text-decoration: none;
text-transform: uppercase;
border: 0;
position: relative;
z-index: 9;
border-radius: 4px;
overflow: hidden; */

.zu_add_extra_hour_input {
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  background: #ffffff;
  padding: 16px 12px;
  font-size: 16px;
  width: 100%;
  line-height: 1.2;
  outline: 0;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none;
}

.zu_common_modal
  .zu_common_modal_text_input
  .zu_common_modal_textarea::placeholder {
  color: #d2d0da;
}
.zu_common_modal .zu_modal_common_btn_row {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}
.zu_find_job_modal_btn_each > button {
  max-width: 250px !important;
}
.zu_common_modal .zu_common_link_btn {
  color: #5f0166;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  background: transparent;
  border: 0;
  margin-right: auto;
  text-decoration: underline;
}
.zu_common_modal .zu_modal_common_btn_row .zu_modal_common_submit_btn {
  max-width: 275px;
  width: 100%;
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
}

.zu_review_complete_modal .zu_common_modal_heading_paragraph {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 7px;
}
.zu_review_complete_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_heading {
  margin-bottom: 14px;
}

.zu_common_modal .zu_common_modal_other_detail_row,
.zu_sidemenu_content .zu_sidemenu_other_detail_row {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: #ffffff;
  box-shadow: 0 4px 6px 0 #acb7c233;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
}

.zu_common_modal_other_detail_row_finalize {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: #ffffff;
  padding: 18px;
  margin-top: 1rem;
}

.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-top: 25px; */
}
/* .zu_common_modal .zu_common_modal_other_detail_row .zu_common_modal_other_detail_column:nth-child(1),
.zu_common_modal .zu_common_modal_other_detail_row .zu_common_modal_other_detail_column:nth-child(2),
.zu_sidemenu_content .zu_sidemenu_other_detail_row .zu_sidemenu_other_detail_column:nth-child(1),
.zu_sidemenu_content .zu_sidemenu_other_detail_row .zu_sidemenu_other_detail_column:nth-child(2){
  margin-top: 0px;
} */
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column::before,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 1px;
  background: #b4b7cc66;
}
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column:nth-child(1):before,
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column:nth-child(3):before,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column:nth-child(1):before,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column:nth-child(3):before {
  display: none;
}
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  svg,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  svg {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_icon {
  width: 40px;
}
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_text {
  text-align: center;
  width: 150px;
}

.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  h3,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_text
  h3 {
  font-size: 18px;
  color: #000000;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  p,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_text
  p {
  font-size: 20px;
  color: #061058;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_modal_close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  padding: 10px;
  background: #5f0166;
  box-shadow: 0 10px 20px #f8eff9;
  border-radius: 9px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
}
.zu_modal_close_btn svg {
  width: 15px;
  height: 15px;
}

/* .zu_apply_modal .zu_common_modal_content {
  padding: 48px 64px 41px;
} */

.zu_apply_modal .zu_common_modal_content .zu_common_modal_heading {
  text-align: center;
  max-width: 464px;
  margin: 0 auto 10px;
}
.zu_apply_modal .zu_common_modal_content .zu_common_modal_paragraph {
  text-align: center;
  color: #4b4279;
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  margin: 0 auto 20px;
  max-width: 464px;
}
.zu_apply_modal
  .zu_common_modal_content
  .zu_common_modal_text_input
  .zu_common_modal_textarea {
  max-height: 183px;
  height: 183px;
}
.zu_apply_modal .zu_common_modal_content .zu_common_btn {
  margin: 30px auto 0;
  max-width: 387px;
  font-family: "Proxima-Nova-Bold";
  /* padding: 26px 19px;
  border-radius: 8px;
  text-transform: capitalize; */
}

/* .zu_job_offer_modal .zu_common_modal_content {
  padding: 54px 50px 45px;
} */

.zu_job_offer_modal .zu_common_modal_heading {
  color: #2e4765;
  font-size: 28px;
  max-width: 400px;
  margin: 0 auto 20px;
  text-align: center;
}
.zu_job_offer_modal .zu_common_label {
  display: block;
  max-width: 162px;
  width: 100%;
  margin: 0 auto 10px;
}
.zu_job_offer_modal .zu_common_modal_second_heading {
  font-size: 24px;
  margin-bottom: 5px;
  text-align: center;
}
.zu_job_offer_modal .zu_common_modal_sub_heading {
  font-size: 16px;
  margin-bottom: 0px;
  display: block;
  text-align: center;
}
.zu_job_offer_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  h3 {
  font-size: 14px;
  margin-bottom: 7px;
}
.zu_job_offer_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  p {
  font-size: 23px;
}
.zu_job_offer_modal .zu_common_modal_other_detail_row {
  margin-bottom: 30px;
}
.zu_job_offer_modal .zu_common_modal_checkbox {
  /* max-width: 400px; */
  margin-bottom: 30px;
}
.zu_job_offer_modal .zu_modal_common_btn_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
}
.zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn {
  /* font-size: 20px; */
  font-family: "Proxima-Nova-Bold";
  /* padding: 26px 19px;
  margin-bottom: 25px;
  border-radius: 8px; */
}
.zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-bottom: 0px;
}
.zu_job_offer_modal
  .zu_modal_common_btn_row
  .zu_common_btn.zu_modal_common_accetp_decline_btn {
  text-transform: capitalize;
}

.zu_send_offer_modal .modal-dialog {
  max-width: 850px;
  /* padding: 56px 80px; */
}
.zu_send_offer_modal .zu_common_modal_heading_paragraph {
  padding-bottom: 0;
  margin-bottom: 20px;
  border: 0;
}
.zu_send_offer_modal .zu_common_modal_heading {
  font-size: 32px;
  color: #2e4765;
}
.zu_send_offer_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph {
  font-size: 20px;
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
}
.zu_send_offer_modal .zu_common_modal_send_offer_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.zu_send_offer_modal
  .zu_common_modal_send_offer_row
  .zu_common_modal_send_offer_column {
  padding: 0 20px;
}
.zu_send_offer_modal
  .zu_common_modal_send_offer_row
  .zu_common_modal_how_work_column {
  max-width: 52.28%;
  width: 100%;
}
.zu_send_offer_modal
  .zu_common_modal_send_offer_row
  .zu_common_modal_the_offer_column {
  max-width: 47.72%;
  width: 100%;
}

.zu_common_modal_how_work_content {
  margin-bottom: 30px;
}
.zu_common_modal_send_offer_column .zu_common_modal_send_offer_heading {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 26px;
  margin-bottom: 15px;
}
.zu_common_modal_send_offer_card {
  display: flex;
  justify-content: space-between;
}
.zu_send_offer_show_card_btn {
  font-size: 14px;
  color: #ffffff;
  background: #2e4765cc;
  border-radius: 0px;
  padding: 4px 8px;
  border: 0;
  position: relative;
  border-radius: 4px;
  margin-bottom: 15px;
}
.zu_send_offer_show_card_btn:hover {
  opacity: 0.8;
}

.zu_send_offer_add_card_btn {
  font-size: 14px;
  color: #ffffff;
  background: #5f0166;
  border-radius: 0px;
  padding: 4px 8px;
  border: 0;
  position: relative;
  border-radius: 9px;
  max-height: 48px;
  max-width: 45px;
  margin-left: 7px;
}
.zu_send_offer_add_card_btn svg {
  width: 100%;
}
.zu_send_offer_add_card_btn:hover {
  opacity: 0.8;
}
.blink_me {
  animation: blinker 1s linear 2;
}

@keyframes blinker {
  50% {
    box-shadow: 0 0 20px #8b8000;
  }
}

.blink_me_two {
  animation: blinkertwo 1s linear 2;
}

@keyframes blinkertwo {
  50% {
    box-shadow: 0 0 20px #8b8000;
  }
}
.zu_common_modal_send_offer_column .zu_common_modal_send_offer_step_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item {
  margin-bottom: 20px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item:last-child {
  margin-bottom: 0px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item
  .zu_common_modal_send_offer_step_text {
  color: #000000;
  font-size: 20px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item
  .zu_common_modal_send_offer_step_text
  span {
  color: #6ec49a;
  font-family: "Proxima-Nova-Bold";
}
/* .zu_common_modal_send_offer_column
  .zu_common_modal_card_info_content
  .zu_common_modal_send_offer_heading {
  margin-bottom: 16px;
} */
.zu_common_modal_send_offer_column .zu_common_modal_card_info_content {
  margin-bottom: 30px;
}
.zu_common_modal_send_offer_column .zu_common_modal_the_offer_content {
  background: #ffffff;
  box-shadow: 0 4px 6px 0 #acb7c233;
  border-radius: 10px;
  padding: 30px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_text {
  margin-bottom: 18px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_text
  h3 {
  color: #000000;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_text
  p {
  color: #061058;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_disabled_text
  h3,
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_disabled_text
  p {
  color: #b3b8bd;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_disabled_text
  p {
  font-size: 16px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_note {
  font-size: 15px;
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_note
  span {
  font-family: "Proxima-Nova-Bold";
}
.zu_send_offer_modal .zu_modal_common_btn_row {
  margin-top: 30px;
}
.zu_send_offer_modal .zu_modal_common_btn_row .zu_send_offer_modal_cancel_btn {
  max-width: 220px;
  margin-right: 35px;
}
.zu_send_offer_modal .zu_modal_common_btn_row .zu_common_btn {
  /* padding: 27px 19px 26px; */
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
}

.zu_send_msg_modal {
  padding: 38px 15px 15px;
}
/* .zu_send_msg_modal .zu_common_modal_content {
  padding: 54px 50px 60px;
} */
.zu_send_msg_modal .zu_common_modal_heading {
  /* color: #000000; */
  /* font-family: "Proxima-Nova-Bold";
  font-size: 30px; */
  text-align: center;
  max-width: calc(100% - 40px);
  margin: 0 auto 10px;
}
.zu_send_msg_modal .zu_common_modal_heading span {
  color: #6ec49a;
  display: block;
}
.zu_send_msg_modal .zu_common_modal_paragraph {
  color: #000000;
  font-family: "Proxima-Nova-Regular";
  font-style: italic;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
}
.zu_send_msg_modal .zu_common_modal_text_input {
  margin-top: 30px;
}
/* .zu_send_msg_modal .zu_common_modal_text_input .zu_common_modal_textarea {
  border-color: #2e4765;
  padding: 11px 21px;
} */
.zu_send_msg_modal
  .zu_common_modal_text_input
  .zu_common_modal_textarea::placeholder {
  color: #b3b8bd;
}
.zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn {
  text-transform: capitalize;
  font-family: "Proxima-Nova-Bold";
  margin-right: 26px;
}
.zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-right: 0px;
}
.zu_send_msg_modal .zu_modal_common_btn_row .zu_send_msg_modal_cancel_btn {
  max-width: 160px;
}

.zu_purchase_lead_msg_modal .zu_common_modal_content {
  /* padding: 48px 80px 50px; */
  max-width: 700px;
}
.zu_purchase_lead_msg_modal .zu_common_modal_content > div,
.zu_purchase_lead_msg_modal .zu_common_card_input,
.zu_purchase_lead_msg_modal .zu_common_card_input > *:nth-child(2) {
  width: 100%;
}
.delete_container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.delete_container svg {
  width: 80px;
  color: #d64657;
  border: 3px solid #d64657;
  border-radius: 50%;
  padding: 15px;
}
.zu_purchase_lead_msg_modal .zu_common_card_input {
  border-color: #2e4765cc;
}
.zu_purchase_lead_msg_modal .zu_common_modal_heading_paragraph {
  padding: 0;
  margin-bottom: 30px;
  border: 0;
}
.zu_purchase_lead_msg_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_heading {
  color: #2e4765;
  margin-bottom: 30px;
  /* font-size: 45px; */
  text-align: center;
}
.zu_purchase_lead_msg_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph {
  color: #2e4765;
  margin-bottom: 16px;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
}
.zu_purchase_lead_msg_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph:last-child {
  margin-bottom: 0px;
}
/* .zu_purchase_lead_msg_modal .zu_modal_common_btn_row {
  margin-top: 52px;
} */
.zu_lead_btn {
  display: flex;
  flex-direction: column;
  padding: 9px 18px !important;
}

.zu_extra_hour_btn {
  height: 60px;
}

.zu_charge {
  font-size: small;
  font-family: "Proxima-Nova-Regular";
}

.zu_purchase_lead_msg_modal
  .zu_modal_common_btn_row
  .zu_send_msg_modal_cancel_btn {
  max-width: 178px;
}
.zu_purchase_lead_msg_modal
  .zu_modal_common_btn_row
  .zu_send_msg_modal_approve_btn {
  max-width: 178px;
}
.zu_purchase_lead_msg_modal .zu_modal_common_btn_row .zu_common_btn {
  font-family: "Proxima-Nova-Bold";
  margin-right: 30px;
}
.zu_purchase_lead_msg_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-right: 0;
}

.zu_common_text_modal .modal-dialog {
  max-width: 500px;
}
.zu_common_text_modal .zu_common_modal_heading {
  text-align: center;
}
.zu_common_text_modal .zu_common_modal_paragraph {
  font-size: 22px;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  color: #4b4279;
  margin: 0;
}
.zu_common_text_modal .zu_modal_common_btn_row {
  margin-top: 30px;
}
.zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn {
  text-transform: capitalize;
  /* border-radius: 8px; */
  /* padding: 27px 19px; */
  /* font-size: 20px; */
  font-family: "Proxima-Nova-Bold";
}

.zu_offer_accepted_modal .zu_common_modal_paragraph {
  max-width: 400px;
  margin: 0 auto;
}

/* .zu_decline_offer_modal .zu_common_modal_heading {
  max-width: 330px;
  margin: 0 auto;
} */
.zu_common_text_modal .zu_modal_common_btn_row .zu_decline_offer_back_btn {
  max-width: 153px;
}
.zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn {
  margin-right: 20px;
}
.zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-right: 0px;
}

.zu_common_heading_paragraph_modal .modal-dialog {
  max-width: 500px;
}
.zu_common_heading_paragraph_modal .zu_common_modal_heading {
  color: #2e4765;
  text-align: center;
  margin-bottom: 16px;
}
.zu_common_heading_paragraph_modal .zu_common_modal_paragraph {
  color: #2e4765;
  font-size: 20px;
  text-align: center;
  margin-bottom: 18px;
  line-height: 1.2;
}
.zu_common_heading_paragraph_modal .zu_modal_common_btn_row {
  max-width: 225px;
  margin: 30px auto 0;
}
.zu_credit_card_modal .zu_modal_common_btn_row {
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
}
.zu_common_heading_paragraph_modal .zu_modal_common_btn_row .zu_common_btn {
  /* padding: 16px 19px; */
  font-family: "Proxima-Nova-Bold";
  /* font-size: 20px; */
}
.zu_common_heading_paragraph_modal
  .zu_common_heading_paragraph_modal_text
  .zu_common_modal_paragraph {
  margin-bottom: 20px;
}
.zu_common_heading_paragraph_modal
  .zu_common_heading_paragraph_modal_text
  .zu_common_modal_paragraph:last-child {
  margin-bottom: 0px;
}

.modal_first_paragraph {
  font-family: "Proxima-Nova-Bold" !important;
  font-size: 18px !important;
}

.div_for_extra_hour_btn {
  text-align: end;
}
.extra_hour_btn {
  text-align: end;
  border-radius: 4px;
  padding: 2px;
  background-color: #4b4279;
  border: 1px solid #4b4279;
  color: #ffffff;
  margin-top: 5px;
}

/* common modal css end */

/* sidemenu modal css start */

.zu_sidemenu_bg {
  background: #171d17cc;
  opacity: 38%;
}

.zu_sidemenu_section {
  /* position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999; */
  padding: 110px 15px 15px;
  width: 100%;
  /* height: 100vh; */
  /* max-height: calc(100vh - 106px); */
  /* overflow-y: auto; */
  /* max-width: 683px;
  margin-left: auto; */
}

.zu_sidemenu_content {
  background: #ffffff;
  border-radius: 10px;
  /* max-width: 683px;
  width: 100%; */
  padding: 72px 30px 30px;
  position: relative;
  margin-left: auto;
}
.zu_sidemenu_content .zu_sidemenu_user_img {
  position: absolute;
  left: 50%;
  top: -65px;
  transform: translateX(-50%);
  border: 1px solid #4364f7;
  border-radius: 500px;
  width: 130px;
  height: 130px;
}
.zu_sidemenu_content .zu_sidemenu_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: none;
}
.zu_sidemenu_content .zu_sidemenu_label_other_name_row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.zu_sidemenu_content .zu_sidemenu_label_other_name_row .zu_sidemenu_label_text {
  background: #efbff5;
  color: #000000;
  padding: 8px 35px 6px;
  font-size: 12px;
  font-family: "Proxima-Nova-Bold";
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
}
.zu_sidemenu_content
  .zu_sidemenu_label_other_name_row
  .zu_sidemenu_other_name_text {
  margin-left: auto;
  margin-bottom: 0;
  color: #2e4765;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  text-decoration: underline;
}
.zu_sidemenu_content .zu_sidemenu_heading {
  color: #5f0166;
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 27px;
}
.zu_sidemenu_content .zu_sidemenu_paragraph {
  color: #707070;
  font-size: 20px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 30px;
}
.zu_sidemenu_content .zu_common_label {
  padding: 8px 35px 6px;
  border: 1px solid #f8eff9;
}

.zu_sidemenu_content .zu_sidemenu_other_detail_row {
  margin-top: 30px;
}
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column {
  margin-top: 0;
}
.zu_sidemenu_content .zu_sidemenu_bottom_btn {
  margin: 30px auto 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
  /* max-width: 387px; */
}
.zu_sidemenu_content .zu_sidemenu_bottom_btn .zu_common_btn {
  /* border-radius: 8px;
  padding-top: 27px;
  padding-bottom: 26px;
  font-size: 20px;
  font-family: "Proxima-Nova-Bold"; */
}

.zu_my_jobs_no_longer_msg_content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.zu_my_jobs_no_longer_msg {
  display: flex;
  flex-direction: column;
  background-color: #b50000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;
  color: #ffffff;
}
.zu_my_jobs_no_longer_msg_content .find-available-job-btn {
  margin-top: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  font-size: 14px;
  background: #6ec49a;
}
.zu_my_jobs_no_longer_msg_content .find-available-job-btn:hover {
  background: #58a17e !important;
}

.zu_cancelled_job_in_findjob {
  background: #a41212;
  color: #ffffff;
  font-size: 17px;
  padding: 3px 3px;
  width: 100%;
  border-radius: 5px;
}

/* sidemenu modal css end */

.creditcard-detail_container {
  max-height: 13rem;
  overflow: auto;
  padding: 0 3px;
}

.creditcard_detail {
  display: flex;
  background: #ffffff;
  padding: 8px 14px;
  border-radius: 10px;
  margin: 5px 0;
  border: 1px solid #e2e2e2;
  align-items: center;
}

/* .creditcard_detail-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #000000;
} */

.creditcard_detail-radio {
  cursor: pointer;
  margin-left: auto;
  width: 32px;
  height: 20px;
}

.creditcard_detail .creditcard_detail_img {
  min-width: 50px;
  width: 50px;
  height: 30px;
  margin-right: 15px;
}
.creditcard_detail .creditcard_detail_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.creditcard_detail .creditcard_detail_number_date h4 {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
}
.creditcard_detail .creditcard_detail_number_date p {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.creditcard_detail .creditcard_detail_delete_btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a4121233;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px #00000012;
  cursor: pointer;
  margin-left: auto;
}
.creditcard_detail .creditcard_detail_delete_btn svg {
  width: 20px;
  height: 20px;
  color: #a41212;
}

.zu_applicant_detail_modal {
  padding: 30px;
}

.zu_applicant_detail_content .zu_applicant_detail_img_name_content {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_img {
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  object-fit: cover;
  box-shadow: 0px 2px 5px 0px #00000063;
  margin-right: 15px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_user_name {
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  color: #000000;
  margin-bottom: 10px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_business_name {
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  margin-bottom: 2px;
  display: block;
}

/* .zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating
  svg {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  color: #2e4765cc;
}
.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating
  svg:last-child {
  margin-right: 0;
  color: #2e4765cc;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating_text {
  color: #9b9b9b;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  margin-bottom: 6px;
} */

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_view_website_link {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  text-decoration: underline;
}

.zu_applicant_detail_star_rating_content {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-top: 4px;
}
.zu_applicant_detail_star_rating_content .zu_applicant_detail_star_ratings {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}
.zu_applicant_detail_star_rating_content .zu_applicant_detail_star_ratings svg {
  width: 15px;
  height: 15px;
}
.zu_applicant_detail_star_rating_content
  .zu_applicant_detail_star_rating_display {
  color: #9b9b9b;
  font-family: "Proxima-Nova-Regular";
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 4px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_heading {
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  color: #000000;
  margin-bottom: 10px;
}
.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_message_heading {
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  color: #000000;
  margin-bottom: 8px;
}
.zu_applicant_detail_content .zu_applicant_detail_user_about_text {
  margin-bottom: 20px;
}
.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_paragraph {
  margin-bottom: 0;
  overflow: hidden;
  font-family: "Proxima-Nova-Regular";
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}

.zu_applicant_detail_user_message {
  margin-bottom: 0;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-word;
  font-family: "Proxima-Nova-Regular";
}

.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_paragraph
  > * {
  white-space: break-spaces;
}
.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_paragraph
  p:last-child {
  margin-bottom: 0;
}
.zu_applicant_detail_content .zu_applicant_detail_user_more_detail_link {
  margin-bottom: 20px;
}
.zu_applicant_detail_content .zu_applicant_detail_user_more_detail_link a {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  text-decoration: underline;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.zu_applicant_detail_content .zu_applicant_detail_bottom_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin: 0 -10px;
}
.zu_applicant_detail_content .zu_applicant_detail_bottom_btn .zu_common_btn {
  max-width: 50%;
  width: 100%;
  margin: 0 10px;
}

.zu_website_sendOffer_btn_togther {
  display: flex;
  align-items: center;
}

.zu_website_sendOffer_btn_togther div {
  width: 100%;
  margin-left: 1.5rem;
}
.invoice_button_section {
  display: flex;
}
.bussiness_invoice_btn {
  width: 200px !important;
  right: 15px;
}
.crew_invoice_btn {
  width: 150px !important;
}

/* ---------------------- */

.zu_loginas-btn {
  padding: 10px 20px;
  color: #000000;
  background: #00000008;
  border-radius: 12px;
  text-decoration: none;
  font-size: 15px;
}

.zu_loginas-btn[disabled] {
  background: #5f0166;
  color: #ffffff;
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  /* width: 25%; */
  margin: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.username-cont {
  margin-top: 1rem;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.35rem;
  width: 100% !important;
}

.input {
  padding: 0.35rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: none;
  border: 1px solid #ccc;
  margin: 0;
}

.input:focus {
  outline: var(--color-light-grey);
  /* background-color: #ccc; */
}

.login-btn {
  margin-top: 0.75rem;
  padding: 0.35rem 0;
  border-radius: 0.25rem;
  border: none;
  background-color: var(--color-blue);
  color: var(--color-white);
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
}

.login-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.error {
  color: var(--color-dark-red);
  background-color: var(--color-light-red);
  padding: 0.35rem 0.75rem;
  margin: 0.5rem 0 1rem;
  border-radius: 0.35rem;
}

.link {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-black);
  font-weight: 600;
  padding: 0.5rem 0.75rem;
}

.link:hover {
  opacity: 0.8;
}

.dashboardpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardpage > * {
  margin-bottom: 0.75rem;
}

.top-left-link {
  position: absolute;
  top: -20%;
  left: 20%;
}

.create-pro-btn {
  position: relative;
}

.project-link {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.lead-list_item {
  text-decoration: none;
}

.zu_confirm_box_page{
  min-height: 100vh;
  display: flex;
  padding: 15px;
}
.zu_confirm_box{
  max-width: 450px;
  width: 100%;
  margin: auto;
  padding: 50px;
  box-shadow: 0 10px 20px 0 #00000014;
  background: #ffffff;
}
.zu_confirm_box_content .zu_confirm_box_logo{
  display: block;
  max-width: 180px;
  margin: 0 auto 40px;
}
.zu_confirm_box_content .zu_confirm_box_heading{
  font-size: 20px;
  font-family: 'Proxima-Nova-Bold';
  text-align: center;
  margin-bottom: 20px;
  color: #25265E;
}
.zu_confirm_box_content .zu_confirm_box_paragraph{
  font-size: 16px;
  font-family: 'Proxima-Nova-Regular';
  text-align: center;
  margin-bottom: 20px;
  color: #707070;
}
.zu_confirm_box_content .zu_common_btn{
  border-radius: 5px;
  padding: 12px 20px;
  text-transform: capitalize;
  background: #5F0166;
  color: #ffffff;
  border: 1px solid #5F0166;
  transition: all 0.3s ease-in-out;
}
/* .zu_confirm_box_content .zu_common_btn:hover{
  background: transparent;
  color: #5F0166;
} */

.zu_confirm_box_signin_link{
  text-align: center;
  margin-bottom: 0;
  margin-top: 50px;
  font-size: 16px;
  font-family: 'Proxima-Nova-Regular';
  color: #707070;
}
.zu_confirm_box_signin_link a{
  font-family: 'Proxima-Nova-Bold';
  color: #25265E;
}
.zu_confirm_box_signin_link a:hover{
  color: #25265E;
}
.App {
  text-align: center;
}
@font-face {
  font-family: "Proxima-Nova-Alt-Bold";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Alt-Bold"),
    url(/static/media/Proxima-Nova-Alt-Bold.da0d3d7c.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Light";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Alt-Light"),
    url(/static/media/Proxima-Nova-Alt-Light.3a7c914c.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Thin";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Alt-Thin"),
    url(/static/media/Proxima-Nova-Alt-Thin.2f1b1383.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Black";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Black"),
    url(/static/media/Proxima-Nova-Black.120f91ca.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Bold";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Bold"),
    url(/static/media/Proxima-Nova-Bold.fcaab252.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Extrabold";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Extrabold"),
    url(/static/media/Proxima-Nova-Extrabold.53156c58.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Regular";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Regular"),
    url(/static/media/Proxima-Nova-Regular.ea03f826.otf) format("opentype");
}

@font-face {
  font-family: "Proxima-Nova-Thin";
  font-weight: normal;
  font-style: normal;
  src: local("Proxima-Nova-Thin"),
    url(/static/media/Proxima-Nova-Thin.e700c29b.otf) format("opentype");
}

/* comman css start */

::selection {
  color: #ffffff;
  background: #5f0166;
}

iframe[style*="position"] {
  display: none !important;
}

.m-0 {
  margin: 0 !important;
}
.zu_chat_msg_container pre {
  white-space: normal;
}

/* body,
html {
  overflow-x: hidden;
} */

body {
  background: #f5f6fa;
  min-height: 100vh;
  height: auto;
  font-family: "Proxima-Nova-Regular";
}

body.zu_open_sidebar {
  overflow: hidden;
  max-height: 100vh;
}

textarea {
  resize: vertical;
}

.ml-auto {
  margin-left: auto;
}

textarea,
button {
  font-family: "Proxima-Nova-Regular";
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #5f016699;
}

::-webkit-scrollbar-thumb:hover {
  background: #5f0166;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style: none;
  margin: 32px -5px 0;
}

.pagination_table {
  display: flex;
  justify-content: flex-end;
}

.pagination .pagination__link--disabled {
  pointer-events: none;
}

.pagination li {
  padding: 5px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #5f0166;
  color: #5f0166;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
.pagination a:hover {
  background: #5f0166;
  color: #ffffff !important;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  background: #5f0166;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.err_text {
  /* color: #9b0000;
  font-size: 14px;
  display: inline-block;
  margin-top: 5px; */

  font-size: 14px;
  display: inline-block;
  margin-top: 9px;
  color: #9b0000;
  background-color: #f3ded7;
  border: 1px solid #9b0000;
  padding: 2px 10px;
  border-radius: 3px;
  position: relative;
}
.err_text::before {
  content: "";
  position: absolute;
  left: 4px;
  top: -12px;
  width: 12px;
  height: 12px;
  border-top: 6px solid #9b000000;
  border-right: 6px solid #9b000000;
  border-bottom: 6px solid #9b0000;
  border-left: 6px solid #9b000000;
}
.err_text::after {
  content: "";
  position: absolute;
  left: 5px;
  top: -10px;
  width: 10px;
  height: 10px;
  border-top: 5px solid #f3ded700;
  border-right: 5px solid #f3ded700;
  border-bottom: 5px solid #f3ded7;
  border-left: 5px solid #f3ded700;
}

.profession_err {
  margin: 9px 15px 0;
}

.spinner {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* //////////  under maintenance page ///////////// */

.zu_body-container {
  text-align: center;
  padding: 0;
  background: #5f0166;
  color: #fff;
  font-family: Open Sans;
  /* display: block; */
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zu_body-heading {
  font-size: 50px;
  font-weight: 100;
  text-align: center;
}

.zu_body-container span svg {
  width: 75px;
  margin-top: 1em;
  fill: #ffffff;
}

/* //////////  under maintenance page ///////////// */

img {
  max-width: 100%;
  height: auto;
}

.invoice_container {
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 5px;
}

.invoic_text {
  font-size: 28px;
  color: #000000;
  font-weight: 400;
}
.invoic_text span {
  font-weight: 1000 !important;
}
.invoic_text_crew {
  color: #674ea7;
}

.invoic_text_business {
  color: #1155cc;
}
.inv_number_text {
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 25px;
}
.issued_date {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 40px;
}

.first_grid_table {
  margin-bottom: 20px;
}
.first_grid_table table {
  width: 100%;
  max-width: 100%;
  margin: 0;
  caption-side: bottom;
  border-collapse: collapse;
}
.first_grid_table table thead th {
  border: 1px solid #a6a6a6;
  width: 50%;
  padding: 5px;
  vertical-align: top;
  text-align: left;
  word-break: break-word;
}
.first_grid_column {
  width: 100%;
}

.first_grid_column .first_grid_column_heading {
  font-size: 22px;
  line-height: 1.1;
  color: #000000;
  text-align: center;
}
.first_grid_column .first_grid_column_paragraph {
  font-size: 22px;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.first_grid_column .first_grid_column_abn_number {
  font-size: 22px;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  text-align: center;
}
.first_grid_column .first_grid_column_abn_number span {
  font-weight: 700;
}
.first_grid_column .first_grid_column_register_gst_heading {
  margin-top: 15px;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.1;
  color: #000000;
  text-align: center;
}
.first_grid_column .first_grid_column_register_gst_heading_yes_no {
  font-size: 22px;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  text-align: center;
}
.job_detail_table {
  margin-bottom: 30px;
}
.job_detail_table table {
  width: 100%;
  max-width: 100%;
  margin: 0;
  caption-side: bottom;
  border-collapse: collapse;
}
.job_detail_table table td {
  width: 50%;
}
.job_detail_table_heading_content {
  text-align: left;
  padding: 5px;
}
.job_detail_table_heading_content .job_detail_table_heading {
  font-size: 22px;
  line-height: 1.1;
  color: #000000;
  font-weight: 700;
}
.job_detail_table_heading_content .job_detail_table_job_number {
  font-size: 1.125rem;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0px;
}
.job_detail_table_heading_content .job_detail_table_job_date {
  font-size: 1.125rem;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0px;
}
.job_detail_table table tbody tr:nth-of-type(odd) > * {
  background: #f5f5f5;
}
.job_detail_table table thead th {
  border: 1px solid #a6a6a6;
}
.job_detail_table table tbody tr td {
  padding: 8px;
  border: 1px solid #a6a6a6;
}
.job_detail_table .job_table_data_heading {
  font-size: 1.125rem;
  line-height: 1.1;
  color: #000000;
  font-weight: 700;
  margin-bottom: 0px;
}
.job_detail_table .job_table_data_paragraph {
  font-size: 14px;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0px;
}

.zu_custom_amount_line {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #000000;
  margin-bottom: 0px;
  text-align: center;
}
.lead_text_center {
  text-align: center;
}

.job_detail_table tbody tr td:nth-child(1) {
  text-align: right;
}
.paid_full_date_text {
  text-align: center;
  margin-bottom: 40px;
}
.paid_full_date_text h2 {
  font-size: 32px;
  line-height: 1.1;
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.paid_full_date_text p {
  font-size: 1.125rem;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.note_text {
  font-size: 16px;
  line-height: 1.1;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0px;
}
.note_text span {
  font-weight: 700;
}
.lead_last_info {
  text-align: center;
}

.download_invoice_btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.download_invoice_btn button {
  width: auto;
}

.go_back_invoice_btn {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 18px;
  background: transparent;
  padding: 0;
  border: 0;
  text-decoration: underline;
}
/* common component start */

.zu_floting_input_content .select__control {
  background: #ffffff;
}

.zu_common_card_input {
  border: 1px solid #2e4765;
  border-radius: 10px !important;
  padding: 20px !important;
}

.zu_credit_card_container {
  margin-bottom: 1rem;
}

.zu_credit_card_container .zu_common_card_input {
  padding: 15px 10px !important;
}

.zu_common_card_input input {
  font-family: "Proxima-Nova-Regular";
}

.zu_common_card_input input::placeholder {
  color: #d2d0da;
}

.zu_danger_text {
  color: #d64657 !important;
}

.zu_common_heading {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 26px;
  margin: 0;
}

.zu_table_action_btn_row {
  display: flex;
  align-items: center;
  width: calc(100% + 8px);
  margin: 0 -4px;
}

.zu_table_action_btn {
  padding: 10px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background: #2e4765cc;
  color: #ffffff;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  display: block;
  /* width: 100%; */
  margin: 0 4px;
  text-transform: uppercase;
}

.zu_table_action_btn:hover {
  background: #2e4765;
  color: #ffffff;
}

.zu_table_action_btn_row .zu_table_action_btn.zu_table_action_view_btn {
  width: auto;
}

.zu_common_label {
  padding: 6px 10px;
  font-size: 12px;
  font-family: "Proxima-Nova-Regular";
  background: #035398;
  border: 1px solid #035398;
  color: #ffffff;
  display: inline-block;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
}

.zu_common_label_primary {
  background: #035398;
  border: 1px solid #035398;
  color: #ffffff;
}

.zu_common_label_success {
  background: #6ec49a;
  border: 1px solid #6ec49a;
  color: #ffffff;
}

.zu_common_label_other {
  background: #6e88c4;
  border: 1px solid #6e88c4;
  color: #ffffff;
}

.zu_common_label_light {
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  color: #5f0166;
}

.zu_common_btn {
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e4765cc;
  border-radius: 0px;
  padding: 18px;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  position: relative;
  z-index: 9;
  border-radius: 4px;
  overflow: hidden;
  /* max-width: 178px; */
}

.zu_lead_invoice_btn {
  color: #2e4765cc;
  background-color: #ffffff;
  margin-top: 1rem;
  font-weight: bold;
}

.zu_common_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 100%;
  background: #2e4765;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.zu_lead_invoice_btn::before {
  background: #ffffff;
  /* color: #2e4765 ; */
}
.zu_common_btn:hover::before {
  width: 100%;
}

.zu_common_btn svg {
  margin-right: 11px;
  width: 20px;
  height: 20px;
}

.zu_common_btn:hover {
  color: #ffffff;
}
.zu_lead_invoice_btn:hover {
  color: #2e4765;
}

.zu_common_select_box,
.zu_common_filter_input_box {
  position: relative;
}
.zu_common_select_box label,
.zu_common_filter_input_box label {
  display: none;
}

.zu_common_select_box select,
.zu_common_filter_input_box input {
  background: #ffffff;
  border-radius: 5px;
  padding: 14px 20px;
  border: 0;
  font-size: 16px;
  color: #5f0166;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none !important;
  background-image: url(/static/media/BottomArrow.d2ca9f24.svg);
  background-size: 16px 9px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  outline: 0;
  border: 1px solid #e2e2e2 !important;
  width: 100%;
}
.zu_common_select_box select {
  padding-right: 50px;
}

.zu_common_filter_input_box input::placeholder {
  color: #5f0166;
}

.zu_common_filter_input_box input {
  background-image: none;
}

.zu_common_select_location_icon svg {
  position: absolute;
  left: 17px;
  top: 18px;
  height: 18px;
  pointer-events: none;
  z-index: 9;
}

.zu_common_select_location_icon {
  position: relative;
}

.zu_common_select_location_icon select,
.zu_common_select_location_icon input {
  padding-left: 40px;
}

.zu_btn_yellow {
  background: #e2a000;
  color: #ffffff;
}
.zu_btn_yellow::before {
  background: #ba8400;
}

.zu_btn_green {
  background: #00950c;
  color: #ffffff;
}

.zu_btn_green::before {
  background: #007009;
}

.zu_btn_voilet {
  background: #035398;
  color: #ffffff;
}

.zu_btn_voilet::before {
  background: #024077;
}

.zu_btn_purple {
  background: #5f0166;
  color: #ffffff;
}

.zu_btn_purple::before {
  background: #46004b;
}

.zu_btn_gray {
  background: #b3b8bd;
  color: #ffffff;
}

.zu_btn_gray::before {
  background: #7f7f7f;
}

.zu_btn_light_green {
  background: #6ec49a;
  color: #ffffff;
}

.zu_btn_light_green::before {
  background: #569c7a;
}

.zu_btn_lighter_green {
  background: #00bf787a;
  color: #ffffff;
}

.zu_btn_lighter_green::before {
  background: #579d83;
}

.zu_btn_pending_green {
  background: #a41262;
  color: #ffffff;
}

.zu_btn_pending_green::before {
  background: #7a0043;
}

.zu_btn_pending_red {
  background: #a41212;
  color: #ffffff;
}

.zu_btn_pending_red::before {
  background: #7a0000;
}

.zu_btn_progress_orange {
  background: #e2a000;
  color: #ffffff;
}

.zu_btn_progress_orange::before {
  background: #c48900;
}

.zu_btn_theme_color {
  background: #5f0166;
  color: #ffffff;
}

.zu_btn_theme_color::before {
  background: #39003d;
}

.zu_btn_light_purple_color {
  background: #4b4279;
  color: #ffffff;
}

.zu_notifications_list_view {
  background: #ffffff;
  padding: 20px;
  border-radius: 7px;
  color: #000000;
  margin: 12px auto;
  width: 50%;
}

.zu_notifications_list_view_title {
  display: flex;
  justify-content: space-between;
  font-family: "Proxima-Nova-Bold";
}
.zu_notifications_list_end_content {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.zu_notifications_list_view_msg {
  margin-bottom: 1rem;
}

.zu_left_border_voilet {
  border-left: 7px solid #4b4279;
  background: #f7f6ff;
  box-shadow: 8px 0 12px -4px rgba(75, 66, 121, 0.3),
    -15px 0 5px -20px rgba(75, 66, 121, 0.3);
}

.zu_left_border_blue {
  border-left: 7px solid #035398;
  background: #eef8ff;
  box-shadow: 8px 0 12px -4px rgba(3, 83, 152, 0.3),
    -15px 0 5px -20px rgba(3, 83, 152, 0.3);
}
.zu_left_border_green {
  border-left: 7px solid #00950c;
  background: #fafffa;
  box-shadow: 8px 0 12px -4px rgba(0, 149, 12, 0.3),
    -15px 0 5px -20px rgba(0, 149, 12, 0.3);
}

.zu_left_border_orange {
  border-left: 7px solid #e2a000;
  background: #fffcf6;
  box-shadow: 8px 0 12px -4px rgba(260, 126, 0, 0.3),
    -15px 0 5px -20px rgba(260, 126, 0, 0.3);
}

.zu_left_border_light_blue {
  border-left: 7px solid #4364f7;
  background: #f1f4ff;
  box-shadow: 8px 0 12px -4px rgba(67, 100, 247, 0.3),
    -15px 0 5px -20px rgba(67, 100, 247, 0.3);
}

.zu_notifications_list_archive {
  color: #0dcaf0;
}

.zu_notifications_list_delete_btn {
  display: flex;
  justify-content: center;
  background: transparent;
  border: transparent;
}
.zu_notifications_list_delete_btn svg {
  width: 32px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding: 5px;
}

.zu_btn_light_purple_color::before {
  background: #332d55;
}

.zu_all_page_main_content {
  padding: 30px 40px 60px;
  /* height: calc(100vh - 85px); */
}

.zu_phone_verify {
  margin-bottom: 20px;
}

.zu_all_page_main_content {
  width: calc(100% - 275px);
  margin-left: auto;
}

.zu_floting_input_content .zu_floting_input {
  border-radius: 0px;
  background: #ffffff;
  font-family: "Proxima-Nova-Regular";
  color: #212121;
  border: 1px solid #e2e2e2;
  box-shadow: none;
}

.zu_floting_input_content label {
  font-family: "Proxima-Nova-Regular";
  color: #212121;
  transition: all 0.1s ease-in-out;
}

.zu_floting_input_content .zu_floting_input:focus ~ label,
.zu_floting_input_content .zu_floting_input:not(:placeholder-shown) ~ label {
  opacity: 1;
  color: #616161;
}

.zu_floting_input_content {
  position: relative;
}

.zu_floting_input_content .zu_password_eye_btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  z-index: 99;
}
.zu_floting_input_content .zu_password_eye_btn svg {
  width: 18px;
  height: 18px;
}

.zu_floting_password_input_content .zu_floting_input,
.zu_floting_password_input_content .zu_floting_input:not(:placeholder-shown) {
  padding-right: 50px;
}

.zu_signup_form_row .zu_floting_input_content .zu_password_eye_btn {
  top: 22px;
}

.zu_auth_checkbox {
  padding-left: 0;
}

.zu_auth_checkbox input {
  display: none;
}

.zu_auth_checkbox label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  color: #757575;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
}

.zu_auth_checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #efbff5;
  border-radius: 2px;
  background: #f8eff9;
}

.zu_auth_checkbox label::after {
  content: "";
  position: absolute;
  left: 6.5px;
  top: 4.5px;
  width: 6px;
  height: 11px;
  border: solid #5f0166;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.zu_auth_checkbox input:checked ~ label::after {
  opacity: 1;
}

.zu_auth_form_submit_btn {
  border-radius: 0px;
  background: #5f0166;
  border: 1px solid #5f0166;
  padding: 15px 20px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Proxima-Nova-Alt-Bold";
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 9;
  outline: 0;
}
.zu_auth_form_submit_btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0px;
  height: 100%;
  background: #f5f6fa;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.zu_auth_form_submit_btn:hover::before {
  width: 100%;
}
.zu_auth_form_submit_btn:hover {
  color: #5f0166;
}

.zu_auth_form_submit_btn svg {
  width: 22px;
  height: 22px;
  margin-left: 16px;
}

.zu_login_info_btn {
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent !important;
  border: 0;
  color: #2e4765cc !important;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  box-shadow: none !important;
  text-decoration: none;
}

.zu_login_info_btn:hover {
  background: transparent;
  border: 0;
  color: #2e4765cc;
}

.zu_login_info_btn svg {
  width: 22px;
  height: 22px;
  background: #2e4765;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px;
}
.zu_login_info_btn svg path {
  fill: #ffffff;
}

.zu_auth_page_mobile_logo {
  box-shadow: 0px 4px 20px #25265e1a;
  padding: 30px 40px;
  display: none;
}

.zu_auth_page_mobile_logo a {
  display: block;
  max-width: 173px;
  margin: 0 auto;
}

.zu_auth_page_mobile_logo a svg {
  width: 100%;
}

/* .zu_common_checkbox input {
  display: none;
} */
.zu_common_checkbox {
  display: flex;
  align-items: flex-start;
}

.zu_common_checkbox input {
  min-width: 18px;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 1px solid #efbff5;
  border-radius: 2px;
  order: 1;
  -webkit-appearance: none;
          appearance: none;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 1px;
}

.zu_common_checkbox input:checked {
  background-image: url(/static/media/CheckBoxCheck.cb77e8a5.svg);
}

.zu_common_checkbox label {
  padding-left: 12px;
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
  font-size: 18px;
  cursor: pointer;
  order: 2;
  line-height: 1.2;
}

/* .zu_common_checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #efbff5;
  border-radius: 2px;
}
.zu_common_checkbox label::after {
  content: "";
  position: absolute;
  left: 7.5px;
  top: 6px;
  width: 7px;
  height: 12px;
  border: solid #5f0166;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
} */
.zu_common_checkbox input:checked ~ label::after {
  opacity: 1;
}

.zu_common_custom_date_picker {
  box-shadow: 0 10px 20px 0 #c4c8d066;
  min-width: 390px;
  max-width: 390px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  padding: 28px 29px 30px;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 99;
}

.zu_common_custom_date_picker .zu_common_custom_date_picker_heading {
  color: #333333;
  font-size: 24px;
  font-family: "Proxima-Nova-Bold";
  padding-bottom: 15px;
  border-bottom: 2px solid #ebf2f7;
  margin: 0;
}

.zu_common_custom_date_picker .zu_common_custom_date_picker_content {
  position: relative;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__navigation
  .react-datepicker__navigation-icon {
  display: none;
}
.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__header
  .react-datepicker__day-names {
  /* display: none; */
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: #858585;
  font-weight: 700;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__navigation {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 43.5px;
  top: 0;
  background: transparent;
  border: 0;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__navigation::before {
  content: "";
  background-image: url(/static/media/DatepickerLeftArrow.dfd756c7.svg);
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
  display: block;
  background-repeat: no-repeat;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__navigation.react-datepicker__navigation--next {
  left: auto;
  right: 43.5px;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__navigation.react-datepicker__navigation--next::before {
  background-image: url(/static/media/DatepickerRightArrow.fba75cf8.svg);
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month {
  margin-top: 21px;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 8.66px;
  gap: 8.66px;
  margin-bottom: 8.66px;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week:last-child {
  margin-bottom: 0px;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #52575d;
  font-size: 14px;
  font-family: "Proxima-Nova-Bold";
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 4px;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background: #ff009e;
  border-radius: 10px;
  opacity: 0;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--today::before {
  opacity: 1;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--today.react-datepicker__day--selected::before {
  background: #ffffff;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--outside-month {
  color: #b3b8bd;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--selected {
  background: #ff009e;
  color: #ffffff;
  position: relative;
}
.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day:hover {
  background: #ff009e;
  color: #ffffff;
}

.zu_color_disable_mon
  .zu_common_custom_date_picker_content
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--selected::before {
  content: "";
  background: linear-gradient(
    90deg,
    rgba(255, 0, 158, 0) 0%,
    rgba(255, 0, 158, 0.2497373949579832) 100%
  ) !important;
  position: absolute;
  right: 0;
  width: 200%;
  height: 100%;
  right: 26px;
  opacity: 1;
  left: unset;
  border-radius: 0;
  top: 0;
  bottom: 0;
  transform: unset;
  pointer-events: none;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__month-container {
  margin-top: 10px;
}

.zu_common_custom_date_picker
  .zu_common_custom_date_picker_content
  .react-datepicker__header
  .react-datepicker__current-month {
  max-width: 245px;
  margin: 0 auto;
  color: #52575d;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  text-align: center;
  line-height: 1.25;
}

.zu_table_status_label {
  background: #b3b8bd;
  color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  padding: 10px 16px;
  width: 217px;
  text-align: center;
  cursor: pointer;
}
.zu_table_status_text {
  cursor: auto !important;
}
.zu_table_status_style {
  cursor: pointer !important;
}
.zu_table_status_new_label {
  background: #2e4765cc;
  color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  padding: 10px 16px;
  width: 217px;
  text-align: center;
}

.zu_table_status_warning_label {
  background: #e2a000;
}

.zu_table_status_danger_label {
  background: #d64657;
}

.zu_table_status_primary_label {
  background: #157fff;
}

.zu_table_status_success_label {
  background: #00950c;
}

.zu_table_status_cancelled_label {
  background: #a41262;
}
.zu_table_status_approve__label {
  background: #6ec49a;
}
.zu_table_status_in_progress__label {
  background: #157fff;
}

.zu_table_label_row {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 217px;
}

.zu_admin_manage_job_table .zu_table_label_row {
  max-width: 350px;
}
.zu_admin_manage_job_table .rdt_Table {
  min-width: 1250px;
}

.zu_table_label_row svg {
  min-width: 40px;
  width: 40px;
  height: 40px;
  /* margin-left: 15px; */
}

.zu_lead_min {
  display: none;
}

.zu_danger_btn {
  background: #d64657;
  color: #ffffff;
}

.zu_danger_btn::before {
  background: #b83a49;
}

.zu_danger_btn2 {
  background: #d64657;
  color: #ffffff;
}
.zu_danger_btn2:hover {
  background: #b83a49;
}

.zu_action_warning_btn {
  background: #f57f4c;
  color: #ffffff;
}

.zu_action_warning_btn::before {
  background: #cd673a;
}

.zu_action_warning_btn2 {
  background: #f57f4c;
  color: #ffffff;
}
.zu_action_warning_btn2:hover {
  background: #cd673a;
  color: #ffffff;
}

.zu_lighter_blue_btn {
  background: #6e88c4;
  color: #ffffff;
}

.zu_lighter_blue_btn:hover {
  background: #6e88c4c0;
  color: #ffffff;
}

.zu_floting_input .select__control {
  border: 0 !important;
  border-radius: 0;
  box-shadow: none;
  margin-top: -8px;
}
.zu_floting_input .select__control .select__indicators {
  margin-top: -14px;
}

.zu_floting_input .select__control .select__value-container {
  padding: 0;
}

.zu_floting_input .select__control .select__input-container {
  padding: 0;
  margin: 0;
}

.zu_floting_input .select__control .select__indicator-separator {
  display: none;
}

.zu_floting_input
  .select__control
  .select__value-container
  .select__single-value {
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  color: #212121;
}

/* common component end */

/* login page css start */

.zu_login_page_content {
  overflow: hidden;
}

.zu_login_section .zu_login_content {
  display: flex;
  align-items: center;
}

.zu_login_section .zu_login_content .zu_login_form_content {
  /* padding: 200px 0px 74px; */
  padding: 50px 0px;
  max-width: 43.1%;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.zu_login_section .zu_login_content .zu_login_image_content {
  max-width: 56.9%;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

.zu_login_section .zu_login_content .zu_login_image_content img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.zu_login_section .zu_login_content .zu_login_form_content .zu_login_form {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 30px 50px;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_logo {
  display: block;
  max-width: 250px;
  margin-bottom: 50px;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_logo
  svg {
  width: 100%;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_sub_heading {
  color: #757575;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  display: block;
  /* text-transform: uppercase; */
  margin-bottom: 10px;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_heading {
  color: #424242;
  letter-spacing: -0.5px;
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 30px;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_input_content
  .zu_floting_input_content {
  margin-top: 30px;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_input_content
  .zu_floting_input_content:first-child {
  margin-top: 0;
}

.zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_input_content
  .zu_auth_checkbox {
  margin: 20px 0 30px;
}

.zu_login_form_input_content .zu_login_bottom_text {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  /* justify-content: center; */
}

.zu_login_form_input_content
  .zu_login_bottom_text
  .zu_login_bottom_password_text {
  color: #000000;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  margin-bottom: 0;
  text-decoration: none;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  /* margin-bottom: 30px; */
}

.zu_login_form_input_content .zu_login_bottom_text .zu_login_bottom_join_text {
  color: #424242;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
  margin-right: 10px;
}

.zu_login_form_input_content
  .zu_login_bottom_text
  .zu_login_bottom_join_text
  a {
  color: #212121;
  font-family: "Proxima-Nova-Bold";
}

.zu_login_form_content .zu_login_info_btn_content {
  padding: 0 30px;
  margin: 0 auto;
  max-width: 600px;
}

/* login page css end */

/* forgot password page css start */

.zu_forgot_password_page_content
  .zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_heading {
  margin-bottom: 26px;
}

.zu_forgot_password_page_content
  .zu_login_section
  .zu_login_content
  .zu_login_form_content
  .zu_login_form
  .zu_login_form_input_content
  .zu_floting_input_content {
  margin-bottom: 30px;
}

/* forgot password page css end */

/* signup page css start */

.zu_signup_section {
  background-image: url(/static/media/sign-up-img.896fb30f.jpg);
  min-height: 100vh;
  background-size: cover;
  padding: 50px 110px;
  background-position-y: top;
  position: relative;
  z-index: 9;
}
.zu_signup_section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000005c;
  z-index: -1;
}

.zu_signup_section .zu_signup_content {
  max-width: 850px;
  width: 100%;
}

.zu_signup_content .zu_signup_form_logo {
  display: block;
  max-width: 250px;
  width: 100%;
  margin-bottom: 50px;
}

.zu_signup_content .zu_signup_form_logo svg {
  width: 100%;
}

.zu_signup_content .zu_signup_form_sub_heading,
.zu_signup_content .zu_signup_form_mobile_sub_heading {
  color: #757575;
  font-size: 17px;
  font-family: "Proxima-Nova-Regular";
  display: block;
  margin-bottom: 10px;
}

.zu_signup_content .zu_signup_form_heading,
.zu_signup_content .zu_signup_form_mobile_heading {
  letter-spacing: -0.5px;
  color: #424242;
  font-size: 31px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 30px;
}

.zu_signup_content .zu_signup_form_sub_heading,
.zu_signup_content .zu_signup_form_heading {
  color: #ffffff;
}

.zu_signup_content .zu_floting_input_content .zu_floting_input {
  background: #ffffff;
}

.zu_signup_form_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.zu_signup_form .zu_signup_form_row {
  margin-top: 30px;
}

.zu_signup_form .zu_signup_form_row:first-child {
  margin-top: 0;
}

.zu_signup_form .zu_signup_form_checkbox_categories {
  margin-top: 30px;
  margin-bottom: 23px;
}

.zu_signup_form
  .zu_signup_form_checkbox_categories
  .zu_signup_form_checkbox_categories_text {
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
  font-size: 18px;
  margin-bottom: 20px;
}

.zu_signup_form_checkbox_categories_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  width: calc(100% + 30px);
  margin-bottom: 20px;
}
.zu_signup_form_checkbox_categories_row .zu_common_checkbox {
  max-width: 33.333333%;
  width: 100%;
  padding: 0 15px;
  margin-top: 20px;
}
.zu_signup_form_checkbox_categories_row .zu_common_checkbox:nth-child(1),
.zu_signup_form_checkbox_categories_row .zu_common_checkbox:nth-child(2),
.zu_signup_form_checkbox_categories_row .zu_common_checkbox:nth-child(3) {
  margin-top: 0px;
}

.zu_signup_form .zu_signup_form_tnc label a {
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
  font-size: 18px;
}

.zu_signup_form .zu_auth_form_submit_btn {
  background: #6ec49a;
  border-color: #6ec49a;
  margin-bottom: 30px;
}

.zu_signup_form .zu_auth_form_submit_btn:hover {
  /* background: #ffffff; */
  color: #000000;
  border-color: #ffffff;
}

.zu_signup_form .zu_signup_already_member_text {
  font-family: "Proxima-Nova-Regular";
  font-size: 18px;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.zu_signup_form .zu_signup_already_member_text a {
  font-family: "Proxima-Nova-Bold";
  color: #ffffff;
}

.zu_signup_form .zu_login_info_btn_content,
.zu_signup_content .zu_signup_form_mobile_sub_heading,
.zu_signup_content .zu_signup_form_mobile_heading {
  display: none;
}

/* signup page css end */

/* dashboard page css start */
.zu_dashboard_job_section {
  margin-bottom: 40px;
}

.zu_dashboard_page .zu_common_banner_content {
  justify-content: center;
}

.zu_dashboard_job_content_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
  margin-top: 30px;
}

.zu_dashboard_job_content_row a {
  text-decoration: none;
}

.zu_dashboard_job_content_column {
  background: #2e4765;
  border-radius: 10px;
  padding: 30px;
  position: relative;
}

.zu_dashboard_job_content_column.zu_dashboard_manage_job_content_column {
  background: #4b4279;
}

.zu_dashboard_job_content_column .zu_dashboard_job_content_column_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  color: #ffffff;
}

.zu_dashboard_job_content_column .zu_dashboard_job_content_column_icon svg {
  width: 40px;
  height: 40px;
}

.zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
  color: #ffffff;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 8px;
  line-height: 1.1;
  text-transform: capitalize;
}

.zu_dashboard_job_content_column .zu_dashboard_job_content_column_text p {
  color: #ffffff;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin: 0;
  line-height: 1.1;
}
.zu_dashboard_job_content_column .zu_dashboard_job_content_column_right_icon {
  width: 100px;
  color: #ffffff;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.1;
}

.zu_dashboard_crew_payment_column {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid #e2e2e2;
  position: relative;
  z-index: 9;
}
.zu_dashboard_crew_payment_column::before {
  content: "01";
  position: absolute;
  bottom: 26px;
  right: 30px;
  color: #2e4765;
  z-index: -1;
  font-size: 64px;
  font-weight: 700;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #b8b8b8;
  -webkit-text-fill-color: transparent;
  opacity: 0.2;
  font-family: "Lato", sans-serif;
}
.zu_dashboard_crew_payment_column:last-child:before {
  content: "02";
}

.zu_dashboard_crew_payment_column h2 {
  color: #5f0166;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
  line-height: 1.1;
}

.zu_dashboard_crew_payment_column span {
  color: #3d3d3d;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 10px;
  display: block;
  /* letter-spacing: 1.38px; */
}

.zu_dashboard_crew_payment_column p {
  color: #3d3d3d;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
  line-height: 1.2;
  /* letter-spacing: 1.34px; */
}

/* dashboard page css end */

/* find job page css start */

.zu_upcomming_job_content .zu_common_heading {
  margin-bottom: 30px;
}

.zu_find_job_section {
  margin-bottom: 40px;
}

.zu_common_filter_input_row {
  display: flex;
  align-items: center;
  margin: 0 -15px 30px;
}

.four_filter_container {
  max-width: 1280px !important;
}

.zu_common_filter_input_row > div {
  margin: 0 15px;
}

.zu_common_filter_input_one {
  max-width: 25%;
  width: 100%;
}

.zu_common_filter_input_two {
  max-width: 25%;
  width: 100%;
}

.zu_common_filter_input_three {
  max-width: 20%;
  width: 100%;
}
.zu_common_filter_input_class_three {
  max-width: 40%;
  width: 100%;
}

/* .zu_common_form_input {
  padding: 16px 20px;
  border-radius: 16px;
  color: #5f0166;
  border-color: transparent;
} */

.zu_common_job_heading {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.zu_common_job_heading .zl_common_job_show_filter {
  color: #2e4765;
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 40px;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
}

.zu_common_job_heading.zu_common_job_heading_filter_open {
  margin-bottom: 24px;
}

.zu_job_column_view_more_btn {
  margin-top: 40px;
  text-align: center;
}

.zu_job_column_view_more_btn button {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: #5f0166;
}

.zu_job_column_view_more_btn button:hover {
  opacity: 0.9;
}

.zu_job_column_view_more_btn a {
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
  font-size: 18px;
}

.zu_upcomming_job_content .zu_common_filter_input_row {
  max-width: 1140px;
}

/* find job page css end */

/* manage job page css start */

.zu_manage_job_banner_section {
  margin-bottom: 40px;
}

.zu_manage_job_filter_row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.zu_manage_job_filter_row .zu_common_filter_input_row {
  margin-bottom: 0;
  width: 100%;
  margin-right: 0;
}

.zu_manage_job_filter_row .zu_common_btn {
  max-width: 230px;
  font-family: "Proxima-Nova-Bold";
  margin-left: auto;
}
.zu_btn_show {
  display: none;
}

/* manage job page css end */

/* manage job detail page css start */

.zu_manage_job_detail_heading_contant {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.zu_manage_job_detail_heading_contant .zu_manage_job_detail_share_btn {
  width: 45px;
  height: 45px;
  border: 1px solid #f0f0f0;
  background: #ffffff;
  border-radius: 6px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.zu_manage_job_detail_heading_contant .zu_manage_job_detail_share_btn svg {
  width: 17px;
  height: 17px;
}

.zu_manage_job_detail_column_content .zu_manage_job_detail_column_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_column {
  padding: 0 15px;
  position: relative;
  width: 100%;
  margin-top: 30px;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_column:nth-child(1),
.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_column:nth-child(2) {
  margin-top: 0;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_left_column {
  max-width: 61.53%;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_right_column {
  max-width: 38.47%;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_right_column
  > div {
  margin-top: 30px;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_detail_right_column
  > div:nth-child(1) {
  margin-top: 0;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_column_row
  .zu_manage_job_other_detail_column {
  max-width: 100%;
}

.zu_manage_job_detail_column_content .zu_manage_job_detail_left_content {
  background: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px #52565c1a; */
  border: 1px solid #e2e2e2;
  padding: 30px;
  height: 100%;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_detail_left_content
  .zu_manage_job_detail_project_name_label_row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.zu_manage_job_detail_project_name_text h3 {
  color: #5f0166;
  font-size: 25px;
  margin-bottom: 26px;
  font-family: "Proxima-Nova-Bold";
}

.zu_manage_job_detail_project_name_text span {
  color: #2e4765;
  font-size: 16px;
  margin-bottom: 16px;
  font-family: "Proxima-Nova-Regular";
  display: block;
}

.zu_manage_job_detail_project_name_text p {
  color: #2e4765;
  font-size: 18px;
  margin-bottom: 0px;
  font-family: "Proxima-Nova-Regular";
  display: flex;
  align-items: center;
}

.zu_manage_job_detail_project_name_text svg {
  width: 12px;
  min-width: 12px;
  height: 16px;
  margin-right: 10px;
}

.zu_manage_job_detail_project_name_text .zu_manage_job_date {
  display: none;
  margin-bottom: 23px;
}

.zu_manage_job_detail_project_name_text .zu_manage_job_date span {
  color: #000000;
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}

.zu_manage_job_detail_project_name_text .zu_manage_job_date p {
  color: #061058;
  font-size: 25px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_manage_job_detail_project_label_text {
  max-width: 218px;
  width: 100%;
  margin-left: auto;
}

.zu_manage_job_detail_project_label_text .zu_common_label {
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}

.zu_manage_job_detail_project_label_text .zu_common_label:last-child {
  margin-bottom: 0;
}

.zu_manage_job_detail_project_label_text .zu_manage_job_detail_id_label {
  color: #4b4279;
  font-family: "Proxima-Nova-Bold";
  background: #f5f6fa;
  border: 1px solid #f8eff9;
}

.zu_manage_job_detail_left_content .zu_manage_job_detail_paragraph p {
  color: #000000;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  margin-bottom: 15px;
}

.zu_manage_job_detail_left_content
  .zu_manage_job_detail_paragraph
  p:last-child {
  margin-bottom: 0;
  font-family: "Proxima-Nova-Regular";
}

.zu_manage_job_applicant_list_column {
  background: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px #52565c1a; */
  border: 1px solid #e2e2e2;
  padding: 30px;
}

.zu_manage_job_applicant_list {
  max-height: 480px;
  overflow-y: auto;
}

.height_minimize {
  max-height: 330px !important;
  overflow-y: auto;
}

.zu_manage_job_applicant_list_column .zu_manage_job_applicant_list_heading {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding: 0 20px 20px;
}

.zu_manage_job_applicant_list_column .zu_manage_job_applicant_list_heading h3 {
  color: #5f0166;
  font-size: 25px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
}

.zu_manage_job_applicant_list_column
  .zu_manage_job_applicant_list_heading
  .zu_manage_job_applicant_list_number {
  background: #5f0166;
  color: #ffffff;
  font-size: 15px;
  font-family: "Proxima-Nova-Bold";
  border-radius: 6px;
  padding: 4.75px 8.52px;
  margin-left: auto;
  min-width: 32px;
  min-height: 32px;
  text-align: center;
}

.zu_applicant_btn_div {
  display: flex;
}

.zu_applicant_msg_icon {
  background: transparent;
  border: transparent;
  padding: 20px 0px;
  font-size: 26px;
}

.zu_applicant_msg_icon svg {
  height: 26px;
  width: 26px;
}

.zu_applicant_msg_icon:hover {
  color: #5f0166;
}

.zu_manage_job_applicant_list_link {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cbc6d726;
  padding: 20px;
  text-decoration: none;
  background: transparent;
  border: transparent;
  width: 100%;
}

.zu_manage_job_applicant_list_link .zu_manage_job_applicant_list_img {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 45px;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 2px 5px 0px #00000063;
}

/* .zu_manage_job_applicant_list_link, */
.zu_manage_job_applicant_list_link:hover {
  color: #2e4765;
  background-color: #e8ecf1;
  border-radius: 3px;
}

.zu_manage_job_applicant_list_link .zu_manage_job_applicant_list_name {
  font-size: 16px;
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
  margin-right: 10px;
}
.zu_manage_job_applicant_list_link sup {
  color: #ea802a;
}

.zu_manage_job_applicant_list_with_rating {
  display: flex;
  flex-direction: column;
}

.zu_manage_job_applicant_list_link svg {
  width: 18px;
  height: 18px;
  margin-left: auto;
}

.zu_manage_job_applicant_list_link .zu_manage_job_applicant_list_star_rating {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 2px;
  gap: 2px;
}
.zu_manage_job_applicant_list_link
  .zu_manage_job_applicant_list_star_rating
  svg {
  width: 10px !important;
  height: 10px !important;
}

.zu_manage_job_detail_column_content
  .zu_manage_job_other_detail_column
  .zu_manage_job_other_detail_row {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 #acb7c233;
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.zu_manage_job_other_detail_content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: relative;
}
.zu_manage_job_other_detail_row .zu_manage_job_other_detail_content:last-child {
  justify-content: flex-start;
  padding: 30px 10px;
}

.zu_manage_job_other_detail_content::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -14px;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background: #b4b7cc66;
}

.zu_manage_job_other_detail_content:first-child:before {
  display: none;
}

.zu_manage_job_other_detail_content svg {
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.zu_manage_job_other_detail_content
  .zu_manage_job_other_detail_content_text
  h3 {
  font-size: 18px;
  color: #000000;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}

.zu_manage_job_other_detail_content .zu_manage_job_other_detail_content_text p {
  font-size: 20px;
  color: #061058;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_custom_total_msg {
  font-size: 15px !important;
}

.zu_manage_job_btn_content {
  margin-top: 30px;
}

.zu_manage_job_btn_content .zu_manage_job_btn_row {
  display: flex;
  align-items: center;
}

.zu_manage_job_btn_content .zu_manage_job_btn_row .zu_manage_job_back_btn {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 18px;
  background: transparent;
  padding: 0;
  border: 0;
  text-decoration: underline;
}

.zu_manage_job_btn_content
  .zu_manage_job_btn_row
  .zu_manage_job_edit_cancel_btn_row {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.zu_manage_job_btn_content
  .zu_manage_job_btn_row
  .zu_manage_job_edit_cancel_btn_row
  .zu_common_btn {
  background: #5f0166;
  display: flex;
  align-items: center;
  max-width: 50%;
  width: 100%;
  color: #ffffff;
  font-family: "Proxima-Nova-Bold";
  text-decoration: none;
  justify-content: center;
  margin-right: 30px;
}

.zu_manage_job_btn_content
  .zu_manage_job_btn_row
  .zu_manage_job_edit_cancel_btn_row
  .zu_manage_job_cancel_btn {
  background: #9b0000;
}
.zu_manage_job_btn_content
  .zu_manage_job_btn_row
  .zu_manage_job_edit_cancel_btn_row
  .zu_manage_job_cancel_btn::before {
  background: #700000;
}

.zu_manage_job_btn_content
  .zu_manage_job_btn_row
  .zu_manage_job_edit_cancel_btn_row
  .zu_common_btn:last-child {
  margin-right: 0;
}

.zu_manage_job_contact_column {
  background: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px #52565c1a; */
  border: 1px solid #e2e2e2;
  padding: 30px;
}

.zu_manage_job_contact_column .zu_manage_job_contact_column_heading {
  color: #5f0166;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 17px;
  text-transform: capitalize;
}

.zu_manage_job_contact_column .zu_manage_job_contact_details_row {
  display: flex;
  align-items: flex-start;
}

.zu_manage_job_contact_column
  .zu_manage_job_contact_details_row
  .zu_manage_job_contact_details_user_img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  box-shadow: 0px 2px 5px 0px #00000063;
  border-radius: 45px;
}

.zu_manage_job_contact_column
  .zu_manage_job_contact_details
  .zu_manage_job_contact_details_heading {
  color: #000000;
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 10px;
}

.zu_manage_job_contact_column
  .zu_manage_job_contact_details
  .zu_manage_job_contact_details_number,
.zu_manage_job_contact_column
  .zu_manage_job_contact_details
  .zu_manage_job_contact_details_email {
  color: #000000;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 5px;
  line-height: 1.2;
}

.zu_manage_job_contact_column
  .zu_manage_job_contact_details
  .zu_manage_job_contact_details_email {
  word-break: break-word;
}

.zu_manage_job_contact_column
  .zu_manage_job_contact_details
  .zu_manage_job_contact_details_web_link {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  text-decoration: underline;
}

.crew_send_msg_btn {
  margin-top: 12px;
  font-size: 14px;
  padding: 12px;
  /* width: 70%; */
}

/* manage job detail page css end */

/* invoices page css start */

.zu_common_data_table .zu_table_processing_button,
.zu_common_heading_processing_label {
  background: #ea802a;
  border: 1px solid #f0f0f0;
  padding: 7px 14px;
  border-radius: 5px;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  color: #ffffff;
  outline: 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.zu_common_data_table .zu_table_processing_button,
.zu_common_heading_job_progress {
  margin-left: 4px;
  background: #ea802a;
  border: 1px solid #f0f0f0;
  padding: 7px 14px;
  border-radius: 5px;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  color: #ffffff;
  outline: 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.zu_common_heading_extra_hour {
  margin-left: 4px;
  background-color: #4b4279;
  color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 7px 14px;
  border-radius: 5px;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  outline: 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.zu_common_heading_job_progress_btn_row {
  padding: 7px 14px;
  border-radius: 5px;
  outline: 0;
  display: inline-block;
}

.zu_common_data_table .zu_table_download_button {
  /* width: 25px; */
  /* height: 25px; */
  /* background: transparent; */
  border: 0;
  /* outline: 0; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  padding: 7px 10px;
  background: #5f0166;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}

.zu_common_data_table .zu_table_download_button svg {
  width: 25px;
  height: 25px;
}

.zu_common_data_table .zu_crew_img_text_row {
  display: flex;
  align-items: center;
}

.zu_common_data_table .zu_crew_img_text_row img {
  min-width: 34px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 14px;
}

.element {
  display: inline-block;
}

.element .tooltip {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 75%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.element .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.element:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* .element {
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 3px;
  border: 1px solid black;
}

.element:hover .tooltip {
  display: contents;
} */

.zu_common_data_table .zu_table_text {
  color: #131313b3;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  margin: 0;
  text-align: center;
}
.zu_common_data_table .table_text {
  color: #131313b3;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  margin: 0;
}

.zu_common_data_table .zu_table_label_text {
  padding: 7px 14px;
  border-radius: 5px;
  background: #f8eff9;
  color: #5f0166b3;
  font-family: "Proxima-Nova-Bold";
  font-size: 15px;
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.zu_invoice_section {
  margin-bottom: 40px;
}

.zu_common_data_table .iLyphs {
  filter: drop-shadow(0px 4px 10px #52565c1a);
}

.zu_common_data_table .rdt_Table .rdt_TableBody .rdt_TableRow:last-child {
  border: 0;
}

/* invoices page css end */

/* leads page css start */

.zu_leads_section {
  margin-bottom: 40px;
}

.zu_leads_data_content .zu_leads_data_column {
  margin-top: 30px;
}

.zu_leads_data_content .zu_leads_data_column:first-child {
  margin-top: 0;
}

.zu_lead_content_heading_row {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.zu_lead_content_heading_row .zu_lead_content_heading {
  margin-right: auto;
  margin-bottom: 0;
  color: #5f0166;
  font-size: 17px;
  font-family: "Proxima-Nova-Regular";
}

.zu_lead_content_heading_row .zu_common_filter_input_row {
  margin-bottom: 0;
  max-width: 470px;
  width: 100%;
  margin-left: 15px;
  margin-right: 0;
}

.zu_lead_content_heading_row .zu_common_filter_input_row > div {
  margin-right: 30px;
  margin-left: 0;
}

.zu_lead_content_heading_row .zu_common_filter_input_row > div:last-child {
  margin-right: 0;
}

/* leads page css end */

/* profile page css start */

.zu_mobile_edit_btn {
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #5f0166;
  border-radius: 30px;
}
.zu_mobile_edit_btn svg {
  width: 18px;
  height: 18px;
}

.zu_profile_page {
  max-width: calc(100% - 120px);
}

.zu_profile_common_heading {
  display: flex;
  align-items: center;
  margin-bottom: 27px;
}

.zu_profile_common_heading h2 {
  color: #4b4279;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
  margin-right: auto;
}

.zu_profile_common_heading .zu_profile_common_heading_view_all_btn {
  margin-left: auto;
  color: #817d7d;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  text-decoration: none;
  text-transform: uppercase;
  min-width: 100px;
  text-align: right;
}

.zu_profile_common_heading h2 ~ .zu_profile_common_heading_view_all_btn {
  margin-left: 15px;
}

.zu_profile_heading_row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.zu_profile_heading_row .zu_common_heading {
  margin-right: 15px;
}

.zu_profile_heading_row .zu_profile_heading_btn {
  margin-left: auto;
}

.zu_profile_page .zu_profile_heading_row .zu_profile_heading_btn {
  max-width: 237px;
  width: 100%;
}

.zu_profile_page
  .zu_profile_heading_row
  .zu_profile_heading_btn
  .zu_common_btn {
  text-transform: capitalize;
  font-family: "Proxima-Nova-Bold";
}

.zu_showcase_links_section {
  margin-bottom: 40px;
}

.zu_showcase_links_content .zu_profile_common_heading {
  margin-bottom: 20px;
}

.zu_showcase_links_content
  .zu_profile_common_heading
  .zu_profile_common_heading_view_all_btn {
  color: #2e4765;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
}

.zu_view_photos_btn {
  cursor: pointer;
}

.zu_view_photos_btn:hover {
  opacity: 0.7;
}

/* .react-photo-gallery--gallery img {
  transition: 1s ease;
}
.react-photo-gallery--gallery img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
} */

.zu_showcase_links_content .zu_showcase_links_nav {
  list-style: none;
  padding-left: 20px;
  margin: 0;
  list-style-type: decimal;
}

.zu_showcase_links_content .zu_showcase_links_nav li {
  margin-bottom: 29px;
}

.zu_showcase_links_content .zu_showcase_links_nav li:last-child {
  margin-bottom: 0;
}

.zu_showcase_links_content .zu_showcase_links_nav li a {
  color: #2e4765;
  font-size: 20px;
  font-family: "Proxima-Nova-Regular";
  word-break: break-word;
}

.zu_profile_rating_review_section {
  padding-top: 37.5px;
}

.zu_profile_rating_review_content .zu_profile_common_heading {
  margin-bottom: 38px;
}

.zu_profile_rating_review_content .zu_profile_rating_review_row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.zu_profile_rating_review_column {
  background: #ffffff;
  border: 1px dashed #01245b;
  border-radius: 10px;
  padding: 30px;
}

.zu_profile_rating_review_column .zu_profile_rating_review_img_row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.zu_profile_rating_review_column .zu_profile_rating_review_img_row img {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border: 1px solid #707070;
  border-radius: 71px;
  object-fit: cover;
  margin-right: 30px;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 2px 5px 0px #00000063;
}

.zu_profile_rating_review_column
  .zu_profile_rating_review_img_row
  .zu_profile_rating_review_user_name
  h3 {
  color: #262626;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 10px;
}

.zu_profile_rating_review_column
  .zu_profile_rating_review_img_row
  .zu_profile_rating_review_user_rating {
  display: flex;
  align-items: center;
}

.zu_profile_rating_review_column
  .zu_profile_rating_review_img_row
  .zu_profile_rating_review_user_rating
  svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  color: #ee9617;
}

.zu_profile_rating_review_column
  .zu_profile_rating_review_img_row
  .zu_profile_rating_review_user_rating
  svg:last-child {
  margin-right: 0;
}

.zu_profile_rating_review_column .zu_profile_rating_review_user_paragraph {
  color: #6c6c6c;
  font-family: "Proxima-Nova-Thin";
  font-size: 18px;
  margin-bottom: 0;
}

.zu_profile_page .zu_profile_job_back_btn {
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  color: #5f0166;
  background: transparent;
  text-decoration: underline;
  border: 0;
  padding: 0;
  display: block;
  margin: 30px auto 0;
}

/* profile page css end */

/* profile edit page css start */

.zu_profile_edit_page {
  max-width: calc(100% - 120px);
}

.zu_profile_edit_page
  .zu_profile_user_detail_content
  .zu_profile_user_detail_row {
  align-items: center;
}

.zu_specialty_label_section {
  margin-bottom: 40px;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_specialty_label_content .zu_specialty_label_row {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 5px;
  gap: 5px;
}

/* .zu_specialty_label_row .zu_specialty_label_column {
  padding: 2.5px;
  min-width: 150px;
} */

.zu_leads_filter:first-child {
  margin-right: 1rem;
}

.zu_select_label_box label {
  padding: 16px 12px;
  background: #f9fcff;
  border: 1px solid #e7e6e9;
  border-radius: 5px;
  color: #4b4279;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zu_select_label_box input:checked ~ label {
  background: #f8eff9;
  border-color: #efbff5;
  box-shadow: 0 0 0 1px #efbff5;
}

.zu_specialty_label_content .zu_profile_common_heading {
  margin-bottom: 20px;
}

.zu_profile_edit_input_section {
  margin-bottom: 40px;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_profile_edit_input_content .zu_profile_edit_input_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_textarea_content {
  grid-column: span 2 / span 2;
}
.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_textarea_content
  .card {
  border-radius: 0;
  border: 1px solid #e2e2e2;
}
.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_textarea_content
  .card
  .rdw-editor-toolbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #e2e2e2;
}
.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_textarea_content
  .card
  .rdw-editor-toolbar
  .rdw-dropdown-wrapper,
.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_textarea_content
  .card
  .rdw-editor-toolbar
  .rdw-option-wrapper {
  box-shadow: none !important;
  border: 1px solid #e2e2e2;
}

.zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row {
  display: flex;
  align-items: center;
}

.zu_profile_edit_bottom_btns
  .zu_profile_edit_bottom_btn_row
  .zu_profile_edit_bottom_back_btn {
  color: #5f0166;
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  text-decoration: underline;
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

.zu_profile_edit_bottom_btns
  .zu_profile_edit_bottom_btn_row
  .zu_profile_edit_bottom_btn_right_content {
  margin-left: auto;
  display: flex;
  align-items: center;
  max-width: 600px;
  width: 100%;
}
.zu_profile_edit_bottom_btns
  .zu_profile_edit_bottom_btn_row
  .zu_profile_edit_bottom_btn_right_content
  .zu_common_btn {
  margin-right: 30px;
  font-family: "Proxima-Nova-Bold";
}
.zu_profile_edit_bottom_btns
  .zu_profile_edit_bottom_btn_row
  .zu_profile_edit_bottom_btn_right_content
  .zu_common_btn:last-child {
  margin-right: 0px;
}

/* .zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row .zu_profile_edit_bottom_btn_right_content button {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
  padding: 27px 26px 26px;
  border-radius: 8px;
  background: #000000;
  width: 50%;
  margin: 0 13.5px;
  transition: all 0.3s ease-in-out;
}

.zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row .zu_profile_edit_bottom_btn_right_content .zu_profile_edit_bottom_save_btn {
  background: #5f0166;
  margin-left: 0;
}

.zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row .zu_profile_edit_bottom_btn_right_content .zu_profile_edit_bottom_save_btn:hover {
  background: #39003d;
}

.zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row .zu_profile_edit_bottom_btn_right_content .zu_profile_edit_bottom_submit_btn {
  background: #6ec49a;
  margin-right: 0;
}

.zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row .zu_profile_edit_bottom_btn_right_content .zu_profile_edit_bottom_submit_btn:hover {
  background: #569c7a;
} */

.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_input_content
  .project_edit_detail_divider {
  margin-bottom: 20px;
}
.zu_profile_edit_input_content
  .zu_profile_edit_input_row
  .zu_common_form_input_content
  .project_edit_detail_divider:last-child {
  margin-bottom: 0px;
}

.zu_common_form_phone {
  display: flex;
  align-items: end;
}

.send_otp_btn {
  /* height: fit-content; */
  margin-left: 1rem;
  width: 150px;
}

.sm-err-text {
  display: none;
}

.zu_edit_profile_add_remove_btn_row {
  display: flex;
  align-items: center;
  margin: 16px 0 0;
  justify-content: space-between;
}
.zu_edit_profile_add_remove_btn_row .zu_edit_profile_input_remove_btn {
  margin-left: auto;
}

.zu_edit_profile_add_remove_btn_row .zu_edit_profile_add_remove_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #5f0166;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
  background: #651fc127;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
}

.zu_edit_profile_add_remove_btn_row
  .zu_edit_profile_add_remove_btn.zu_edit_profile_add_btn {
  margin-left: auto;
}

.zu_edit_profile_add_remove_btn_row .zu_edit_profile_add_remove_btn:hover {
  color: #ffffff;
  background: #5f0166;
}

.zu_edit_profile_add_remove_btn_row
  .zu_edit_profile_input_remove_btn
  .zu_edit_profile_add_remove_btn {
  background: #d6465727;
  color: #d64657;
}
.zu_edit_profile_add_remove_btn_row
  .zu_edit_profile_input_remove_btn
  .zu_edit_profile_add_remove_btn:hover {
  background: #d64657;
  color: #ffffff;
}

/* profile edit page css end */

/* notification page css start */

.zu_notification_section .zu_common_heading {
  margin-bottom: 38px;
}

.zu_notification_content {
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_notification_content .zu_notification_content_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 60px 80px;
  gap: 60px 80px;
}

.zu_notification_content_column .zu_notification_content_column_heading {
  color: #5f0166;
  font-size: 24px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
}

.zu_notification_content_column .zu_notification_content_column_paragraph {
  color: #000000;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_notification_content_column
  .zu_notification_content_column_heading
  ~ .zu_notification_content_column_paragraph {
  margin-top: 15px;
}

.zu_notification_content_column .zu_notification_checkbox_list {
  margin-top: 25px;
}

.zu_notification_checkbox_list .zu_notification_checkbox {
  margin-bottom: 20px;
}

.zu_notification_checkbox_list .zu_notification_checkbox:last-child {
  margin-bottom: 0px;
}

.zu_notification_checkbox_list .zu_notification_checkbox label {
  border: 1px solid #dde4ea;
  border-radius: 2px;
  background: #ffffff;
  padding: 16px 20px 16px 50px;
  width: 100%;
  cursor: pointer;
  color: #000000;
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  position: relative;
}

.zu_notification_checkbox_list .zu_notification_checkbox label::before {
  content: "";
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid #dde4ea;
}

.zu_notification_checkbox_list .zu_notification_checkbox label::after {
  content: "";
  position: absolute;
  left: 19px;
  top: 45%;
  width: 7px;
  height: 12px;
  border: solid #5f0166;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translateY(-50%);
  opacity: 0;
}

.zu_notification_checkbox_list
  .zu_notification_checkbox
  input:checked
  ~ label::before {
  border-color: #5f0166;
  border-radius: 2px;
}

.zu_notification_checkbox_list
  .zu_notification_checkbox
  input:checked
  ~ label::after {
  opacity: 1;
}

.zu_notification_save_btn {
  margin-top: 50px;
}

.zu_notification_save_btn .zu_common_btn {
  max-width: 250px;
  margin: 0 auto;
  font-family: "Proxima-Nova-Bold";
}

.zu_no_jobs_found_content {
  color: #5f0166;
  display: flex;
  justify-content: center;
}

.zu_no_notification_found_content {
  color: #5f0166;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_heading {
  margin: 1.5rem 1rem;
  font-size: 22px;
}

/* notification page css end */

/* payment page css start */

.zu_payment_section .zu_common_heading {
  margin-bottom: 40px;
}
/* .zu_settings_heading {
  border: none;
  background: transparent;
} */

.zu_payment_content .zu_payment_content_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.zu_credit_card_container {
  display: flex;
}

.zu_credit_card_detail_card_input p,
.zu_credit_card_input p {
  display: none;
}

.creditcard_detail-left-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a4121233;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px #00000012;
  cursor: pointer;
}
.creditcard_detail-left-icon svg {
  color: #a41212;
  width: 20px;
  height: 20px;
}

.zu_payment_content .sc-eCYdqJ.hdGkUx {
  display: none;
}

.zu_payment_content_column {
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_payment_content_column .zu_payment_content_column_heading {
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: #000000;
  font-size: 25px;
  margin-bottom: 10px;
  font-family: "Proxima-Nova-Bold";
}

.zu_payment_content_column .zu_payment_content_column_paragraph {
  color: #000000;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 30px;
}

.zu_payment_content_column
  .zu_payment_input_form_list
  .zu_common_form_input_content
  .zu_common_form_input {
  /* border-color: #2e4765; */
  outline: 0;
}

.zu_payment_content_column
  .zu_payment_input_form_list
  .zu_common_form_input_content {
  margin-bottom: 20px;
}

.zu_payment_content_column
  .zu_payment_input_form_list
  .zu_common_form_input_content:last-child {
  margin-bottom: 0px;
}

.zu_payment_content_column .zu_payment_save_btn {
  margin-top: 30px;
}

.zu_payment_content_column .zu_payment_save_btn .zu_common_btn {
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
  margin-left: auto;
}

/* payment page css end */

/* manage new job post page css start */

.zu_manage_new_job_post_section .zu_common_heading {
  margin-bottom: 27px;
}

.zu_manage_new_job_post_content {
  background: #ffffff;
  border-radius: 30px;
  /* padding: 97px 30px 72px; */
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
}

.zu_blur_disable_form {
  filter: blur(3px);
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.zu_manage_new_job_post_content .zu_manage_new_job_post_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 123px;
  gap: 123px;
  /* max-width: 1236px; */
  width: 100%;
  margin: 0 auto;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_input
  .zu_common_form_input_content
  .zu_common_form_input_label {
  font-size: 22px;
  margin-bottom: 5px;
  color: #4b4279;
}

.zu_common_form_input_send_otp {
  padding: 16px 12px 16px 45px !important;
}

.zu_common_form_send_otp {
  position: relative;
}

.countrycode_span {
  position: absolute;
  left: 10px;
  bottom: 16px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_input
  .zu_common_form_input_content
  .zu_common_form_input {
  /* border-color: #2e4765; */
  border-radius: 0px;
  background: #ffffff;
  font-family: "Proxima-Nova-Regular";
  color: #212121;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  font-size: 16px;
  padding: 16px 12px;
  outline: 0;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_input
  .zu_common_form_input_content {
  margin-bottom: 20px;
}
.zu_manage_new_job_post_column .zu_manage_new_job_post_left_input,
.zu_manage_new_job_post_column .zu_manage_new_job_post_left_label_content {
  margin-bottom: 30px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_input
  .zu_common_form_input_content:last-child,
.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_label_content:last-child {
  margin-bottom: 0px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_label_content
  .zu_manage_new_job_post_left_label_heading {
  font-size: 22px;
  color: #4b4279;
  display: block;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 14px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_label_content
  .zu_specialty_label_row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 5px;
  gap: 5px;
}

/* .zu_manage_new_job_post_column .zu_manage_new_job_post_left_label_content .zu_specialty_label_row .zu_specialty_label_column {
  padding: 0px;
} */

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_left_label_content
  .zu_specialty_label_row
  .zu_select_label_box
  label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 23px; */
}

.zu_manage_new_job_post_column .zu_manage_new_job_post_discription_content {
  margin-bottom: 30px;
}

.zu_manage_new_job_post_column .zu_manage_new_job_post_discription_content h2 {
  color: #4b4279;
  font-family: "Proxima-Nova-Bold";
  font-size: 32px;
  margin-bottom: 10px;
}

.zu_manage_new_job_post_column .zu_manage_new_job_post_discription_content p {
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  margin-bottom: 12px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_discription_content
  .zu_manage_new_job_post_discription_input {
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  background: #ffffff;
  padding: 18px 12px 16px 12px;
  font-size: 16px;
  line-height: 1.2;
  outline: 0;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none;
  resize: none;
}

.zu_manage_new_job_post_discription_heading {
  display: flex;
  justify-content: space-between;
}

.zu_manage_new_job_post_discription_input::placeholder {
  color: #d2d0da;
}

.zu_manage_new_job_post_column .zu_manage_new_job_post_hour_price_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 37px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_hour_price_row
  .zu_manage_new_job_post_price_column {
  max-width: 58%;
  width: 100%;
  padding: 0 15px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_hour_price_row
  .zu_manage_new_job_post_hours_column {
  max-width: 42%;
  width: 100%;
  padding: 0 15px;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_hour_price_row
  .zu_common_form_input_content
  .zu_common_form_input_label {
  font-size: 22px;
  margin-bottom: 5px;
  color: #4b4279;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_post_hour_price_row
  .zu_common_form_input_content
  .zu_common_form_input {
  border-radius: 0px;
  background: #ffffff;
  font-family: "Proxima-Nova-Regular";
  color: #212121;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  font-size: 16px;
  padding: 16px 12px;
}

.zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text {
  position: relative;
  border-bottom: 1px solid #b4b7cc66;
  padding-bottom: 16px;
}

.zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text span {
  text-align: right;
  display: block;
  letter-spacing: -0.77px;
  color: #061058;
  opacity: 0.5;
  font-family: "Proxima-Nova-Regular";
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 5px;
  line-height: 1;
  width: calc(100% - 70px);
  margin-left: auto;
}

.zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text p {
  text-align: right;
  color: #061058;
  font-family: "Proxima-Nova-Regular";
  font-size: 32px;
  margin: 0;
  line-height: 1;
  width: calc(100% - 70px);
  margin-left: auto;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_total_payment_text
  .zu_manage_new_job_total_payment_card_icon {
  border-radius: 7px;
  background: #f5f6fa;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #e2e2e2;
}

.zu_manage_new_job_post_column
  .zu_manage_new_job_total_payment_text
  .zu_manage_new_job_total_payment_card_icon
  svg {
  width: 30px;
  height: 22px;
}

.zu_manage_new_job_post_content .zu_manage_new_job_btn_content {
  margin: 30px auto 0;
}

.zu_manage_new_job_btn_content .zu_common_btn {
  max-width: 250px;
  font-family: "Proxima-Nova-Bold";
  margin-left: auto;
}

.zu_manage_new_job_btn_content .zu_manage_new_job_back_btn {
  margin-top: 20px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  padding: 0;
  background: transparent;
  border: 0;
  text-decoration: underline;
  color: #5f0166;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
}

/* manage new job post page css end */

/* admin dashboard page css start */

.zu_admin_dashboard_crew_payment_column::before {
  display: none;
}
.zu_admin_dashboard_crew_payment_column {
  text-align: center;
}

.zu_admin_dashboard_crew_payment_column .zu_dashboard_job_content_column {
  max-width: 450px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
}

.zu_admin_dashboard_crew_payment_column .zu_common_btn {
  /* max-width: 450px;
  margin: 0 auto; */
  /* font-size: 30px; */
  font-family: "Proxima-Nova-Bold";
  /* padding: 26px 19px;
  line-height: 1.3; */
}

/* admin dashboard page css end */

/* admin manage job page css start */

.zu_common_input_search_icon input {
  background-image: url(/static/media/SearchIcon.2562a508.svg);
  background-size: 26px 26px;
}

.zu_admin_manage_job_banner_section {
  margin-bottom: 40px;
}

.zu_admin_manage_job_page .zu_manage_job_filter_row {
  margin-bottom: 34px;
}

.zu_admin_manage_job_page
  .zu_purchase_lead_msg_modal
  .zu_modal_common_btn_row
  .zu_common_btn {
  max-width: 178px;
}

/* admin manage job page css end */

/* admin manage users page css start */

.zu_admin_manage_user_banner_section {
  margin-bottom: 64px;
}

.zu_admin_manage_user_table_section .zu_common_job_heading {
  margin-bottom: 40px;
}

.zu_admin_manage_user_table_content .zu_admin_manage_user_table_row {
  display: block;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 33px;
  gap: 33px;
}

.zu_admin_manage_user_table_column .zu_admin_manage_user_table_heading_row {
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  /* min-height: 69px; */
}

.zu_admin_manage_user_table_heading_row .zu_admin_manage_user_table_heading {
  color: #2e4765;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
  margin-right: 10px;
  width: 100%;
}

.zu_admin_manage_user_table_heading_row .zu_common_filter_input_box {
  max-width: 372px;
  width: 100%;
  margin-left: auto;
}

.zu_admin_manage_user_table_heading_row .zu_common_filter_input_box input {
  width: 100%;
}
.zu_admin_manage_user_table_heading_row
  .zu_common_filter_input_box.zu_common_input_search_icon
  input {
  padding-right: 50px;
}

.zu_admin_manage_users_table
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell
  > div {
  width: 100%;
}

.zu_admin_manage_users_table
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:first-child,
.zu_admin_manage_users_table
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:first-child {
  padding-left: 46px;
}

.zu_admin_manage_users_table
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:last-child,
.zu_admin_manage_users_table
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:last-child {
  padding-right: 42px;
}

/* admin manage users page css end */

/* admin manage users profile page css start */

.zu_admin_manage_user_profile_page
  .zu_profile_heading_row
  .zu_profile_heading_btn {
  display: grid;
  max-width: 588px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 11px;
  gap: 11px;
}

/* .zu_admin_manage_user_profile_page .zu_profile_heading_row .zu_profile_heading_btn .zu_common_btn {
  border-radius: 8px;
  font-size: 20px;
} */

/* admin manage users profile page css end */

/* privacy terms page start */

.zu_privacy_terms_banner_img_text {
  height: 350px;
  overflow: hidden;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.zu_all_page_main_content .zu_privacy_terms_banner_img_text {
  margin-top: -30px;
  margin-left: -40px;
  margin-right: -40px;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.zu_privacy_terms_banner_img_text::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000073;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_text {
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 15px;
  text-align: center;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_heading {
  font-size: 50px;
  color: #ffffff;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_paragraph {
  font-size: 22px;
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 15px;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_updat_date {
  font-size: 16px;
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
}

.zu_privacy_terms_section {
  padding-bottom: 30px;
}
.zu_privacy_terms_section .container {
  display: block;
  min-height: auto;
}

.zu_all_page_main_content .zu_privacy_terms_section .container {
  padding: 0;
  max-width: 100%;
}

.zu_privacy_terms_content {
  border: 1px solid #e2e2e2;
  padding: 24px;
  border-radius: 5px;
}

.zu_privacy_terms_btn {
  color: #ffffff;
  text-decoration: none;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}
.zu_privacy_terms_btn:hover {
  color: #000000;
}
.zu_privacy_terms_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.zu_privacy_terms_btn:hover::before {
  width: 100%;
}

.zu_privacy_terms_common_heading {
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0px;
  color: #5f0166;
}
.zu_privacy_terms_common_paragraph {
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0px;
  color: #757575;
}
.zu_privacy_terms_common_paragraph a {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
}
.zu_privacy_terms_common_paragraph .zu_privacy_terms_common_paragraph_bold {
  font-family: "Proxima-Nova-Bold";
}

.zu_privacy_terms_common_list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.zu_privacy_terms_common_list li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}
.zu_privacy_terms_common_list li::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 12px;
  width: 12px;
  height: 2px;
  border-radius: 10px;
  background: #5f0166;
}
.zu_privacy_terms_common_list li:last-child {
  margin-bottom: 0px;
}

.zu_privacy_terms_covid_content {
  background: #d64657;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_covid_heading {
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  color: #edf000;
  margin-bottom: 20px;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_heading,
.zu_privacy_terms_covid_content .zu_privacy_terms_common_paragraph {
  color: #ffffff;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_list {
  counter-reset: my-awesome-counter;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_list li {
  counter-increment: my-awesome-counter;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_list li::before {
  content: counter(my-awesome-counter) ". ";
  color: #ffffff;
  background: transparent;
  width: auto;
  height: auto;
  left: 10px;
  top: 4px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
}
.zu_privacy_terms_covid_content .zu_privacy_terms_btn {
  margin: 25px auto 0;
}

.zu_privacy_terms_condition_pdf_content,
.zu_privacy_second_terms_condition_pdf_content {
  background: #00bf787a;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.zu_privacy_terms_condition_pdf_content .zu_privacy_terms_common_heading,
.zu_privacy_second_terms_condition_pdf_content
  .zu_privacy_terms_common_heading {
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  text-align: center;
  color: #ffffff;
}
.zu_privacy_terms_condition_pdf_content .zu_privacy_terms_btn,
.zu_privacy_second_terms_condition_pdf_content .zu_privacy_terms_btn {
  margin: 25px auto 0;
}

.zu_privacy_second_terms_condition_pdf_content {
  background: #000000;
  margin-top: 25px;
}

/* privacy terms page end */

/* common tab menu start */

.zu_common_tab_menu .nav-tabs {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  padding: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}
.zu_common_tab_menu .nav-tabs .nav-item {
  margin: 4px;
}
.zu_common_tab_menu .nav-tabs .nav-item .nav-link {
  margin: 0;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border-radius: 0px;
  padding: 10px 20px;
  width: 100%;
  border: 0;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 10px;
  overflow: hidden;
}
.zu_common_tab_menu .nav-tabs .nav-item .nav-link:hover {
  background: #00000017;
}
.zu_common_tab_menu .nav-tabs .nav-item .nav-link.active {
  background: #5f0166;
  color: #ffffff;
}

/* common tab menu end */
.zu_admin_manage_users_table .rdt_Table {
  min-width: 1024px;
}
.zu_admin_new_users_table .rdt_Table {
  min-width: 1200px;
}

.zu_common_filter_input_row .css-1mctpf5-MuiSlider-root {
  width: calc(100% - 40px);
}

.zu_sidemenu_modal .modal-dialog {
  max-width: 683px;
  margin-left: auto;
  margin-right: 0;
}
.zu_sidemenu_modal .modal-dialog .modal-content {
  background: transparent;
  border: 0;
}
.modal-dialog {
  max-width: 683px;
}

.zu_credit_card_detail_card_input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.zu_credit_card_detail_card_input .zu_credit_card_detail_card_input_add_btn {
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e4765cc;
  border-radius: 0px;
  padding: 17px 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  position: relative;
  z-index: 9;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 10px;
}
.zu_credit_card_detail_card_input
  .zu_credit_card_detail_card_input_add_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 100%;
  background: #2e4765;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.zu_credit_card_detail_card_input
  .zu_credit_card_detail_card_input_add_btn:hover::before {
  width: 100%;
}

.zu_credit_card_detail_card_input .err_text,
.zu_credit_card_input .err_text {
  /* position: absolute;
  top: 100%;
  left: 0-; */
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.zu_credit_card_modal .zu_credit_card_input {
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto 0;
}

.zu_card_input {
  width: 339px;
}

/* Chat Msg Page CSS start */

/* *******************************
message-area
******************************** */

.zu_chat_container {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  max-height: calc(100vh - 165px);
  /* height: 100%; */
  height: calc(100vh - 165px);
}
.zu_chat_container .zu_chat_content {
  display: flex;
  height: 100%;
}
.zu_chat_container .zu_chat_content .zu_chat_list_content {
  padding: 15px 15px 0;
  border-right: 1px solid #e2e2e2;
  max-width: 350px;
  width: 100%;
  background: #ffffff;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_content_heading {
  font-size: 26px;
  color: #000000;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 8px;
}

.zu_margin_bottom {
  margin-bottom: 28px !important;
}

.zu_chat_container .zu_chat_content .zu_chat_list_content .zu_chat_list_search {
  position: relative;
  margin-bottom: 16px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  input {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 36px;
  border: 0;
  font-size: 16px;
  color: #5f0166;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none !important;
  outline: 0;
  border: 1px solid #e2e2e2 !important;
  width: 100%;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  input::placeholder {
  color: #d2d0da;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  .zu_chat_list_search_icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #5f0166;
  pointer-events: none;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  .zu_chat_list_search_icon
  svg {
  width: 22px;
  height: 22px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  .zu_chat_list_search_remove_btn {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #5f0166;
  border: 0;
  padding: 0;
  background: transparent;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  .zu_chat_list_search_remove_btn
  svg
  * {
  stroke: #5f0166;
  stroke-width: 2px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_content
  .zu_chat_list_search
  .input-group-append
  svg {
  width: 16px;
  height: 24px;
  display: block;
  position: relative;
  top: 13px;
  left: 0px;
  transform: translateY(-50%);
  color: #5f0166;
  pointer-events: none;
}
.zu_chat_container .zu_chat_content .zu_chat_list_tab_row {
  margin: 0 -15px;
  border: 0;
}
.zu_chat_container .zu_chat_content .zu_chat_list_tab_row .nav-item {
  flex: 1 1;
}
.zu_chat_container .zu_chat_content .zu_chat_list_tab_row .nav-item .nav-link {
  border: 0;
  color: #000000;
  background: #00000008;
  text-decoration: none;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 0;
  display: block;
  width: 100%;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_list_tab_row
  .nav-item
  .nav-link.active {
  background: #5f0166;
  color: #ffffff;
}
.zu_chat_container .zu_chat_content .zu_chat_user_list {
  margin: 0 -15px;
  max-height: calc(100vh - 325px);
  height: auto;
  overflow-y: auto;
}
.zu_chat_container .zu_chat_content .zu_chat_user_list_heading_label {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  background: #ededed;
  padding: 5px 15px;
  margin-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}
.zu_chat_container .zu_chat_content .zu_chat_user_list_heading_label svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.zu_chat_container .zu_chat_content .zu_chat_user_list_nav {
  padding: 0;
  margin: 0;
  list-style: none;
}
.zu_chat_container .zu_chat_content .zu_chat_user_list_nav li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
}
/* .zu_chat_container .zu_chat_content .zu_chat_user_list_nav li::before {
  content: "";
  position: absolute;
  left: 50px;
  bottom: 18px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 50%;
  background: #ff6961;
  border: 1px solid #fff;
  box-shadow: 0px 2px 5px 0px #00000063;
} */

.zu_chat_container .zu_chat_content .zu_chat_user_list_nav li.online::before {
  background: #60b158;
  box-shadow: 0px 2px 5px 0px #00000063;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_img {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
  overflow: hidden;
  background: #5f0166;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 22px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
  box-shadow: 0px 2px 5px 0px #00000063;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  img.zu_chat_user_list_img {
  background: #ffffff;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text {
  padding-left: 15px;
  width: calc(100% - 50px);
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_name {
  display: flex;
  align-items: center;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_name
  h3 {
  width: 100%;
  font-size: 16px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_name
  span {
  font-size: 12px;
  font-family: "Proxima-Nova-Regular";
  color: #707070;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_paragraph {
  display: flex;
  align-items: center;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_paragraph
  p {
  font-size: 13px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_paragraph
  span {
  font-size: 11px;
  font-family: "Proxima-Nova-Regular";
  color: #ffffff;
  background: #5f0166;
  border-radius: 100px;
  padding: 2px 6.5px 1px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_last_msg {
  font-size: unset;
  margin: 0;
  overflow: hidden;
  white-space: normal;
  height: 19.5px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_last_msg_container {
  display: flex;
  align-items: center;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_last_msg_container
  .zu_chat_user_list_text_last_msg_name {
  font-size: 13px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  margin-bottom: 0;
  margin-right: 4px;
  white-space: nowrap;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav
  .zu_chat_user_list_text
  .zu_chat_user_list_text_last_msg
  p {
  font-size: 13px;
  font-family: "Proxima-Nova-Regular";
  color: #707070;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zu_chat_container .zu_chat_content .zu_chat_user_list_nav li:hover {
  background: #b7b7b70d;
}
.zu_chat_container .zu_chat_content .zu_chat_user_list_nav li.active {
  background: #5f016620;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list_nav:last-child
  li:last-child {
  border: 0;
}
.zu_chat_container .zu_chat_content .zu_chat_msg_content {
  width: calc(100% - 350px);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: calc(100vh - 167px);
  position: relative;
}

.zu_chat_container .zu_chat_content .zu_chat_msg_content .zu_chat_msg_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_content_back_btn {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  border-radius: 30px;
  margin-right: 10px;
  color: #000000;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_content_back_btn:hover,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_content_back_btn:active {
  background: #70707020;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_content_back_btn
  svg {
  width: 18px;
  height: 18px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content {
  display: flex;
  align-items: center;
  margin-right: 15px;
  position: relative;
}
/* .zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content::before {
  content: "";
  position: absolute;
  left: 37px;
  bottom: 6px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 50%;
  background: #ff6961;
  border: 1px solid #fff;
  box-shadow: 0px 2px 5px 0px #00000063;
} */
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content.online::before {
  background: #60b158;
  box-shadow: 0px 2px 5px 0px #00000063;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  .zu_chat_msg_header_img {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  object-fit: cover;
  background: #5f0166;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
  box-shadow: 0px 2px 5px 0px #00000063;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  img.zu_chat_msg_header_img {
  background: #ffffff;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  .zu_chat_msg_header_name_text {
  padding-left: 10px;
}

.zu_chat_msg_header_job_title {
  margin-right: 4px;
}

.zu_chat_msg_header_job_date {
  color: #a41262;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  .zu_chat_msg_header_name_text
  h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  color: #000000;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  .zu_chat_msg_header_name_text
  p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: #707070;
  white-space: nowrap;
  display: flex;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  .zu_chat_msg_header_name_text
  p
  span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 181px;
  display: block;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_name_content
  .zu_chat_msg_header_name_text
  .zu_chat_msg_header_name_text_job_id {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: #707070;
  display: block;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_content
  .zu_chat_msg_header
  .zu_chat_msg_header_view_job {
  border: 0;
  padding: 10px 20px;
  color: #ffffff;
  background: #5f0166;
  border-radius: 4px;
  text-decoration: none;
  font-size: 15px;
  margin-left: auto;
}
.zu_chat_container .zu_chat_content .zu_chat_msg_container {
  /* max-height: calc(100vh - 272px);
  height: 100%; */
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  /* display: flex; */
  background-image: url(/static/media/chatBgImage.98537d89.png);
  background-color: #f5f6faa3;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_container_inner_container {
  /* flex: 1 1; */
  /* overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 272px); */
  /* height: 100%; */
  width: 100%;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .message-container
  > button {
  background-image: url(/static/media/bottomArrowLine.973e2544.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #5f0166;
}
.zu_chat_container .zu_chat_content .zu_chat_msg_container::-webkit-scrollbar,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_write_content
  .rdw-editor-main::-webkit-scrollbar,
.zu_chat_container .zu_chat_content .zu_chat_img_upload_row::-webkit-scrollbar,
.zu_chat_container .zu_chat_content .zu_chat_user_list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 100px;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container::-webkit-scrollbar-track,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_write_content
  .rdw-editor-main::-webkit-scrollbar-track,
.zu_chat_container
  .zu_chat_content
  .zu_chat_img_upload_row::-webkit-scrollbar-trac,
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container::-webkit-scrollbar-thumb,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_write_content
  .rdw-editor-main::-webkit-scrollbar-thumb,
.zu_chat_container
  .zu_chat_content
  .zu_chat_img_upload_row::-webkit-scrollbar-thumb,
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list::-webkit-scrollbar-thumb {
  background: transparent;
  opacity: 0;
  border-radius: 100px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container:hover::-webkit-scrollbar-thumb,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_write_content
  .rdw-editor-main:hover::-webkit-scrollbar-thumb,
.zu_chat_container
  .zu_chat_content
  .zu_chat_img_upload_row:hover::-webkit-scrollbar-thumb,
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list:hover::-webkit-scrollbar-thumb {
  background: #d1d1d1;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container::-webkit-scrollbar-thumb:hover,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_write_content
  .rdw-editor-main::-webkit-scrollbar-thumb:hover,
.zu_chat_container
  .zu_chat_content
  .zu_chat_img_upload_row::-webkit-scrollbar-thumb:hover,
.zu_chat_container
  .zu_chat_content
  .zu_chat_user_list::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
  border-radius: 100px;
}

.zu_pin_msg_icon_no_pinned_msg_container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_box_content {
  padding: 0 30px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_box_content
  .zu_chat_msg_box_time {
  margin: 15px 0;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_box_time
  span {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  padding: 0 10px;
}
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_box_time::before,
.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_box_time::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #70707029;
  flex: 1 1;
}

.zu_no_msg_found {
  justify-content: center;
  align-items: center;
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
}

.zu_chat_msg_recive {
  display: flex;
  align-items: flex-start;
  max-width: calc(100% - 200px);
  margin: 10px 0;
}
.zu_chat_msg_recive .zu_chat_msg_recive_img {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
  overflow: hidden;
  background: #5f0166;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
}
.zu_chat_msg_recive .zu_chat_msg_recive_img_none {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
  overflow: hidden;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
}
.zu_chat_msg_recive img.zu_chat_msg_recive_img {
  background: #ffffff;
}

.zu_chat_msg_recive .zu_chat_msg_recive_text {
  padding-left: 15px;
  width: 100%;
}
.zu_chat_msg_recive .zu_chat_msg_recive_text .zu_chat_msg_recive_text_name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #707070;
  display: flex;
  align-items: center;
}
.zu_chat_msg_recive .zu_chat_msg_recive_text .zu_chat_msg_recive_text_name svg {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown {
  margin-left: 5px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-toggle,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-toggle {
  padding: 0;
  border-radius: 100px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/moreIcon.137b56a2.svg);
  box-shadow: none !important;
  border: 0 !important;
  color: #707070;
  background-color: transparent !important;
  background-size: 24px 24px;
  background-position: center center;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-toggle:hover,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-toggle:hover {
  background-color: #70707020 !important;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-toggle::after,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-toggle::after {
  display: none;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-toggle
  svg,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-toggle
  svg {
  width: 24px;
  height: 24px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu {
  border-color: #e2e2e2;
  padding: 0;
  box-shadow: 0 2px 5px 0px #00000010;
  overflow: hidden;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  padding: 10px 20px;
  color: #707070;
  border-bottom: 1px solid #e2e2e2;
  text-decoration: none;
  width: 100%;
  background: transparent;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:last-child,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:last-child {
  border-bottom: 0;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:active,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  background: #ededed;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item
  svg,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item
  svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #25265e;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover {
  color: #ffffff;
  background: #5f0166;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg,
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg {
  color: #ffffff;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box {
  border-radius: 0 10px 10px 10px;
  background: #ffffff;
  border: 1px solid #70707029;
  padding: 10px 15px;
  max-width: 200px;
  min-height: 152px;
  width: 100%;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box {
  position: relative;
  overflow: hidden;
  max-height: 130px;
  height: 130px;
  border-radius: 4px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box_text {
  margin: 0;
  font-size: unset;
  overflow: hidden;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box_text
  p {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 0;
  word-break: break-word;
  font-family: "Proxima-Nova-Regular";
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box_text {
  overflow: hidden;
  margin: 0;
  font-size: unset;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box_text
  p {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 0;
  word-break: break-word;
  font-family: "Proxima-Nova-Regular";
}

.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_msg_box_text {
  overflow: hidden;
  margin: 0;
  font-size: unset;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_msg_box_text
  p {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 0;
  word-break: break-word;
  font-family: "Proxima-Nova-Regular";
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_msg_box_text {
  overflow: hidden;
  margin: 0;
  font-size: unset;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_msg_box_text
  p {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 0;
  word-break: break-word;
  font-family: "Proxima-Nova-Regular";
}

.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box
  .zu_chat_msg_recive_img_button_row {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  width: 100%;
  height: 100%;
  background: #00000035;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box:hover
  .zu_chat_msg_recive_img_msg_box
  .zu_chat_msg_recive_img_button_row {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box
  .zu_chat_msg_recive_img_button_row
  .zu_chat_msg_recive_img_button {
  width: 30px;
  height: 30px;
  border: 0;
  background: #1e253880;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 5px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_img_msg_box_row
  .zu_chat_msg_recive_img_text_msg_box
  .zu_chat_msg_recive_img_msg_box
  .zu_chat_msg_recive_img_button_row
  .zu_chat_msg_recive_img_button
  svg {
  width: 18px;
  height: 18px;
}
/* //////////////////////////////// */

.zu_chat_msg_send .zu_chat_msg_send_text .zu_chat_msg_send_img_msg_box_row {
  display: flex;
  align-items: flex-start;
  justify-content: end;
  margin-top: 5px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box {
  border-radius: 10px 0 10px 10px;
  background: #e9f1fc;
  border: 1px solid #e9f1fc;
  /* background: #ededed;
  border: 1px solid #ededed; */
  padding: 10px 15px;
  max-width: 200px;
  min-height: 152px;
  width: 100%;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box {
  position: relative;
  overflow: hidden;
  max-height: 130px;
  height: 130px;
  border-radius: 4px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box
  .zu_chat_msg_send_img_button_row {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  width: 100%;
  height: 100%;
  background: #00000035;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box:hover
  .zu_chat_msg_send_img_msg_box
  .zu_chat_msg_send_img_button_row {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box
  .zu_chat_msg_send_img_button_row
  .zu_chat_msg_send_img_button {
  width: 30px;
  height: 30px;
  border: 0;
  background: #1e253880;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 5px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_img_msg_box_row
  .zu_chat_msg_send_img_text_msg_box
  .zu_chat_msg_send_img_msg_box
  .zu_chat_msg_send_img_button_row
  .zu_chat_msg_send_img_button
  svg {
  width: 18px;
  height: 18px;
}

/* ///////////////////////////////// */
.zu_chat_msg_recive .zu_chat_msg_recive_text .zu_chat_msg_recive_text_msg_box {
  background: #ffffff;
  border: 1px solid #70707029;
  border-radius: 0 10px 10px 10px;
  padding: 10px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box
  .zu_chat_msg_recive_text_msg {
  margin: 0;
  font-size: unset;
  overflow: hidden;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_text_msg_box
  .zu_chat_msg_recive_text_msg
  p {
  margin-bottom: 0;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-family: "Proxima-Nova-Regular";
}

.zu_chat_msg_send {
  display: flex;
  align-items: flex-start;
  max-width: calc(100% - 200px);
  margin: 10px 0 10px auto;
}
.zu_chat_msg_send .zu_chat_msg_send_text {
  width: 100%;
}
.zu_chat_msg_send .zu_chat_msg_send_text .zu_chat_msg_send_text_name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #707070;
  text-align: right;
  padding-right: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.zu_chat_msg_send .zu_chat_msg_send_text .zu_chat_msg_send_text_name svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.zu_chat_msg_send .zu_chat_msg_send_text .zu_chat_msg_send_text_msg_box_row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 5px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown {
  margin-left: 5px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-toggle {
  padding: 0;
  border-radius: 100px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  color: #707070;
  background-image: url(/static/media/moreIcon.137b56a2.svg);
  background-size: 24px 24px;
  background-position: center center;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-toggle:hover {
  background-color: #70707020 !important;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-toggle::after {
  display: none;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-toggle
  svg {
  width: 24px;
  height: 24px;
}

.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu {
  border-color: #e2e2e2;
  padding: 0;
  box-shadow: 0 2px 5px 0px #00000010;
  overflow: hidden;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  padding: 10px 20px;
  color: #707070;
  border-bottom: 1px solid #e2e2e2;
  text-decoration: none;
  width: 100%;
  background: transparent;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:last-child {
  border-bottom: 0;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  background: #ededed;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item
  svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #25265e;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover {
  color: #ffffff;
  background: #5f0166;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg {
  color: #ffffff;
}

/* //// */
.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-toggle {
  padding: 0;
  border-radius: 100px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  color: #707070;
  background-image: url(/static/media/moreIcon.137b56a2.svg);
  background-size: 24px 24px;
  background-position: center center;
}
.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-toggle:hover {
  background-color: #70707020 !important;
}

.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-toggle::after {
  display: none;
}
.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-toggle svg {
  width: 24px;
  height: 24px;
}
.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-menu {
  border-color: #e2e2e2;
  padding: 0;
  box-shadow: 0 2px 5px 0px #00000010;
  overflow: hidden;
}
.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  padding: 10px 20px;
  color: #707070;
  border-bottom: 1px solid #e2e2e2;
  text-decoration: none;
  width: 100%;
  background: transparent;
}
.zu_chat_msg_send_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:last-child {
  border-bottom: 0;
}

.zu_chat_msg_send_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  background: #ededed;
}
.zu_chat_msg_send_img_msg_box_row .dropdown .dropdown-menu .dropdown-item svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #25265e;
}
.zu_chat_msg_send_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover {
  color: #ffffff;
  background: #5f0166;
}
.zu_chat_msg_send_img_msg_box_row
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg {
  color: #ffffff;
}
/* //// */

.zu_chat_msg_send .zu_chat_msg_send_text .zu_chat_msg_send_text_msg_box {
  /* background: #ededed;
  border: 1px solid #ededed; */
  background: #e9f1fc;
  border: 1px solid #e9f1fc;
  border-radius: 10px 0 10px 10px;
  padding: 10px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  min-height: 42px;
  min-width: 42px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box
  .spinner-border {
  position: absolute;
  top: 8px;
  left: 7px;
  width: 25px;
  height: 25px;
  border-width: 3px;
  border-color: #5f0166;
  border-right-color: transparent;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box
  .zu_chat_msg_send_text_msg {
  margin: 0;
  font-size: unset;
  overflow: hidden;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_text_msg_box
  .zu_chat_msg_send_text_msg
  p {
  margin-bottom: 0;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-family: "Proxima-Nova-Regular";
}

.zu_chat_msg_write_content {
  position: relative;
}
.zu_chat_msg_write_content .rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #e2e2e2;
}
.zu_chat_msg_write_content .rdw-editor-wrapper .rdw-editor-toolbar {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: 0;
  border-top: 1px solid #e2e2e200;
  height: 0;
  overflow: hidden;
}
.zu_chat_msg_write_content.is-open .rdw-editor-wrapper .rdw-editor-toolbar {
  padding: 8px;
  border-top: 1px solid #e2e2e2;
  height: auto;
}
.zu_chat_msg_write_content .rdw-editor-wrapper .rdw-editor-toolbar > div {
  margin: 0;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > .rdw-emoji-wrapper {
  position: absolute;
  right: 78px;
  bottom: 10px;
  z-index: 99;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > .rdw-emoji-wrapper:hover {
  background: #70707020;
}
.zu_chat_msg_write_content.is-open
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > .rdw-emoji-wrapper {
  bottom: 50px;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > div
  .rdw-option-wrapper {
  height: 24px;
  padding: 3px 5px;
  width: 28px;
  border: 0;
  margin: 0;
  box-shadow: none !important;
  opacity: 0.5;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > .rdw-emoji-wrapper
  .rdw-option-wrapper {
  opacity: 1;
  background: transparent;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > div
  .rdw-emoji-modal {
  right: 0;
  left: auto;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .rdw-editor-toolbar
  > div
  .rdw-option-wrapper.rdw-option-active {
  opacity: 1;
}
.zu_chat_msg_write_content .rdw-editor-wrapper .public-DraftStyleDefault-block {
  margin: 0;
  padding: 0px;
}
.zu_chat_msg_write_content .rdw-editor-wrapper .public-DraftEditor-content {
  padding: 13px 180px 13px 10px;
  max-height: 146px;
}
.zu_chat_msg_write_content.is-edit
  .rdw-editor-wrapper
  .public-DraftEditor-content {
  padding: 13px 215px 13px 10px;
}
.zu_chat_msg_write_content
  .rdw-editor-wrapper
  .public-DraftEditorPlaceholder-root {
  padding: 13px 10px;
}
.zu_chat_msg_write_content .rdw-editor-wrapper .public-DraftStyleDefault-ol,
.zu_chat_msg_write_content .rdw-editor-wrapper.public-DraftStyleDefault-ul {
  margin: 0;
}
.zu_chat_msg_write_content .zu_chat_msg_write_btn_row {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 8px;
  z-index: 99;
}
.zu_chat_msg_write_content.is-open .zu_chat_msg_write_btn_row {
  bottom: 48px;
}
.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_icon_btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  border-radius: 30px;
  margin-right: 5px;
  cursor: pointer;
}
.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_icon_btn.zu_chat_msg_write_emoji_icon_btn {
  opacity: 0;
  pointer-events: none;
}
.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_icon_btn:hover {
  background: #70707020;
}
.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_icon_btn
  svg {
  width: 18px;
  height: 18px;
}

.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_send_btn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: #5f0166;
  border-radius: 4px;
}

.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_send_btn:disabled {
  background: #925a96;
}
.zu_chat_msg_write_content
  .zu_chat_msg_write_btn_row
  .zu_chat_msg_write_send_btn
  svg {
  width: 18px;
  height: 18px;
}

.zu_chat_emoji_box {
  position: absolute;
  bottom: 42px;
  right: 56px;
}

.zu_chat_img_zoom_modal {
  position: fixed;
  left: 0;
  top: 0;
  transform: scale(0.75);
  z-index: -9;
  opacity: 0;
  padding: 75px 15px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000099;
  transition: all 0.3s ease-in-out;
}
.zu_chat_img_zoom_modal.zu_modal_show {
  transform: scale(1);
  z-index: 999999;
  opacity: 1;
}
.zu_chat_img_zoom_modal .zu_chat_img_zoom_modal_img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.zu_chat_img_zoom_modal .zu_chat_img_zoom_modal_close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
}
.zu_chat_img_zoom_modal .zu_chat_img_zoom_modal_close svg {
  width: 18px;
  height: 18px;
}
.zu_chat_img_zoom_modal .zu_chat_img_zoom_modal_img_download {
  position: absolute;
  right: 66px;
  top: 20px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
}
.zu_chat_img_zoom_modal .zu_chat_img_zoom_modal_img_download svg {
  width: 22px;
  height: 22px;
}

.zu_chat_img_upload_row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #e2e2e2;
  padding: 10px;
  max-height: 181px;
  overflow-y: auto;
}
.zu_chat_img_upload_row .zu_chat_img_upload_col {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background: #ededed;
  margin: 5px;
  border-radius: 4px;
  position: relative;
}
.zu_chat_img_upload_row .zu_chat_img_upload_col img {
  width: 70px;
  height: 70px;
  filter: blur(3px);
  object-fit: cover;
}
.zu_chat_img_upload_row .zu_chat_img_upload_col p {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin: 4px 0 0;
}
.zu_chat_img_upload_row .zu_chat_img_upload_col .zu_chat_img_upload_col_delete {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000045;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.zu_chat_img_upload_row
  .zu_chat_img_upload_col:hover
  .zu_chat_img_upload_col_delete {
  opacity: 1;
}
.zu_chat_img_upload_row
  .zu_chat_img_upload_col
  .zu_chat_img_upload_col_delete
  svg {
  width: 24px;
  height: 24px;
  color: #ffffff;
  stroke-width: 2px;
}

.zu_chat_msg_send .zu_chat_msg_send_text .zu_chat_msg_send_file_msg_box {
  background: #e9f1fc;
  border: 1px solid #e9f1fc;
  /* background: #ededed;
  border: 1px solid #ededed; */
  border-radius: 10px 0 10px 10px;
  max-width: 215px;
  width: 100%;
  padding: 10px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box {
  background: #ffffff;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_icon {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: #007aff26;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_icon
  p {
  font-size: 12px;
  line-height: 14px;
  color: #007aff;
  text-align: center;
  margin-bottom: 0;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_icon
  svg {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_icon
  svg
  path {
  fill: #007aff;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_name {
  padding: 0 10px;
  width: calc(100% - 70px);
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_name
  p {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_name
  span {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: block;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_button {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.zu_chat_msg_send
  .zu_chat_msg_send_text
  .zu_chat_msg_send_file_msg_box
  .zu_chat_msg_send_file_inner_msg_box
  .zu_chat_msg_send_file_msg_button
  svg {
  width: 20px;
  height: 20px;
}

/* ///// */
.zu_chat_msg_recive .zu_chat_msg_recive_text .zu_chat_msg_recive_file_msg_box {
  background: #ffffff;
  border: 1px solid #70707029;
  border-radius: 0 10px 10px 10px;
  max-width: 215px;
  width: 100%;
  padding: 10px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box {
  background: #ededed;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_icon {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_icon
  p {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  margin-bottom: 0;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_icon
  svg {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_name {
  padding: 0 10px;
  width: calc(100% - 70px);
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_name
  p {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_name
  span {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: block;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_button {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.zu_chat_msg_recive
  .zu_chat_msg_recive_text
  .zu_chat_msg_recive_file_msg_box
  .zu_chat_msg_recive_file_inner_msg_box
  .zu_chat_msg_recive_file_msg_button
  svg {
  width: 20px;
  height: 20px;
}
/* ///// */

.rdw-emoji-modal {
  bottom: 35px;
  top: auto;
}

.zu_chat_container
  .zu_chat_content
  .zu_chat_msg_container
  .zu_chat_msg_box_content.zu_chat_msg_box_content_loader {
  padding-right: 65px;
}
.zu_chat_msg_box_content_loader .zu_chat_msg_send_text_msg {
  padding: 1px;
}
.zu_chat_msg_box_content_loader .zu_chat_msg_box_content_loader_box {
  border: 4px solid #5f0166;
  border-right-color: transparent;
  width: 32px;
  height: 32px;
  animation: 0.75s linear infinite spinner-border;
  border-radius: 32px;
}

.zu_chat_edit_mode_on_text {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: -19px;
  position: relative;
  z-index: 9;
  padding-left: 10px;
}

.zu_chat_msg_container.is-edit-text-on > div > div {
  /* padding-bottom: 20px; */
}

.zu_msg_not_open_content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 15px;
}
.zu_msg_not_open_content .zu_msg_not_open_top_content {
  text-align: center;
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.zu_msg_not_open_content .zu_msg_not_open_top_content svg {
  margin-bottom: 15px;
  width: 70px;
  height: 70px;
}
.zu_msg_not_open_content .zu_msg_not_open_top_content h2 {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #1e2538;
  margin-bottom: 10px;
}
.zu_msg_not_open_content .zu_msg_not_open_top_content p {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  max-width: 440px;
  margin: 0 auto;
}
.zu_msg_not_open_content .zu_msg_not_open_bottom_content {
  flex: 1 1;
  text-align: center;
}
.zu_msg_not_open_content .zu_msg_not_open_bottom_content img {
  box-shadow: 0 10px 20px rgba(30, 37, 56, 0.1);
  display: inline-flex;
  border-radius: 100px;
  border: 3px solid #fff;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.zu_msg_not_open_content .zu_msg_not_open_bottom_content p {
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin-top: 5px;
}

.zu_chat_msg_top_bottom_arrow {
  width: 24px;
  height: 24px;
  border: 1px solid #5f0166;
  /* position: sticky;
  bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  /* margin-top: -29px; */
  /* margin-bottom: 5px; */
  border-radius: 24px;
  padding: 5px;
  margin-left: 5px;
  /* margin-right: 5px; */
}

/* Chat Msg Page CSS End */

/* media query start */

/* @media (max-width: 1900px) {
  .zu_profile_rating_review_column .zu_profile_rating_review_img_row img {
    width: 65px;
    min-width: 65px;
    height: 65px;
    margin-right: 20px;
  }
} */

/* custom select option start */
.zu-custom-select-2 .select__control {
  border: 2px solid #5f0166;
  padding: 2px;
  width: 300px;
  box-shadow: none !important;
  background: #f9fcff;
}
.zu-custom-select-2 .select__control:hover {
  border: 2px solid #5f0166;
  box-shadow: none !important;
}
.zu-custom-select-2 .select__control .select__value-container {
  color: #5f0166;
}

.zu-custom-select-2 .select__control .select__placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #b3b8bd;
}

.zu-custom-select-2 .select__control .select__input-container .select__input {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #4b4279 !important;
  border: 0 !important;
}
.zu-custom-select-2
  .select__control
  .select__indicators
  .select__indicator-separator {
  display: none;
}
.zu-custom-select-2
  .select__control
  .select__indicators
  .select__dropdown-indicator {
  color: #4b4279;
  padding: 2px;
}
.zu-custom-select-2
  .select__control
  .select__indicators
  .select__dropdown-indicator:before {
  color: #4b4279;
}

.zu-custom-select-2 .select__clear-indicator {
  color: #4b4279;
  padding: 2px;
}

.zu-custom-select-2 .select__clear-indicator:before {
  color: #4b4279;
}

.zu-custom-select-2 .select__single-value {
  color: #4b4279;
  font-weight: 600;
}
.zu-custom-select-2 .select__option--is-selected {
  color: #4b4279;
  background-color: #c2bbe3;
  font-weight: 600;
}
.zu-custom-select-2 .select__option--is-focused {
  color: #111111;
  background-color: #c2bbe3;
}

.zu-custom-select-2 .select__control.select__control--is-focused {
  border: 2px solid #5f0166;
}
/* custom select option end */

@media (max-width: 1800px) {
  .zu_profile_rating_review_column {
    padding: 20px;
  }
}

@media (max-width: 1700px) {
  .zu_jobs_column .zu_jobs_column_left_content {
    width: calc(100% - 320px);
  }

  .zu_jobs_column .zu_jobs_column_right_content {
    padding: 30px 50px 35px;
    max-width: 320px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    h3 {
    font-size: 22px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    p {
    font-size: 16px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_price_more_detail {
    max-width: 150px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_price_more_detail
    p {
    font-size: 18px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row,
  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_price_more_detail
    p {
    margin-bottom: 15px;
  }

  .zu_jobs_column
    .zu_jobs_column_right_content
    .zu_jobs_column_right_payment_text,
  .zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_text_row {
    margin-bottom: 18px;
  }

  .zu_leads_data_column {
    padding: 35px 30px;
  }

  /* .zu_leads_data_column_date_text, */
  /* .zu_leads_data_column_type_text {
    margin-bottom: 20px;
  } */

  /* .zu_leads_data_column_date_text p, */
  /* .zu_leads_data_column_date_text span {
    font-size: 26px;
  } */

  /* .zu_leads_data_column_lead_contact h4 {
    margin-bottom: 16px;
  } */

  /* .zu_leads_data_column .zu_leads_data_column_right_text {
    max-width: 81.8%;
    padding-left: 30px;
  } */

  /* .zu_leads_data_column_event_info .zu_leads_data_column_event_info_row .zu_leads_data_column_event_info_detail_text {
    padding-left: 30px;
  } */

  /* .zu_leads_data_column .zu_leads_data_column_right_text .zu_leads_data_column_event_info {
    padding-right: 30px;
  } */

  .zu_leads_data_column
    .zu_leads_data_column_right_text
    .zu_leads_data_column_btn_content {
    max-width: 230px;
  }

  .zu_profile_rating_review_column
    .zu_profile_rating_review_img_row
    .zu_profile_rating_review_user_name
    h3 {
    /* font-size: 20px; */
    margin-bottom: 10px;
  }

  /* .zu_profile_rating_review_column .zu_profile_rating_review_img_row .zu_profile_rating_review_user_rating svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  } */
}

@media (max-width: 1600px) {
  /* .zu_login_form_content .zu_login_info_btn_content {
    padding: 0 50px;
  } */

  .zu_jobs_column .zu_jobs_column_left_content {
    padding: 30px 25px 35px;
    width: calc(100% - 270px);
  }

  .zu_jobs_column .zu_jobs_column_right_content {
    padding: 30px 25px 35px;
    max-width: 270px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_description_text,
  .zu_profile_rating_review_column .zu_profile_rating_review_user_paragraph {
    font-size: 16px;
  }

  .zu_manage_job_other_detail_content svg {
    margin-right: 20px;
  }

  .zu_manage_job_other_detail_content
    .zu_manage_job_other_detail_content_text
    h3,
  .zu_profile_rating_review_column
    .zu_profile_rating_review_img_row
    .zu_profile_rating_review_user_name
    h3,
  .zu_profile_user_detail_content .zu_profile_user_detail_about_text p {
    font-size: 18px;
  }

  .zu_manage_job_other_detail_content
    .zu_manage_job_other_detail_content_text
    p,
  .zu_profile_user_detail_content .zu_profile_user_detail_message_text p {
    font-size: 20px;
  }

  .zu_showcase_content .react-photo-gallery--gallery > div img {
    height: 160px;
  }

  .zu_profile_rating_review_column .zu_profile_rating_review_img_row img {
    width: 55px;
    min-width: 55px;
    height: 55px;
    margin-right: 15px;
  }

  .zu_profile_rating_review_column
    .zu_profile_rating_review_img_row
    .zu_profile_rating_review_user_rating
    svg {
    width: 18px;
    height: 18px;
  }

  .zu_profile_rating_review_column .zu_profile_rating_review_img_row {
    margin-bottom: 20px;
  }

  /* .zu_profile_user_detail_content .zu_profile_user_detail_photo {
    height: 180px;
    width: 180px;
    min-width: 180px;
  } */

  /* .zu_profile_user_detail_content .zu_profile_user_detail_text_content .zu_profile_user_detail_heading {
    font-size: 40px;
  } */

  /* .zu_profile_user_detail_content .zu_profile_user_detail_message_text h3 {
    font-size: 26px;
  } */

  .zu_manage_new_job_post_content .zu_manage_new_job_post_row {
    grid-gap: 80px;
    gap: 80px;
  }

  /* .zu_manage_new_job_post_column .zu_manage_new_job_post_left_label_content .zu_specialty_label_row .zu_select_label_box label {
    padding: 23px 15px;
  } */

  /* .zu_admin_manage_user_table_column .zu_admin_manage_user_table_heading_row {
    display: block;
    min-height: auto;
  } */

  .zu_admin_manage_user_table_heading_row .zu_admin_manage_user_table_heading {
    margin-right: 0;
  }

  .zu_admin_manage_user_table_heading_row
    .zu_admin_manage_user_table_heading
    ~ .zu_common_filter_input_box {
    margin-top: 15px;
    margin-left: 0;
    max-width: 100%;
  }

  .zu_common_heading_paragraph_modal .zu_common_modal_paragraph {
    font-size: 18px;
  }
}

@media (max-width: 1500px) {
  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    h3 {
    font-size: 20px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    span,
  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    p,
  .zu_leads_data_column_event_info_detail_text p {
    font-size: 14px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_price_more_detail {
    max-width: 140px;
  }

  .zu_common_banner_content h1 {
    font-size: 50px;
  }

  /* .zu_dashboard_job_section {
    margin-bottom: 40px;
  } */

  /* .zu_dashboard_job_content_column {
    padding: 45px 30px;
    border-radius: 25px;
  } */

  /* .zu_dashboard_job_content_column svg {
    width: 45px;
    height: 45px;
    margin-right: 20px;
  } */

  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2,
  .zu_dashboard_crew_payment_column p {
    font-size: 40px;
  } */

  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text p, */
  /* .zu_leads_data_column_date_text p, */
  /* .zu_leads_data_column_date_text span {
    font-size: 22px;
  } */

  /* .zu_dashboard_crew_payment_column h2 {
    font-size: 35px;
  }

  .zu_dashboard_crew_payment_column span {
    font-size: 20px;
  } */

  .zu_manage_job_filter_row .zu_common_btn {
    max-width: 190px;
  }

  /* .zu_manage_job_detail_column_content .zu_manage_job_detail_left_content,
  .zu_manage_job_applicant_list_column,
  .zu_manage_job_contact_column {
    padding: 40px 30px;
  } */

  /* .zu_manage_job_other_detail_content {
    padding: 24px 20px 33px;
  } */

  /* .zu_leads_data_column_event_info_left_text .zu_leads_data_column_event_info_left_text_content p, */
  .zu_leads_data_column_btn_content .zu_leads_data_column_btn_heading,
  .zu_leads_data_column_btn_content .zu_leads_data_column_btn p {
    font-size: 16px;
  }

  .zu_leads_data_column_event_info_left_text
    .zu_leads_data_column_event_info_left_text_content {
    margin-bottom: 25px;
  }

  /* .zu_leads_data_column_event_info_left_text .zu_leads_data_column_event_info_left_text_content h4 {
    font-size: 18px;
    margin-bottom: 5px;
  } */

  .zu_leads_data_column_btn_content .zu_leads_data_column_btn .zu_common_btn,
  .zu_leads_data_column_btn_content
    .zu_leads_data_column_edit_archiv_btn
    .zu_common_btn {
    padding: 16px 12px;
    font-size: 13px;
  }

  .zu_leads_data_column
    .zu_leads_data_column_right_text
    .zu_leads_data_column_btn_content {
    max-width: 180px;
  }

  .zu_showcase_content .react-photo-gallery--gallery > div {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .zu_profile_rating_review_content .zu_profile_rating_review_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content {
    max-width: 600px;
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content
    button {
    font-size: 18px;
  }

  /* .zu_notification_checkbox_list .zu_notification_checkbox label {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 22px;
  } */

  /* .zu_notification_content .zu_notification_content_row {
    gap: 60px;
  } */

  /* .zu_payment_content_column {
    padding: 50px;
  } */

  .zu_payment_content_column .zu_payment_content_column_heading {
    margin-bottom: 15px;
    font-size: 22px;
  }

  .zu_payment_content_column .zu_payment_content_column_paragraph {
    font-size: 16px;
    /* margin-bottom: 30px; */
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_price_column,
  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_hours_column {
    max-width: 100%;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_hours_column {
    margin-top: 30px;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_discription_content
    .zu_manage_new_job_post_discription_input {
    max-height: 250px;
  }

  /* .zu_manage_new_job_post_content {
    padding: 50px 30px;
  } */

  /* .zu_admin_manage_user_profile_page .zu_profile_heading_row .zu_profile_heading_btn {
    max-width: 570px;
  } */

  .zu_specialty_label_content .zu_specialty_label_row {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 1400px) {
  .zu_jobs_column .zu_jobs_column_left_content {
    width: 100%;
    padding: 30px 25px;
  }

  .zu_jobs_column .zu_jobs_column_right_content {
    padding: 30px 25px;
    border-left: 0;
    border-top: 2px solid #f5f6fa;
  }

  .zu_jobs_column .zu_jobs_column_right_content {
    max-width: 100%;
  }

  .zu_jobs_column {
    display: block;
  }

  .zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_text_row {
    display: flex;
    align-items: center;
  }

  .zu_jobs_column
    .zu_jobs_column_right_content
    .zu_jobs_column_right_payment_text {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .zu_jobs_column
    .zu_jobs_column_right_content
    .zu_jobs_column_right_price_text {
    margin-left: auto;
  }

  .zu_jobs_column .zu_jobs_column_right_content .zu_admin_cancel_text {
    display: flex;
    justify-content: center;
    margin-left: auto;
    color: #a41212;
  }

  .zu_common_btn {
    padding: 16px 18px;
    font-size: 15px;
  }

  .zu_common_btn svg {
    width: 18px;
    height: 18px;
  }

  .zu_jobs_column .zu_find_jobs_column_right_content {
    flex-direction: column-reverse;
  }

  .zu_jobs_column
    .zu_find_jobs_column_right_content
    .zu_jobs_column_price_more_detail_btn {
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .zu_jobs_column
    .zu_jobs_column_right_content
    .zu_jobs_column_right_payment_text {
    margin: 20px 0;
    text-align: left;
  }

  .zu_jobs_column .zu_jobs_column_bookmark_btn {
    right: 27px;
    top: 17px;
  }

  .zu_manage_job_filter_row .zu_common_filter_input_row {
    margin: 0 0 0 -10px;
  }

  .zu_manage_job_filter_row .zu_common_filter_input_row > div {
    margin: 0 10px;
  }

  .zu_manage_job_applicant_list_link {
    padding: 20px 0px;
    background: transparent;
    border: transparent;
  }

  .zu_manage_job_applicant_list_column .zu_manage_job_applicant_list_heading {
    padding: 0px 0px 20px;
  }

  .zu_manage_job_contact_column .zu_manage_job_contact_column_heading,
  .zu_manage_job_contact_column
    .zu_manage_job_contact_details
    .zu_manage_job_contact_details_heading,
  .zu_manage_job_applicant_list_column
    .zu_manage_job_applicant_list_heading
    h3 {
    font-size: 22px;
  }

  .zu_manage_job_contact_column
    .zu_manage_job_contact_details_row
    .zu_manage_job_contact_details_user_img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .zu_manage_job_contact_column
    .zu_manage_job_contact_details
    .zu_manage_job_contact_details_number,
  .zu_manage_job_contact_column
    .zu_manage_job_contact_details
    .zu_manage_job_contact_details_email {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .zu_manage_job_contact_column
    .zu_manage_job_contact_details
    .zu_manage_job_contact_details_web_link {
    font-size: 16px;
  }

  .zu_manage_job_other_detail_content svg {
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row {
    max-width: 500px;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row
    .zu_manage_job_edit_cancel_btn {
    margin-right: 30px;
    padding: 20px 24px;
  }

  .zu_leads_data_column_event_info .zu_leads_data_column_event_info_row {
    display: block;
  }

  .zu_leads_data_column_event_info
    .zu_leads_data_column_event_info_row
    .zu_leads_data_column_event_info_detail_text {
    padding-left: 0;
  }

  .zu_leads_data_column_event_info
    .zu_leads_data_column_event_info_row
    .zu_leads_data_column_event_info_left_text {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 30px;
    max-width: 100%;
  }

  .zu_leads_data_column_event_info_left_text
    .zu_leads_data_column_event_info_left_text_content {
    margin: 0;
  }

  .zu_showcase_content .react-photo-gallery--gallery > div {
    grid-gap: 30px;
    gap: 30px;
  }

  .zu_profile_page,
  .zu_profile_edit_page {
    max-width: 100%;
  }

  .zu_profile_edit_input_content .zu_profile_edit_input_row {
    grid-gap: 30px;
    gap: 30px;
  }

  /* .zu_profile_edit_input_section {
    padding-bottom: 15px;
  } */

  /* .zu_upload_image_section {
    padding-top: 15px;
  } */

  .zu_specialty_label_content .zu_profile_common_heading {
    margin-bottom: 20px;
  }

  /* .zu_specialty_label_row .zu_specialty_label_column {
    padding: 10px;
  } */

  /* .zu_select_label_box label {
    padding: 20px 28px;
    font-size: 16px;
  } */

  .zu_specialty_label_section {
    padding-bottom: 40px;
  }

  .zu_upload_image_dropbox .zu_upload_image_priview_row {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  /* .zu_notification_content_column .zu_notification_content_column_heading {
    font-size: 20px;
  } */

  /* .zu_notification_content_column .zu_notification_content_column_paragraph {
    font-size: 18px;
  } */

  /* .zu_notification_content_column .zu_notification_content_column_heading~.zu_notification_content_column_paragraph {
    margin-top: 25px;
  } */

  .zu_payment_content_column .zu_payment_content_column_heading {
    font-size: 20px;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_left_label_content
    .zu_specialty_label_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .zu_chat_msg_recive,
  .zu_chat_msg_send {
    max-width: calc(100% - 100px);
  }
}

@media (max-width: 1300px) {
  .zu_notifications_list_view {
    width: 100%;
  }

  .zu_manage_new_job_post_discription_heading {
    display: block;
    margin-bottom: 10px;
  }
  /* .zu_login_section .zu_login_content .zu_login_form_content {
    padding: 70px 0;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form {
    padding: 0px 50px 70px;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_logo svg,
  .zu_signup_content .zu_signup_form_logo {
    max-width: 400px;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_logo,
  .zu_signup_content .zu_signup_form_logo {
    margin-bottom: 50px;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_heading,
  .zu_signup_content .zu_signup_form_heading,
  .zu_signup_content .zu_signup_form_mobile_heading {
    margin-bottom: 40px;
  } */

  .zu_login_section .zu_login_content .zu_login_form_content {
    max-width: 600px;
    min-width: 600px;
  }

  .zu_login_section .zu_login_content .zu_login_image_content {
    max-width: 100%;
  }

  .zu_signup_section {
    padding: 50px 30px;
  }

  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    font-size: 36px;
  } */

  /* .zu_dashboard_crew_payment_column h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .zu_dashboard_crew_payment_column span {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .zu_dashboard_crew_payment_column p {
    font-size: 35px;
  } */

  /* .zu_dashboard_crew_payment_column h2{
    font-size: 30px;
  }
  .zu_dashboard_crew_payment_column span{
    font-size: 18px;
  }
  .zu_dashboard_crew_payment_column p{
    font-size: 32px;
  } */

  /* .zu_common_heading {
    font-size: 30px;
  } */

  .zu_manage_job_filter_row {
    display: block;
  }

  .zu_manage_job_filter_row .zu_common_btn {
    margin-left: 0;
    margin-top: 20px;
    /* display: none; */
  }
  .zu_btn_show {
    display: block;
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_other_detail_column
    .zu_manage_job_other_detail_row {
    grid-gap: 20px;
    gap: 20px;
  }

  .zu_manage_job_other_detail_content::before {
    left: -10px;
  }

  /* .zu_manage_job_other_detail_content {
    padding: 24px 15px;
  } */

  .zu_manage_job_detail_project_label_text {
    max-width: 164px;
  }

  .zu_profile_user_detail_content
    .zu_profile_user_detail_text
    .zu_profile_user_detail_text_row {
    display: block;
  }

  .zu_profile_user_detail_content
    .zu_profile_user_detail_text
    .zu_profile_user_detail_text_row
    .zu_profile_user_detail_message_text {
    max-width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }

  /* .zu_notification_content {
    padding: 40px 45px 50px;
  } */

  /* .zu_notification_checkbox_list .zu_notification_checkbox label {
    padding-left: 50px;
    padding-right: 20px;
  } */

  .zu_notification_checkbox_list .zu_notification_checkbox label::before {
    left: 12px;
    width: 18px;
    height: 18px;
  }

  .zu_notification_checkbox_list .zu_notification_checkbox label::after {
    left: 14px;
    top: 46%;
    width: 6px;
    height: 11px;
  }

  /* .zu_payment_content_column .zu_payment_input_form_list .zu_common_form_input_content {
    margin-bottom: 30px;
  } */

  .zu_manage_new_job_post_content .zu_manage_new_job_post_row {
    grid-gap: 40px;
    gap: 40px;
  }

  .zu_profile_user_detail_content .zu_profile_user_bottom_btn_row {
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
  }
  .zu_common_filter_input_one {
    max-width: 25%;
  }

  .zu_common_filter_input_two {
    max-width: 25%;
  }

  .zu_common_filter_input_three {
    max-width: 50%;
  }
  .zu_common_filter_input_class_three {
    max-width: 35%;
  }

  .zu_profile_user_detail_content
    .zu_profile_user_bottom_btn_row
    .zu_common_btn {
    /* font-size: 20px;
    padding: 20px; */
  }

  /* .zu_admin_dashboard_crew_payment_column .zu_dashboard_job_content_column {
    padding: 30px;
  }

  .zu_admin_dashboard_crew_payment_column
    .zu_dashboard_job_content_column
    .zu_dashboard_job_content_column_text
    h2 {
    margin-bottom: 10px;
    font-size: 32px;
  }

  .zu_admin_dashboard_crew_payment_column
    .zu_dashboard_job_content_column
    .zu_dashboard_job_content_column_text
    p {
    font-size: 20px;
  } */

  /* .zu_admin_dashboard_crew_payment_column .zu_common_btn {
    font-size: 25px;
  } */

  .zu_admin_manage_user_profile_page
    .zu_profile_heading_row
    .zu_profile_heading_btn {
    margin-left: 0;
    margin-top: 20px;
    max-width: 600px;
  }

  .zu_admin_manage_user_profile_page .zu_profile_heading_row {
    display: block;
  }
}

@media (max-width: 1200px) {
  .zu_common_banner_content {
    margin: -30px -30px 0;
    width: calc(100% + 60px);
  }

  /* .zu_top_header_section {
    padding: 26px 50px;
  } */

  .zu_all_page_main_content {
    padding: 30px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text {
    display: flex;
    flex-direction: column;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row {
    display: block;
    order: 1;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_label_row {
    order: 2;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    h3,
  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_name_row
    span {
    margin-bottom: 10px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_price_more_detail {
    display: none;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_price_more_detail_btn {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    order: 5;
  }

  .zu_common_banner_content {
    height: 300px;
  }

  .zu_manage_job_detail_project_label_text {
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    flex-wrap: wrap;
  }
  .zu_manage_job_detail_project_label_text .zu_common_label {
    margin: 5px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /* .zu_dashboard_job_content_column {
    padding: 35px 30px;
    border-radius: 20px;
  } */

  /* .zu_dashboard_job_content_column svg {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  } */

  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    font-size: 30px;
  }

  .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text p {
    font-size: 19px;
  } */

  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2,
  .zu_dashboard_crew_payment_column p{
    font-size: 30px;
  } */
  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text p{
    font-size: 20px;
  } */

  /* .zu_dashboard_crew_payment_column h2 {
    font-size: 28px;
  }
  .zu_dashboard_crew_payment_column span {
    font-size: 16px;
  } */

  .zu_jobs_column_price_time_text {
    display: flex;
    order: 3;
    max-width: 365px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 15px -15px;
  }

  .zu_jobs_column_price_time_text p {
    text-align: left;
    width: 50%;
    padding: 0 15px;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 18px;
  }

  .zu_jobs_column_price_time_text p:nth-child(1),
  .zu_jobs_column_price_time_text p:nth-child(2) {
    margin-top: 0;
  }

  .zu_jobs_column_price_time_text p:nth-child(2n) {
    text-align: right;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_description_text {
    order: 4;
  }

  .zu_jobs_column
    .zu_find_jobs_column_right_content
    .zu_jobs_column_price_more_detail_btn {
    display: none;
  }

  .zu_jobs_column
    .zu_jobs_column_right_content
    .zu_jobs_column_right_payment_text {
    margin-top: 0;
  }

  .zu_upcomming_job_content .zu_common_filter_input_row {
    margin: 0 -15px 30px;
  }

  .zu_upcomming_job_content .zu_common_filter_input_row > div {
    margin: 0 15px;
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_other_detail_column
    .zu_manage_job_other_detail_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .zu_manage_job_other_detail_content::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 100%;
    background: #b4b7cc66;
  }

  .zu_manage_job_other_detail_content:nth-child(3):before,
  .zu_manage_job_other_detail_content:nth-child(1):after,
  .zu_manage_job_other_detail_content:nth-child(2):after {
    display: none;
  }

  /* .zu_manage_job_detail_column_content .zu_manage_job_detail_left_content,
  .zu_manage_job_applicant_list_column,
  .zu_manage_job_contact_column {
    padding: 30px 20px;
  } */

  .zu_manage_job_contact_column .zu_manage_job_contact_column_heading,
  .zu_manage_job_contact_column
    .zu_manage_job_contact_details
    .zu_manage_job_contact_details_heading,
  .zu_manage_job_applicant_list_column
    .zu_manage_job_applicant_list_heading
    h3 {
    font-size: 20px;
  }

  .zu_manage_job_contact_column
    .zu_manage_job_contact_details_row
    .zu_manage_job_contact_details_user_img {
    width: 45px;
    height: 45px;
  }

  .zu_manage_job_applicant_list_column
    .zu_manage_job_applicant_list_heading
    .zu_manage_job_applicant_list_number {
    font-size: 13px;
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_detail_left_content
    .zu_manage_job_detail_project_name_label_row {
    flex-direction: column-reverse;
  }

  .zu_manage_job_detail_project_label_text {
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .zu_manage_job_detail_project_label_text .zu_manage_job_detail_id_label {
    margin-bottom: 0;
  }

  .zu_manage_job_applicant_list_link .zu_manage_job_applicant_list_img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .zu_manage_job_applicant_list_link .zu_manage_job_applicant_list_name {
    font-size: 14px;
  }

  .zu_manage_job_contact_column
    .zu_manage_job_contact_details
    .zu_manage_job_contact_details_heading {
    font-size: 18px;
  }

  .zu_leads_data_column .zu_leads_data_column_right_text {
    display: block;
  }

  .zu_leads_data_column
    .zu_leads_data_column_right_text
    .zu_leads_data_column_event_info {
    padding: 0;
    margin-bottom: 30px;
  }

  .zu_leads_data_column
    .zu_leads_data_column_right_text
    .zu_leads_data_column_btn_content,
  .zu_leads_data_column_btn_content .zu_leads_data_column_edit_archiv_btn {
    max-width: 100%;
  }

  .zu_leads_data_column_btn_content .zu_leads_data_column_btn {
    margin: 0;
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content {
    max-width: 500px;
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content
    button {
    font-size: 16px;
    padding: 16px;
  }

  .zu_upload_image_dropbox .zu_upload_image_priview_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .zu_notification_checkbox_list .zu_notification_checkbox label {
    font-size: 18px;
  }

  .zu_notification_content_column .zu_notification_content_column_heading {
    font-size: 18px;
  }

  .zu_notification_content_column .zu_notification_content_column_paragraph {
    font-size: 16px;
  }

  .zu_notification_content .zu_notification_content_row {
    grid-gap: 30px;
    gap: 30px;
  }

  /* .zu_notification_checkbox_list .zu_notification_checkbox {
    margin-bottom: 20px;
  } */

  .zu_payment_content .zu_payment_content_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  /* .zu_admin_dashboard_crew_payment_column .zu_dashboard_job_content_column {
    padding: 30px 15px;
  } */

  /* .zu_admin_dashboard_crew_payment_column .zu_common_btn {
    font-size: 22px;
  } */

  /* .zu_admin_dashboard_crew_payment_column .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    font-size: 28px;
  }

  .zu_admin_dashboard_crew_payment_column
    .zu_dashboard_job_content_column
    .zu_dashboard_job_content_column_text
    p {
    font-size: 18px;
  } */

  .zu_admin_leads_management_page .zu_lead_content_heading_row {
    display: block;
  }

  .zu_admin_leads_management_page
    .zu_lead_content_heading_row
    .zu_common_filter_input_row {
    margin-left: 0;
    margin-top: 20px;
  }

  .zu_leads_data_column_btn_content .zu_leads_data_column_edit_archiv_btn {
    margin-bottom: 20px;
  }

  .zu_all_page_main_content .zu_privacy_terms_banner_img_text {
    margin-left: -30px;
    margin-right: -30px;
  }
  .zu_manage_job_other_detail_content {
    flex-direction: column;
  }
  .zu_manage_job_other_detail_content svg {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .zu_manage_job_other_detail_content
    .zu_manage_job_other_detail_content_text
    h3,
  .zu_manage_job_other_detail_content
    .zu_manage_job_other_detail_content_text
    p {
    text-align: center;
  }

  .zu_chat_container .zu_chat_content .zu_chat_list_content {
    max-width: 300px;
  }
}

@media (max-width: 1100px) {
  .zu_chat_msg_recive,
  .zu_chat_msg_send {
    max-width: calc(100% - 56px);
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_name_content
    .zu_chat_msg_header_name_text
    p
    span {
    max-width: 100px;
  }
}
@media (max-width: 1024px) {
  /* .zu_dashboard_crew_payment_column h2 {
    font-size: 28px;
  }

  .zu_dashboard_crew_payment_column p {
    font-size: 32px;
  } */

  /* .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    font-size: 28px;
  }

  .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text p {
    font-size: 18px;
  } */

  /* .zu_notification_content {
    padding: 30px 30px 50px;
  } */

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_left_label_content
    .zu_specialty_label_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_manage_new_job_post_column .zu_manage_new_job_post_left_input,
  .zu_manage_new_job_post_column .zu_manage_new_job_post_left_label_content,
  .zu_manage_new_job_post_column .zu_manage_new_job_post_discription_content,
  .zu_manage_new_job_post_column .zu_manage_new_job_post_hour_price_row {
    margin-bottom: 20px;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_hours_column {
    margin-top: 20px;
  }

  .zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text span {
    font-size: 14px;
  }

  .zu_specialty_label_content .zu_specialty_label_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .zu_common_form_input_content .zu_common_form_input_label {
    font-size: 20px;
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_name_content
    .zu_chat_msg_header_name_text
    p
    span {
    max-width: 60px;
  }
}

@media (max-width: 991px) {
  .zu_auth_page_mobile_logo,
  .zu_signup_form .zu_login_info_btn_content,
  .zu_signup_content .zu_signup_form_mobile_sub_heading,
  .zu_signup_content .zu_signup_form_mobile_heading {
    display: block;
  }

  .zu_lead_max {
    display: none;
  }

  .zu_lead_min {
    display: block;
  }

  .zu_login_section .zu_login_content .zu_login_image_content,
  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_logo,
  .zu_signup_content .zu_signup_form_logo,
  .zu_signup_content .zu_signup_form_sub_heading,
  .zu_signup_content .zu_signup_form_heading,
  .zu_find_job_section,
  .zu_invoice_section,
  .zu_leads_section,
  .zu_admin_manage_user_banner_section,
  .zu_signup_section::before {
    display: none;
  }

  .zu_login_section .zu_login_content .zu_login_form_content {
    min-width: auto;
    max-width: 100%;
    max-height: unset;
    min-height: unset;
    /* padding: 42px 34px 33px; */
  }

  /* .zu_sidebar_content .zu_sidebar_logo svg path[fill="#0B0911"] {
    fill: #ffffff;
  } */

  .zu_login_page_content {
    overflow: visible;
  }

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form {
    padding: 0 0 65px;
  } */

  /* .zu_login_form_content .zu_login_info_btn_content {
    padding: 0;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_sub_heading,
  .zu_signup_content .zu_signup_form_sub_heading,
  .zu_signup_content .zu_signup_form_mobile_sub_heading {
    margin-bottom: 15px;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_heading,
  .zu_signup_content .zu_signup_form_heading,
  .zu_signup_content .zu_signup_form_mobile_heading {
    margin-bottom: 69px;
  } */

  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_input_content
    .zu_floting_input_content {
    margin-top: 30px;
  }

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_input_content .zu_auth_checkbox {
    margin: 50px 0 30px;
  } */

  /* .zu_auth_form_submit_btn {
    padding: 22.5px 27px;
  } */

  /* .zu_login_form_input_content .zu_login_bottom_text {
    display: block;
    text-align: center;
  } */

  /* .zu_login_form_input_content
    .zu_login_bottom_text
    .zu_login_bottom_password_text {
    margin-right: 0;
    margin-bottom: 26px;
  } */

  .zu_signup_section {
    background-image: none;
  }

  .zu_signup_section {
    padding: 50px 30px;
  }

  .zu_signup_form_row {
    grid-gap: 30px;
    gap: 30px;
  }

  .zu_signup_form .zu_signup_form_row {
    margin-top: 30px;
  }

  .zu_signup_form .zu_signup_form_checkbox_categories {
    margin: 30px 0 26px;
  }

  .zu_signup_form_checkbox_categories_row {
    max-width: calc(100% + 30px);
    margin-bottom: 30px;
  }

  .zu_signup_form
    .zu_signup_form_checkbox_categories
    .zu_signup_form_checkbox_categories_text {
    color: #9e9e9e;
  }

  .zu_signup_form .zu_common_checkbox label::before {
    background: #f8eff9;
    top: 1px;
  }

  .zu_signup_form .zu_common_checkbox label::after {
    top: 4px;
  }

  .zu_signup_form .zu_common_checkbox label,
  .zu_signup_form .zu_signup_form_tnc label a {
    color: #757575;
    font-size: 16px;
  }

  .zu_signup_form .zu_auth_form_submit_btn {
    background: #5f0166;
    border-color: #5f0166;
  }

  .zu_signup_form .zu_auth_form_submit_btn:hover {
    background: transparent;
    border-color: #5f0166;
    color: #5f0166;
  }

  .zu_signup_form .zu_signup_already_member_text {
    color: #616161;
    margin-bottom: 30px;
  }

  .zu_signup_form .zu_signup_already_member_text a {
    color: #212121;
  }

  /* .zu_signup_content .zu_floting_input_content .zu_floting_input {
    background: #f8eff9;
  } */

  .zu_mobile_edit_btn {
    display: flex;
  }

  .zu_sidebar_bg_overlay {
    display: block;
  }

  .zu_sidebar_section {
    left: -100%;
    max-width: 330px;
    background: #ffffff;
    border: 0;
    box-shadow: 2px 0px 20px 1px #25265e1a;
    z-index: 999999;
  }

  .zu_open_sidebar .zu_sidebar_section {
    left: 0;
  }

  .zu_sidebar_section .zu_sidebar_content {
    /* flex-direction: column-reverse;
    display: flex; */
    /* padding-top: 75px; */
  }

  .zu_sidebar_content
    .zu_sidebar_nav
    .zu_sidebar_item
    .zu_sidebar_link
    .zu_sidebar_link_pandding_msg {
    top: 4px;
    left: -22px;
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    font-size: 18px;
    color: #4b4279;
    padding: 0;
    /* font-weight: 600; */
    font-family: "Proxima-Nova-Bold";
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link.active,
  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link:hover {
    background: transparent;
    color: #4b4279;
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item {
    padding-left: 50px;
    position: relative;
    padding-bottom: 15px;
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item::before {
    content: "";
    position: absolute;
    left: 25px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #4b4279;
  }

  .zu_top_header_toggal_close_btn {
    display: flex;
  }

  .zu_sidebar_content .zu_sidebar_nav {
    max-height: calc(100vh - 84px);
    min-height: calc(100vh - 84px);
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link span {
    margin-left: 0;
  }

  .zu_top_header_section,
  .zu_all_page_main_content {
    width: 100%;
  }

  .zu_top_header_section .zu_top_header_icon_menu,
  .zu_top_header_section .zu_top_header_user_dropdown_menu,
  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link svg,
  .zu_common_banner_content {
    display: none;
  }

  .zu_top_header_section .zu_top_header_logo,
  .zu_top_header_section .zu_top_header_toggal_btn {
    display: block;
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link {
    margin-top: 40px;
    margin-left: 50px;
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link a,
  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link button {
    padding: 0px;
    color: #000000;
    text-decoration: underline;
    font-family: "Proxima-Nova-Regular";
  }

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link a:hover,
  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link button:hover {
    background: transparent;
  }

  .zu_sidebar_content .zu_sidebar_logo a {
    height: auto;
  }

  .zu_sidebar_content .zu_sidebar_logo {
    padding: 20px 60px 20px 25px;
  }

  .zu_sidebar_content .zu_sidebar_logo a svg {
    height: auto;
    width: 150px;
  }

  .zu_dashboard_job_content_row {
    margin-top: 0;
  }

  .zu_upcomming_job_content .zu_common_heading {
    margin-left: 0;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_description_text,
  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text {
    max-width: 100%;
  }

  .zu_upcomming_job_content .zu_common_filter_input_row,
  .zu_manage_job_filter_row .zu_common_filter_input_row {
    display: none;
    margin-bottom: 30px;
  }

  .zu_common_filter_input_row.zu_common_filter_open {
    display: flex;
    flex-wrap: wrap;
  }

  .zu_common_filter_input_row > div {
    margin: 30px 0 0 !important;
    padding: 0 15px;
    max-width: 50%;
  }

  .zu_common_filter_input_row > div:first-child,
  .zu_common_filter_input_row > div:nth-child(2) {
    margin-top: 0px !important;
  }

  .zu_manage_job_filter_row .zu_common_filter_input_row {
    margin: 0 -15px;
    width: calc(100% + 30px);
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_detail_column_row
    .zu_manage_job_detail_left_column,
  .zu_manage_job_detail_column_content
    .zu_manage_job_detail_column_row
    .zu_manage_job_detail_right_column {
    max-width: 100%;
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_detail_column_row
    .zu_manage_job_detail_column:nth-child(2) {
    margin-top: 30px;
  }

  /* .zu_manage_job_detail_column_content .zu_manage_job_detail_left_content,
  .zu_manage_job_applicant_list_column,
  .zu_manage_job_contact_column {
    padding: 35px 42px;
  } */

  .zu_manage_job_detail_project_label_text {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .zu_manage_job_detail_project_label_text
    .zu_manage_job_detail_id_label:first-child,
  .zu_manage_job_banner_section {
    margin: 0;
  }

  .zu_manage_job_detail_project_label_text .zu_manage_job_detail_id_label,
  .zu_manage_job_detail_project_label_text .zu_common_label:last-child {
    margin-right: 10px;
  }

  .zu_leads_data_column {
    display: block;
  }

  .zu_leads_data_column .zu_leads_data_column_left_text {
    padding: 0;
    border: 0;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .zu_leads_data_column .zu_leads_data_column_lead_contact,
  .zu_leads_data_column_event_info
    .zu_leads_data_column_event_info_row
    .zu_leads_data_column_event_info_left_text {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 30px;
  }

  .zu_leads_data_column .zu_leads_data_column_lead_contact h4 {
    grid-column: span 3 / span 3;
    margin: 0;
  }

  .zu_leads_data_column .zu_leads_data_column_right_text {
    max-width: 100%;
    padding: 0;
  }

  .zu_profile_user_detail_content .zu_profile_user_detail_row {
    display: block;
  }

  .zu_profile_user_detail_content .zu_profile_user_detail_photo {
    margin-right: 0;
    margin-bottom: 35px;
  }

  .zu_profile_user_detail_content.zu_profile_user_detail_message
    .zu_profile_user_detail_about_text {
    margin-top: 30px;
  }

  /* .zu_upload_image_section {
    padding-bottom: 0;
  } */

  .zu_profile_edit_bottom_btns .zu_profile_edit_bottom_btn_row {
    flex-direction: column-reverse;
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content {
    margin: 0 auto 20px;
  }

  .zu_manage_new_job_post_content .zu_manage_new_job_post_row,
  .zu_admin_manage_user_table_content .zu_admin_manage_user_table_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_left_label_content
    .zu_specialty_label_row {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_price_column {
    max-width: 58%;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_hours_column {
    max-width: 42%;
    margin-top: 0;
  }

  .zu_profile_user_detail_content .zu_profile_user_bottom_btn_row {
    max-width: 237px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_profile_user_detail_content
    .zu_profile_user_bottom_btn_row
    .zu_common_btn {
    margin: 0;
  }

  .zu_admin_manage_user_table_column .zu_admin_manage_user_table_heading_row {
    display: flex;
  }

  .zu_admin_manage_user_table_heading_row
    .zu_admin_manage_user_table_heading
    ~ .zu_common_filter_input_box {
    margin-left: 15px;
    margin-top: 0;
    max-width: 372px;
  }

  .zu_admin_manage_user_table_heading_row .zu_admin_manage_user_table_heading {
    margin-right: auto;
  }

  .zu_admin_leads_management_page .zu_common_job_heading {
    margin-bottom: 30px;
  }

  .zu_admin_leads_management_page
    .zu_lead_content_heading_row
    .zu_common_filter_input_row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .zu_sidemenu_content {
    margin: 0 auto;
    /* padding: 72px 30px 50px; */
  }

  /* .zu_sidemenu_content .zu_sidemenu_bottom_btn {
    margin-top: 50px;
  } */

  /* .zu_sidemenu_content .zu_sidemenu_bottom_btn .zu_common_btn {
    margin-bottom: 30px;
    padding: 19px;
  } */

  .zu_send_offer_modal
    .zu_common_modal_send_offer_row
    .zu_common_modal_send_offer_column {
    max-width: 100%;
    margin-top: 30px;
  }

  .zu_send_offer_modal
    .zu_common_modal_send_offer_row
    .zu_common_modal_send_offer_column:first-child {
    margin-top: 0px;
  }

  /* .zu_send_offer_modal .zu_common_modal_content {
    padding: 30px 40px;
  } */

  .zu_send_offer_modal .zu_common_modal_heading {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .zu_send_offer_modal
    .zu_common_modal_heading_paragraph
    .zu_common_modal_paragraph {
    font-size: 18px;
  }

  .zu_common_modal_send_offer_column .zu_common_modal_send_offer_heading {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .zu_common_modal_send_offer_column
    .zu_common_modal_send_offer_step_list
    .zu_common_modal_send_offer_step_item {
    margin-bottom: 15px;
  }

  .zu_common_modal_send_offer_column
    .zu_common_modal_send_offer_step_list
    .zu_common_modal_send_offer_step_item
    .zu_common_modal_send_offer_step_text {
    font-size: 20px;
  }

  .zu_common_modal_how_work_content,
  .zu_common_modal_send_offer_column .zu_common_modal_card_info_content {
    margin-bottom: 30px;
  }

  .zu_notification_content_column .zu_notification_content_column_heading {
    font-size: 20px;
  }

  .zu_common_modal_send_offer_column .zu_common_modal_the_offer_content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    grid-gap: 20px;
    gap: 20px;
  }
  .zu_common_modal_send_offer_column
    .zu_common_modal_the_offer_content
    .zu_common_modal_the_offer_note {
    grid-column: span 3 / span 3;
  }

  .zu_common_select_location_icon svg {
    left: 28px;
  }
  .zu_admin_manage_user_table_column .zu_admin_manage_user_table_heading_row {
    display: block;
  }
  .zu_admin_manage_user_table_heading_row
    .zu_admin_manage_user_table_heading
    ~ .zu_common_filter_input_box {
    margin-left: 0;
    margin-top: 20px;
  }

  .zu_chat_msg_recive,
  .zu_chat_msg_send {
    max-width: calc(100% - 100px);
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_name_content
    .zu_chat_msg_header_name_text
    p
    span {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .zu_signup_form_row,
  .zu_dashboard_job_content_row,
  .zu_profile_edit_input_content .zu_profile_edit_input_row,
  .zu_notification_content .zu_notification_content_row,
  .zu_admin_manage_user_profile_page
    .zu_profile_heading_row
    .zu_profile_heading_btn {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_leads_data_column .zu_leads_data_column_lead_contact,
  .zu_leads_data_column_event_info
    .zu_leads_data_column_event_info_row
    .zu_leads_data_column_event_info_left_text {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .zu_signup_form_checkbox_categories_row .zu_common_checkbox {
    max-width: 50%;
  }
  .zu_signup_form_checkbox_categories_row .zu_common_checkbox:nth-child(3) {
    margin-top: 20px;
  }

  /* .zu_signup_content .zu_signup_form_heading,
  .zu_signup_content .zu_signup_form_mobile_heading {
    margin-bottom: 48px;
  } */

  .zu_signup_form
    .zu_signup_form_checkbox_categories
    .zu_signup_form_checkbox_categories_text {
    font-size: 16px;
  }

  .zu_dashboard_job_content_row {
    grid-gap: 30px;
    gap: 30px;
  }

  .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    line-height: 1.2;
  }

  /* .zu_dashboard_crew_payment_column h2 {
    font-size: 30px;
  } */

  /* .zu_dashboard_crew_payment_column p {
    font-size: 34px;
  } */

  .zu_dashboard_job_section,
  .zu_manage_job_filter_row {
    margin-bottom: 30px;
  }

  .zu_upcomming_job_content .zu_common_heading {
    margin-bottom: 25px;
  }

  .zu_manage_job_btn_content .zu_manage_job_btn_row {
    flex-direction: column-reverse;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row {
    max-width: 100%;
    margin-bottom: 30px;
    justify-content: center;
  }

  .zu_leads_data_column .zu_leads_data_column_lead_contact h4 {
    grid-column: span 2 / span 2;
  }

  .zu_profile_user_detail_content .zu_profile_user_detail_photo,
  .zu_profile_user_detail_content
    .zu_profile_user_detail_text_content
    .zu_common_btn {
    margin-left: auto;
    margin-right: auto;
  }

  .zu_profile_user_detail_content
    .zu_profile_user_detail_text_content
    .zu_profile_user_detail_heading,
  .zu_profile_user_detail_content
    .zu_profile_user_detail_text_content
    .zu_profile_user_detail_rating_text {
    display: block;
    text-align: center;
  }
  .zu_profile_user_detail_content
    .zu_profile_user_detail_text_content
    .zu_profile_user_detail_sub_heading {
    margin-left: auto;
    margin-right: auto;
  }

  .zu_profile_user_detail_content
    .zu_profile_user_detail_text_content
    .zu_profile_user_detail_heading {
    font-size: 26px;
  }

  .zu_profile_user_detail_content
    .zu_profile_user_detail_text_content
    .zu_profile_user_detail_rating_row {
    justify-content: center;
  }

  .zu_profile_user_detail_section {
    margin-bottom: 40px;
  }

  .zu_showcase_section,
  .zu_showcase_links_section {
    /* padding-bottom: 20px; */
    margin-bottom: 30px;
  }

  .zu_showcase_links_content .zu_profile_common_heading {
    margin-bottom: 20px;
  }

  .zu_showcase_links_content .zu_showcase_links_nav li,
  .zu_profile_rating_review_content .zu_profile_common_heading {
    margin-bottom: 20px;
  }

  .zu_showcase_links_content .zu_showcase_links_nav li a {
    font-size: 16px;
  }
  .zu_profile_common_heading h2 {
    font-size: 18px;
  }

  .zu_profile_common_heading .zu_profile_common_heading_view_all_btn {
    font-size: 14px;
  }

  .zu_profile_rating_review_section {
    padding-top: 20px;
  }

  .zu_profile_page .zu_profile_job_back_btn {
    margin-top: 50px;
  }

  .zu_showcase_content .react-photo-gallery--gallery > div {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .zu_profile_user_detail_content .zu_profile_user_detail_photo {
    margin-bottom: 20px;
  }

  .zu_profile_edit_input_content
    .zu_profile_edit_input_row
    .zu_common_form_textarea_content {
    grid-column: span 1 / span 1;
  }

  .zu_common_form_input_content select.zu_common_form_input {
    background-position: right 25px center;
    padding-right: 55px;
  }

  .zu_upload_image_dropbox .zu_upload_image_priview_row,
  .zu_common_modal_send_offer_column .zu_common_modal_the_offer_content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content {
    flex-direction: column;
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content
    button {
    margin: 0;
    width: 100%;
  }

  .zu_profile_edit_bottom_btns
    .zu_profile_edit_bottom_btn_row
    .zu_profile_edit_bottom_btn_right_content
    button:first-child {
    margin-bottom: 20px;
    margin-right: 0;
  }

  /* .zu_manage_new_job_post_content {
    padding: 30px;
    border-radius: 15px;
  } */

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_price_column,
  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_hours_column,
  .zu_admin_manage_user_profile_page
    .zu_profile_heading_row
    .zu_profile_heading_btn {
    max-width: 100%;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_hour_price_row
    .zu_manage_new_job_post_hours_column {
    margin-top: 20px;
  }

  .zu_manage_new_job_btn_content .zu_common_btn {
    background: #5f0166;
    margin: 0 auto;
  }
  .zu_manage_new_job_btn_content .zu_common_btn::before {
    background: #39003d;
  }

  .zu_manage_new_job_btn_content .zu_manage_new_job_back_btn {
    margin: 20px auto 0;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_left_label_content
    .zu_specialty_label_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_discription_content
    h2 {
    font-size: 26px;
  }

  .zu_profile_user_detail_content .zu_profile_user_bottom_btn_row {
    margin-left: auto;
    margin-right: auto;
  }

  .zu_admin_manage_user_table_column .zu_admin_manage_user_table_heading_row {
    display: block;
  }

  .zu_admin_manage_user_table_heading_row
    .zu_admin_manage_user_table_heading
    ~ .zu_common_filter_input_box {
    max-width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }

  .zu_common_center_modal .zu_common_modal_content {
    max-width: 100%;
    /* padding: 40px 30px;
    border-radius: 20px; */
  }

  .zu_send_offer_modal .zu_modal_common_btn_row .zu_common_btn {
    padding: 19px;
  }

  .zu_common_modal_send_offer_column
    .zu_common_modal_the_offer_content
    .zu_common_modal_the_offer_note {
    grid-column: span 2 / span 2;
  }

  .zu_profile_user_detail_content .zu_profile_user_detail_text_content {
    text-align: center;
  }

  .pagination {
    justify-content: center;
  }
  .zu_chat_container .zu_chat_content {
    position: relative;
    overflow: hidden;
  }
  .zu_chat_container .zu_chat_content .zu_chat_list_content {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    height: 100%;
    z-index: 991;
    border-right: 0;
    transition: all 0.3s ease-in-out;
  }
  .zu_chat_container .zu_chat_content.zu_open_chat .zu_chat_list_content {
    left: -100%;
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_content_back_btn {
    display: flex;
  }
  .zu_chat_container .zu_chat_content .zu_chat_msg_content {
    width: 100%;
  }
  .zu_chat_container .zu_chat_content .zu_msg_not_open_content {
    height: calc(100vh - 167px);
    position: relative;
  }
}

@media (max-width: 600px) {
  .zu_login_section .zu_login_content .zu_login_form_content {
    padding: 30px 0;
  }
  .zu_signup_section {
    padding: 30px;
  }

  .zu_signup_form .zu_common_checkbox label,
  .zu_signup_form .zu_signup_form_tnc label a {
    font-size: 14px;
  }

  .zu_signup_form .zu_common_checkbox label {
    padding-left: 10px;
  }

  .zu_signup_form .zu_signup_already_member_text {
    font-size: 12.8px;
  }

  .zu_signup_form
    .zu_signup_form_checkbox_categories
    .zu_signup_form_checkbox_categories_text {
    font-size: 14px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_label_row
    .zu_common_label {
    min-width: auto;
    max-width: 50%;
    width: 100%;
  }

  .zu_jobs_column_price_time_text {
    max-width: calc(100% + 30px);
    width: calc(100% + 30px);
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_description_text {
    font-size: 18px;
  }

  .zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_text_row {
    display: block;
    margin-bottom: 13px;
  }

  .zu_jobs_column
    .zu_jobs_column_right_content
    .zu_jobs_column_right_payment_text {
    margin-bottom: 5px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_price_more_detail_btn {
    font-size: 18px;
    justify-content: center;
    width: 100%;
  }

  .zu_jobs_column .zu_jobs_column_left_content {
    display: block;
  }

  .zu_jobs_column .zu_jobs_column_left_content .zu_jobs_column_left_user_img {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .zu_common_heading {
    font-size: 24px;
  }

  .zu_common_filter_input_row > div {
    max-width: 100%;
  }

  .zu_common_filter_input_row > div:nth-child(2) {
    margin-top: 30px !important;
  }

  .zu_manage_job_filter_row .zu_common_btn {
    max-width: 100%;
  }

  .zu_jobs_column .zu_jobs_column_bookmark_btn {
    top: 35px;
  }

  .zu_manage_job_detail_heading_contant {
    margin-bottom: 30px;
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_other_detail_column
    .zu_manage_job_other_detail_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 0;
    gap: 0;
  }

  .zu_manage_job_other_detail_content::after {
    top: 0;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row
    .zu_common_btn {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row
    .zu_common_btn:last-child {
    margin-bottom: 0px;
  }

  .zu_manage_job_other_detail_content::before {
    display: none;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row,
  .zu_admin_leads_management_page
    .zu_lead_content_heading_row
    .zu_common_filter_input_row,
  .zu_login_form_input_content .zu_login_bottom_text {
    display: block;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row
    .zu_manage_job_edit_cancel_btn {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .zu_login_form_input_content
    .zu_login_bottom_text
    .zu_login_bottom_password_text {
    margin: 0 auto 20px;
  }

  .zu_manage_job_btn_content
    .zu_manage_job_btn_row
    .zu_manage_job_edit_cancel_btn_row
    .zu_manage_job_edit_cancel_btn:last-child {
    margin-bottom: 0;
  }

  .zu_manage_job_detail_project_label_text {
    flex-direction: column-reverse;
  }

  .zu_manage_job_detail_project_label_text .zu_common_label {
    width: 100%;
  }

  .zu_manage_job_detail_project_label_text .zu_manage_job_detail_id_label,
  .zu_manage_job_detail_project_label_text .zu_common_label:last-child {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .zu_manage_job_detail_heading_contant {
    padding: 0;
  }

  .zu_manage_job_detail_project_name_text h3 {
    margin-bottom: 14px;
  }

  .zu_manage_job_detail_column_content
    .zu_manage_job_other_detail_column
    .zu_manage_job_other_detail_row
    .zu_manage_job_date_column {
    display: none;
  }

  .zu_manage_job_detail_project_name_text .zu_manage_job_date {
    display: block;
  }

  .zu_showcase_content .react-photo-gallery--gallery > div,
  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_left_label_content
    .zu_specialty_label_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .zu_profile_rating_review_content .zu_profile_rating_review_row,
  .zu_common_modal_send_offer_column .zu_common_modal_the_offer_content,
  .zu_credit_card_modal .zu_modal_common_btn_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_payment_content_column {
    padding: 40px 30px;
  }

  /* .zu_payment_content_column .zu_payment_save_btn .zu_common_btn {
    width: 100%;
    padding: 16px 18px;
  } */

  .zu_payment_content_column .zu_payment_content_column_paragraph {
    font-size: 18px;
  }

  .zu_payment_content_column
    .zu_payment_input_form_list
    .zu_common_form_input_content {
    margin-bottom: 20px;
  }

  .zu_common_form_input_content .zu_common_form_input_label {
    margin-bottom: 10px;
  }

  /* .zu_payment_content_column .zu_payment_save_btn {
    margin-top: 30px;
  } */

  .zu_admin_dashboard_crew_payment_column .zu_common_btn {
    padding: 19px;
  }

  .zu_admin_leads_management_page
    .zu_lead_content_heading_row
    .zu_common_filter_input_row {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .zu_admin_leads_management_page
    .zu_lead_content_heading_row
    .zu_common_filter_input_row
    > div {
    padding: 0;
  }

  .zu_common_modal .zu_common_modal_heading,
  .zu_common_modal .zu_common_modal_second_heading {
    font-size: 26px;
  }

  .zu_common_modal .zu_common_modal_rating_star_row {
    margin-top: 15px;
  }

  .zu_common_modal .zu_common_modal_heading_paragraph {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .zu_common_modal .zu_modal_common_btn_row {
    flex-direction: column-reverse;
  }

  .zu_common_modal .zu_common_link_btn {
    margin: 15px auto 0;
  }

  .zu_common_modal .zu_common_modal_other_detail_row,
  .zu_common_modal .zu_common_modal_other_detail_row_finalize,
  .zu_sidemenu_content .zu_sidemenu_other_detail_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_common_modal
    .zu_common_modal_other_detail_row
    .zu_common_modal_other_detail_column::before,
  .zu_sidemenu_content
    .zu_sidemenu_other_detail_row
    .zu_sidemenu_other_detail_column::before {
    width: 100%;
    height: 1px;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
  }

  .zu_sidemenu_content
    .zu_sidemenu_other_detail_row
    .zu_sidemenu_other_detail_column:nth-child(3):before {
    display: block;
  }

  /* .zu_sidemenu_content .zu_sidemenu_bottom_btn .zu_common_btn {
    font-size: 16px;
    margin-bottom: 15px;
  } */

  .zu_sidemenu_content .zu_sidemenu_bottom_btn,
  .zu_sidemenu_content .zu_sidemenu_other_detail_row {
    margin-top: 30px;
  }

  .zu_sidemenu_content .zu_sidemenu_label_other_name_row {
    margin-bottom: 30px;
  }

  .zu_sidemenu_content .zu_sidemenu_heading {
    font-size: 24px;
  }

  .zu_sidemenu_content .zu_sidemenu_paragraph {
    font-size: 18px;
  }

  /* .zu_job_offer_modal .zu_common_modal_heading {
    font-size: 30px;
    margin-bottom: 30px;
  } */

  /* .zu_job_offer_modal .zu_common_modal_second_heading {
    font-size: 25px;
  } */

  .zu_send_offer_modal .zu_common_modal_heading {
    font-size: 24px;
  }

  .zu_common_modal_send_offer_column .zu_common_modal_send_offer_heading {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .zu_send_offer_modal .zu_common_modal .zu_common_modal_checkbox label {
    padding-left: 15px;
  }

  .zu_send_offer_modal .zu_modal_common_btn_row .zu_common_btn,
  .zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn,
  .zu_purchase_lead_msg_modal .zu_modal_common_btn_row .zu_common_btn,
  .zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .zu_send_offer_modal .zu_modal_common_btn_row .zu_common_btn:first-child,
  .zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn:first-child,
  .zu_purchase_lead_msg_modal
    .zu_modal_common_btn_row
    .zu_common_btn:first-child,
  .zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn:first-child {
    margin-bottom: 0px;
  }

  .zu_send_msg_modal .zu_common_modal_heading,
  .zu_purchase_lead_msg_modal
    .zu_common_modal_heading_paragraph
    .zu_common_modal_heading {
    font-size: 28px;
  }

  .zu_purchase_lead_msg_modal
    .zu_common_modal_heading_paragraph
    .zu_common_modal_heading {
    margin-bottom: 15px;
  }

  .zu_purchase_lead_msg_modal
    .zu_common_modal_heading_paragraph
    .zu_common_modal_paragraph {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .zu_purchase_lead_msg_modal .zu_common_modal_heading_paragraph {
    padding-bottom: 0;
    margin-bottom: 25px;
  }

  .zu_purchase_lead_msg_modal .zu_modal_common_btn_row {
    margin-top: 30px;
  }

  .zu_common_text_modal .zu_common_modal_paragraph {
    font-size: 18px;
  }

  .zu_specialty_label_content .zu_specialty_label_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .zu_apply_modal .zu_common_modal_content .zu_common_modal_paragraph {
    font-size: 18px;
  }

  .zu_common_modal_send_offer_column
    .zu_common_modal_the_offer_content
    .zu_common_modal_the_offer_note {
    grid-column: span 1 / span 1;
  }

  .zu_privacy_terms_common_heading {
    font-size: 17px;
  }
  .zu_privacy_terms_covid_content .zu_privacy_terms_covid_heading {
    font-size: 25px;
  }
  .zu_privacy_terms_condition_pdf_content .zu_privacy_terms_common_heading,
  .zu_privacy_second_terms_condition_pdf_content
    .zu_privacy_terms_common_heading {
    font-size: 22px;
  }

  .zu_privacy_terms_covid_content,
  .zu_privacy_terms_condition_pdf_content,
  .zu_privacy_second_terms_condition_pdf_content {
    padding: 30px 20px;
  }
  .zu_privacy_terms_btn {
    font-size: 16px;
    padding: 7px 16px;
  }
  .zu_privacy_terms_banner_img_text {
    height: auto;
    margin-bottom: 30px;
  }
  .zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_heading {
    font-size: 30px;
  }
  .zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_paragraph {
    font-size: 20px;
  }

  .err_text {
    font-size: 12px;
  }
  .zu_credit_card_modal .zu_modal_common_btn_row {
    grid-gap: 20px;
    gap: 20px;
  }
  .zu_chat_msg_recive,
  .zu_chat_msg_send {
    max-width: calc(100% - 50px);
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_view_job {
    padding: 6px 12px;
    font-size: 13px;
  }
  .hidden_icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 500px) {
  .zu_common_form_phone {
    flex-direction: column;
    align-items: start;
  }

  .send_otp_btn {
    margin: 7px 0 0 0;
  }

  .md-err-text {
    display: none;
  }

  .sm-err-text {
    display: block;
  }

  .zu_credit_card_container {
    flex-wrap: wrap;
  }
  .zu_send_offer_add_card_btn {
    margin-top: 2px;
  }

  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_sub_heading {
    font-size: 14px;
  }
  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_heading {
    font-size: 25px;
  }

  .zu_login_form_input_content
    .zu_login_bottom_text
    .zu_login_bottom_password_text {
    margin: 0px auto 20px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_label_row {
    flex-direction: column-reverse;
    margin: 0;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_label_row
    .zu_common_label {
    max-width: 100%;
    margin: 0 0 8px;
  }

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_label_row
    .zu_common_label:first-child {
    margin: 0;
  }

  .zu_jobs_column_price_time_text p {
    font-size: 16px;
  }

  .zu_manage_job_detail_column_content .zu_manage_job_detail_left_content,
  .zu_manage_job_applicant_list_column,
  .zu_manage_job_contact_column {
    padding: 30px;
  }

  .zu_leads_data_column .zu_leads_data_column_lead_contact,
  .zu_leads_data_column_event_info
    .zu_leads_data_column_event_info_row
    .zu_leads_data_column_event_info_left_text {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 27px;
    gap: 27px;
  }

  .zu_leads_data_column .zu_leads_data_column_lead_contact h4 {
    grid-column: span 1 / span 1;
  }

  .zu_upload_image_dropbox .zu_upload_image_priview_row,
  .zu_manage_new_job_post_column
    .zu_manage_new_job_post_left_label_content
    .zu_specialty_label_row,
  .zu_sidemenu_content .zu_sidemenu_bottom_btn {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .zu_sidemenu_content .zu_sidemenu_bottom_btn {
    grid-gap: 20px;
    gap: 20px;
  }

  /* .zu_specialty_label_row .zu_specialty_label_column {
    max-width: 50%;
    width: 100%;
  } */

  /* .zu_specialty_label_row .zu_specialty_label_column label {
    width: 100%;
    text-align: center;
    padding: 20px;
  } */

  /* .zu_manage_new_job_post_column .zu_manage_new_job_post_left_label_content .zu_specialty_label_row .zu_specialty_label_column {
    max-width: 100%;
  } */

  .zu_manage_new_job_post_column
    .zu_manage_new_job_total_payment_text
    .zu_manage_new_job_total_payment_card_icon {
    width: 44px;
    height: 44px;
    border-radius: 10px;
  }

  .zu_manage_new_job_post_column
    .zu_manage_new_job_total_payment_text
    .zu_manage_new_job_total_payment_card_icon
    svg {
    width: 26px;
    height: 21px;
  }

  .zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text span,
  .zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text p {
    width: calc(100% - 50px);
  }

  .zu_manage_new_job_post_column .zu_manage_new_job_total_payment_text p {
    font-size: 28px;
  }

  .zu_common_custom_date_picker {
    min-width: 320px;
    max-width: 320px;
    padding: 15px;
  }

  .zu_common_custom_date_picker
    .zu_common_custom_date_picker_content
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day {
    height: 34px;
  }

  .zu_common_custom_date_picker
    .zu_common_custom_date_picker_content
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day::before {
    display: none;
  }

  .zu_admin_manage_user_table_heading_row .zu_admin_manage_user_table_heading {
    font-size: 18px;
  }

  .zu_confirm_box {
    padding: 30px;
  }

  .zu_confirm_box_content .zu_confirm_box_heading {
    font-size: 18px;
  }

  .zu_confirm_box_content .zu_confirm_box_paragraph {
    font-size: 14px;
  }

  /* .zu_common_modal .zu_common_modal_heading, */
  /* .zu_common_modal .zu_common_modal_second_heading {
    font-size: 28px;
  } */

  .zu_common_modal
    .zu_common_modal_heading_paragraph
    .zu_common_modal_paragraph {
    font-size: 14px;
  }

  .zu_common_modal .zu_modal_common_btn_row {
    margin-top: 25px;
  }

  .zu_common_modal .zu_modal_common_btn_row .zu_modal_common_submit_btn {
    max-width: 100%;
  }

  .zu_sidemenu_content .zu_sidemenu_label_other_name_row {
    display: block;
  }

  .zu_sidemenu_content
    .zu_sidemenu_label_other_name_row
    .zu_sidemenu_other_name_text {
    margin-top: 15px;
  }

  .zu_sidemenu_content {
    padding-top: 80px;
  }

  /* .zu_sidemenu_content .zu_sidemenu_user_img {
    width: 120px;
    height: 120px;
    top: -58px;
  } */

  .zu_sidemenu_section {
    padding-top: 72px;
  }

  .zu_job_offer_modal .zu_common_modal_heading {
    font-size: 20px;
  }

  .zu_job_offer_modal .zu_common_modal_second_heading {
    font-size: 18px;
  }

  .zu_job_offer_modal .zu_common_modal_other_detail_row {
    margin-bottom: 30px;
  }

  .zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn,
  .zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn,
  .zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn,
  .zu_purchase_lead_msg_modal .zu_modal_common_btn_row .zu_common_btn {
    padding: 19px;
  }

  .zu_job_offer_modal
    .zu_modal_common_btn_row
    .zu_common_btn.zu_modal_common_accetp_decline_btn {
    max-width: 100%;
  }

  .zu_common_modal_send_offer_column
    .zu_common_modal_send_offer_step_list
    .zu_common_modal_send_offer_step_item
    .zu_common_modal_send_offer_step_text,
  .zu_common_modal_send_offer_column
    .zu_common_modal_the_offer_content
    .zu_common_modal_the_offer_text
    p {
    font-size: 16px;
  }

  .zu_common_modal_send_offer_column .zu_common_modal_the_offer_content {
    padding: 20px;
  }

  .zu_common_modal_send_offer_column
    .zu_common_modal_the_offer_content
    .zu_common_modal_the_offer_text {
    margin-bottom: 15px;
  }

  .zu_common_modal_send_offer_column .zu_common_modal_send_offer_heading {
    font-size: 20px;
  }

  .zu_send_offer_modal .zu_common_modal_heading {
    font-size: 24px;
  }

  .zu_apply_modal .zu_common_modal_content .zu_common_modal_paragraph {
    font-size: 16px;
  }

  .zu_job_offer_modal .zu_modal_common_btn_row {
    display: flex;
    grid-gap: 0;
    gap: 0;
  }
  .zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn {
    margin-top: 20px;
  }
  .zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
    margin-top: 0px;
  }

  .zu_common_tab_menu .nav-tabs {
    width: 100%;
    justify-content: center;
  }

  .zu_applicant_detail_content .zu_applicant_detail_bottom_btn {
    margin: -10px 0;
    flex-wrap: wrap;
  }
  .zu_applicant_detail_content .zu_applicant_detail_bottom_btn .zu_common_btn {
    max-width: 100%;
    margin: 10px 0;
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_name_content
    .zu_chat_msg_header_name_text
    p
    span {
    max-width: 67px;
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_name_content
    .zu_chat_msg_header_img {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .zu_chat_container
    .zu_chat_content
    .zu_chat_msg_content
    .zu_chat_msg_header
    .zu_chat_msg_header_name_content::before {
    left: 30px;
    bottom: 9px;
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 427px) {
  .zu_notifications_list_view_msg {
    overflow: auto;
  }
  /* .zu_login_section .zu_login_content .zu_login_form_content {
    padding: 40px 30px 30px;
  } */

  /* .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form .zu_login_form_heading,
  .zu_signup_content .zu_signup_form_heading,
  .zu_signup_content .zu_signup_form_mobile_heading {
    margin-bottom: 40px;
    font-size: 26px;
  } */

  /* .zu_floting_input_content .zu_floting_input {
    font-size: 18px;
    padding: 30px 20px 20px;
  }

  .zu_floting_input_content .zu_floting_input:focus,
  .zu_floting_input_content .zu_floting_input:not(:placeholder-shown) {
    padding: 30px 20px 20px;
  }

  .zu_floting_input_content label {
    padding: 21.5px 20px;
    font-size: 18px;
    top: 0;
  }

  .zu_floting_input_content .zu_floting_input:focus~label,
  .zu_floting_input_content .zu_floting_input:not(:placeholder-shown)~label {
    font-size: 12px;
    padding: 8px 20px;
  } */

  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_input_content
    .zu_auth_checkbox {
    margin: 30px 0;
  }

  .zu_auth_form_submit_btn {
    padding: 15px 27px;
    font-size: 18px;
  }

  .zu_login_form_input_content .zu_login_bottom_text {
    margin-top: 30px;
  }

  .zu_login_form_input_content
    .zu_login_bottom_text
    .zu_login_bottom_password_text {
    margin-bottom: 15px;
  }

  .zu_login_section .zu_login_content .zu_login_form_content .zu_login_form {
    padding-bottom: 30px;
  }

  .zu_specialty_label_content .zu_specialty_label_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 400px) {
  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_sub_heading,
  .zu_signup_content .zu_signup_form_sub_heading,
  .zu_signup_content .zu_signup_form_mobile_sub_heading {
    font-size: 14px;
  }
  .zu_login_section
    .zu_login_content
    .zu_login_form_content
    .zu_login_form
    .zu_login_form_heading,
  .zu_signup_content .zu_signup_form_mobile_heading {
    font-size: 22px;
  }
  .zu_signup_content .zu_signup_form_heading,
  .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    font-size: 23px;
  }

  .zu_auth_page_mobile_logo {
    padding: 30px;
  }

  .zu_signup_form_checkbox_categories_row .zu_common_checkbox {
    max-width: 100%;
  }
  .zu_signup_form_checkbox_categories_row .zu_common_checkbox:nth-child(2) {
    margin-top: 20px;
  }

  .zu_sidebar_section {
    max-width: 290px;
  }

  /* .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item {
    padding-left: 50px;
    padding-bottom: 20px;
  } */

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item::before {
    left: 30px;
  }

  /* .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link {
    font-size: 26px;
  } */

  .zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link {
    margin-left: 50px;
  }

  /* .zu_top_header_toggal_close_btn,
  .zu_top_header_toggal_close_btn svg {
    width: 25px;
    height: 25px;
  } */

  /* .zu_top_header_section {
    padding: 26px 30px;
  } */

  .zu_dashboard_job_content_column {
    padding: 35px 25px;
  }

  /* .zu_dashboard_job_content_column svg {
    width: 30px;
    height: 30px;
  } */

  .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text p {
    font-size: 16px;
  }

  .zu_dashboard_crew_payment_column {
    padding: 30px 25px;
  }

  /* .zu_dashboard_crew_payment_column h2 {
    font-size: 24px;
  } */

  .zu_jobs_column
    .zu_jobs_column_left_content
    .zu_jobs_column_name_description_text
    .zu_jobs_column_description_text {
    font-size: 14px;
  }

  .zu_common_heading {
    font-size: 22px;
  }
  .zu_common_job_heading .zl_common_job_show_filter {
    margin-left: 20px;
  }

  .zu_showcase_content .react-photo-gallery--gallery > div {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  /* .zu_specialty_label_row .zu_specialty_label_column {
    max-width: 100%;
  } */

  .zu_payment_content_column {
    padding: 30px 15px;
  }

  .zu_common_custom_date_picker {
    min-width: 280px;
    max-width: 280px;
  }

  .zu_common_custom_date_picker .zu_common_custom_date_picker_heading {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .zu_common_custom_date_picker
    .zu_common_custom_date_picker_content
    .react-datepicker__month
    .react-datepicker__week {
    grid-gap: 4.66px;
    gap: 4.66px;
    margin-bottom: 4.66px;
  }

  .zu_common_custom_date_picker
    .zu_common_custom_date_picker_content
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day {
    height: 31.72px;
  }

  .zu_admin_dashboard_crew_payment_column {
    padding: 30px 15px;
  }

  /* .zu_admin_dashboard_crew_payment_column .zu_dashboard_job_content_column .zu_dashboard_job_content_column_text h2 {
    font-size: 26px;
  } */

  /* .zu_admin_dashboard_crew_payment_column .zu_common_btn {
    font-size: 18px;
  } */

  .zu_common_modal .zu_common_modal_other_detail_row,
  .zu_sidemenu_content .zu_sidemenu_other_detail_row {
    padding: 15px;
  }

  .zu_common_modal
    .zu_common_modal_other_detail_row
    .zu_common_modal_other_detail_column
    svg,
  .zu_sidemenu_content
    .zu_sidemenu_other_detail_row
    .zu_sidemenu_other_detail_column
    svg {
    width: 26px;
    height: 26px;
  }

  .zu_common_modal .zu_common_modal_checkbox label {
    padding-left: 20px;
    font-size: 14px;
  }

  .zu_send_msg_modal .zu_common_modal_heading span {
    display: inline;
  }

  .zu_send_msg_modal .zu_common_modal_heading {
    font-size: 21px;
  }
}

.zu_applicant_detail_view_more {
  background: transparent;
  border: transparent;
  color: #5f0166;
}

/* media query end */

.finalize_payment_btn {
  width: 176px;
  cursor: pointer;
}

/* Sliders Loader for jobs */
.dots-3 {
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #5f0166 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: d3 1s infinite linear;
}

.back-color {
  --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
}
@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.zu_common_modal_finalize_details {
  color: #5f0166;
  font-size: 17px;
  font-family: "Proxima-Nova-Regular";
  margin: 0 5px 10px 0;
}

.zu_common_modal_finalize_details p {
  margin: 0;
}

.zu_common_modal_finalize_details_text {
  color: #000000;
  font-size: 18px;
}

.zu_leads_myleads_all_leads {
  display: flex;
  margin-bottom: 0.5rem;
  font-family: "Proxima-Nova-Bold";
}

.zu_warning_message {
  border-left-color: #ffe564;
  border-left-width: 9px;
  border-left-style: solid;
}

.zu_alert_for_extra_hour {
  padding: 6px !important;
}

.zu_approve_btn {
  max-width: 178px;
}

.zu_user_filter {
  margin-right: 20px;
}

/* .invoice_row_two_column {
  width: 100% !important;
} */

.message-container::-webkit-scrollbar {
  display: none;
}

