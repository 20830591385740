.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  /* width: 25%; */
  margin: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.username-cont {
  margin-top: 1rem;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.35rem;
  width: 100% !important;
}

.input {
  padding: 0.35rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: none;
  border: 1px solid #ccc;
  margin: 0;
}

.input:focus {
  outline: var(--color-light-grey);
  /* background-color: #ccc; */
}

.login-btn {
  margin-top: 0.75rem;
  padding: 0.35rem 0;
  border-radius: 0.25rem;
  border: none;
  background-color: var(--color-blue);
  color: var(--color-white);
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
}

.login-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.error {
  color: var(--color-dark-red);
  background-color: var(--color-light-red);
  padding: 0.35rem 0.75rem;
  margin: 0.5rem 0 1rem;
  border-radius: 0.35rem;
}

.link {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-black);
  font-weight: 600;
  padding: 0.5rem 0.75rem;
}

.link:hover {
  opacity: 0.8;
}

.dashboardpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardpage > * {
  margin-bottom: 0.75rem;
}

.top-left-link {
  position: absolute;
  top: -20%;
  left: 20%;
}

.create-pro-btn {
  position: relative;
}

.project-link {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.lead-list_item {
  text-decoration: none;
}
