/* .sidebar_container {
  padding-top: 2rem;
  background-color: #2f4050;
  color: #808080;
  max-width: 215px;
  width: 100%;
  min-height: 100vh;
}

.sidebar li {
  width: 100%;
  padding: 1rem;
}

.sidebar li:hover {
  background-color: #293846;
  border-left: 5px solid rgb(3, 97, 3);
}
.sidebar li:hover a {
  color: #fff;
}

.sidebar li > * {
  text-decoration: none;
  color: #a7b1c2;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #efefef;
  list-style-type: none;
  padding: 1.5rem 0;
  font-size: 1.1rem;
}

.navbar li {
    margin: 0 1.5rem;
}

.navbar li > * {
  text-decoration: none;
  color: rgb(108,117,125);
}

.link-side {
  display: block;
}

.link-side:hover {
  color: #93a5c5;
}

.Layout {
  display: flex;
  position: relative;
  width: 100%;
}

.Authpage { */
/* background-color: var(--color-light-grey); */
/* }

.logout-btn {
  position: absolute;
  right: 1rem;
}

.logout-color {
  color: red !important;
}
.logout-color:hover {
  color: rgb(110, 6, 6) !important;
}

.outlet {
  max-width: calc(100% - 338px);
  width: 100%;
}

.main-content-section {
  padding: 0 0 0 30px;
}

.icon-margin {
  margin-right: 0.5rem;
} */

/* common form input css start */

.zu_common_form_input_content {
  position: relative;
}
.zu_common_form_input_content .zu_common_form_input_label {
  color: #4b4279;
  display: block;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 5px;
}
.zu_common_form_input_content .zu_common_form_input {
  border-radius: 0px;
  background: #ffffff;
  font-family: "Proxima-Nova-Regular";
  color: #212121;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  font-size: 16px;
  padding: 16px 12px;
  width: 100%;
  outline: 0;
  /* border-color: transparent; */
  /* border-radius: 16px; */
  /* color: #5f0166; */
}

.zu_email_text {
  color: #7a7a7a !important;
}
.zu_common_form_input_content .zu_common_form_input::placeholder {
  color: #b3b8bd;
}
.zu_common_form_input_content .zu_common_form_input_btn {
  right: 25px;
  top: 50%;
  border: 0;
  width: 21px;
  height: 21px;
  background: transparent;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.zu_common_form_input_content .zu_common_form_input_btn svg {
  width: 21px;
  height: 21px;
}

.zu_common_form_input_content select.zu_common_form_input {
  background-image: url(../images/BottomArrow.svg);
  filter: hue-rotate(260deg);
  background-size: 16px 9px;
  background-repeat: no-repeat;
  background-position: right 16px center;
  padding-right: 46px;
  box-shadow: none;
  border-color: #e7e6e9 !important;
}
.zu_common_form_input_content.zu_common_form_button_input_content
  .zu_common_form_input {
  padding-right: 55px;
}

.zu_common_form_check_box label {
  position: relative;
  color: #4b4279;
  font-family: "Proxima-Nova-Bold";
  font-size: 20px;
  padding-left: 32px;
  cursor: pointer;
}

.zu_common_form_check_box label::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  outline: 2px solid #4b4279;
  background: #fff;
  outline-offset: 0;
}
.zu_common_form_check_box input:checked ~ label::before {
  left: 5px;
  top: 10px;
  width: 10px;
  height: 10px;
  outline: 5px solid #4b4279;
}

.zu_common_form_input_content .zu_common_form_input_check_box_row {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}
.zu_common_form_input_content
  .zu_common_form_input_check_box_row
  .zu_common_form_check_box {
  margin: 0 10px;
}

/* common form input css end */

/* sidebar css start */

.zu_sidebar_bg_overlay {
  position: fixed;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d17cc;
  z-index: 999;
  opacity: 73%;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  transition: all 0.3s ease-in-out;
  display: none;
}

.zu_open_sidebar .zu_sidebar_bg_overlay {
  left: 0;
}

.zu_sidebar_section {
  position: fixed;
  left: 0px;
  top: 0px;
  background: #ffffff;
  max-width: 275px;
  width: 100%;
  min-height: 100vh;
  /* box-shadow: 0px 4px 20px 0px #25265e1a; */
  border-right: 1px solid #e2e2e2;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}
.zu_sidebar_content .zu_sidebar_logo {
  padding: 15px 30px;
}
.zu_sidebar_content .zu_sidebar_logo a {
  display: block;
  height: 55px;
}
.zu_sidebar_content .zu_sidebar_logo a svg {
  width: 100%;
  height: 100%;
}
.zu_sidebar_content .zu_sidebar_nav {
  list-style: none;
  padding: 0px;
  margin: 0px;
  max-height: calc(100vh - 85px);
  min-height: calc(100vh - 85px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link {
  padding: 18px 30px;
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-family: "Proxima-Nova-Regular";
  background: transparent;
  border: 0;
  width: 100%;
  position: relative;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link
  .zu_sidebar_link_pandding_msg {
  background: #f00000;
  min-width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 20px;
  color: #ffffff;
  position: absolute;
  left: 22px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 5px;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link span {
  margin-left: 16px;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link svg {
  width: 26px;
  height: 26px;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link svg path {
  transition: all 0.2s ease-in-out;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link.active,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_item .zu_sidebar_link:hover,
.zu_sidebar_bottom_link .active {
  background: #5f0166;
  color: #ffffff;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path,
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path {
  fill: #ffffff;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path[fill="#6ec49a"],
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path[fill="#6ec49a"] {
  fill: #6ec49a;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path[stroke="#000000"],
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path[stroke="#000000"] {
  stroke: #ffffff;
}
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link.active
  svg
  path[stroke="#6ec49a"],
.zu_sidebar_content
  .zu_sidebar_nav
  .zu_sidebar_item
  .zu_sidebar_link:hover
  svg
  path[stroke="#6ec49a"] {
  stroke: #6ec49a;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link {
  margin-top: auto;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link a,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link button {
  padding: 15px 30px;
  display: block;
  color: #000000;
  font-size: 15px;
  font-family: "Proxima-Nova-Bold";
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;
}
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link a:hover,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link button:hover,
.zu_sidebar_content .zu_sidebar_nav .zu_sidebar_bottom_link .active {
  background: #5f0166;
  color: #ffffff;
}

.zu_top_header_toggal_close_btn {
  background: transparent;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 25px;
  top: 29px;
  background: #4b4279;
  border-radius: 3px;
  padding: 8px;
}
.zu_top_header_toggal_close_btn svg {
  width: 100%;
  height: 100%;
}

/* sidebar css end */

/* top header css start */

.zu_top_header_section .zu_top_header_logo {
  max-width: 175px;
  width: 100%;
}
.zu_top_header_section .zu_top_header_toggal_btn {
  margin-left: auto;
  width: 30px;
  height: 22px;
  background: transparent;
  border: 0;
  position: relative;
}
.zu_top_header_section .zu_top_header_toggal_btn span,
.zu_top_header_section .zu_top_header_toggal_btn span::before,
.zu_top_header_section .zu_top_header_toggal_btn span::after {
  background: #5f0166;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  display: block;
}
.zu_top_header_section .zu_top_header_toggal_btn span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.zu_top_header_section .zu_top_header_toggal_btn span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
.zu_top_header_section .zu_top_header_logo,
.zu_top_header_section .zu_top_header_toggal_btn {
  display: none;
}

.zu_top_header_section {
  background: #ffffff;
  width: calc(100% - 275px);
  box-shadow: 0px 4px 20px 0px #25265e1a;
  padding: 20px 30px;
  margin-left: auto;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.zu_top_header_section .zu_top_header_user_dropdown_menu {
  padding-left: 30px;
  position: relative;
}
.zu_top_header_section .zu_top_header_user_dropdown_menu::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 108px;
  background: #f1f1f5;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 0px;
  border-radius: 0px;
  position: relative;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown
  p {
  color: #25265e;
  margin-bottom: 0px;
  font-family: "Proxima-Nova-Regular";
  margin-right: 27px;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown
  p
  span {
  color: #5f0166;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown
  img {
  box-shadow: 0px 2px 5px 0px #00000063;
  border-radius: 45px;
  min-width: 45px;
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.zu_top_header_section
  .zu_top_header_user_dropdown_menu
  .zu_top_header_user_dropdown::after {
  border: 0px !important;
  margin: 0px !important;
  background-image: url(../images/BottomArrow.svg);
  background-size: 12px 7px;
  height: 7px;
  width: 13px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}
.zu_top_header_section .zu_top_header_icon_menu {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0px 30px 0px 0px;
  margin: 0px;
}
.zu_loginas-btn {
  color: #5f0166;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
  border: 0;
  background: transparent;
}
.zu_login_btn {
  margin-right: 0.5rem;
}
.zu_top_header_section .zu_top_header_icon_menu .zu_top_header_icon_menu_item {
  margin: 0px 17px;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item:first-child {
  margin-left: 0px;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item:last-child {
  margin-right: 0px;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link {
  text-decoration: none;
  position: relative;
  display: block;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  background: transparent;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link::after {
  display: none;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link:focus {
  box-shadow: none;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link.zu_top_header_msg_unread::before {
  content: "";
  position: absolute;
  top: 0px;
  right: -5px;
  width: 5.27px;
  height: 5.27px;
  border-radius: 10px;
  background: #6ec49a;
}
.zu_top_header_section
  .zu_top_header_icon_menu
  .zu_top_header_icon_menu_item
  .zu_top_header_icon_menu_link
  svg {
  width: 25px;
  /* height: 25px; */
}
.zu_top_header_icon_menu_dropdown .dropdown-menu {
  padding: 0;
  margin-top: 8px;
  min-width: 350px;
}
.zu_top_header_icon_menu_dropdown .dropdown-menu::before {
  content: "";
  border-top: 6px solid #ffffff00;
  border-right: 6px solid #ffffff00;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid #ffffff00;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  right: 5px;
  top: -12px;
}
.zu_top_header_icon_menu_dropdown .dropdown-menu::after {
  content: "";
  border-top: 7px solid #ffffff00;
  border-right: 7px solid #ffffff00;
  border-bottom: 7px solid #cecece;
  border-left: 7px solid #ffffff00;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  right: 4px;
  top: -14px;
  z-index: -1;
}

.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_dropdown_header {
  padding: 8px 15px;
  border-bottom: 1px solid #e2e2e2;
}
.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_mark_all_read_btn {
  color: #5f0166;
  background: transparent;
  padding: 0;
  border: 0;
  display: block;
  width: fit-content;
  margin-left: auto;
  font-size: 13px;
  text-decoration: underline;
  font-family: "Proxima-Nova-Regular";
}
.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_dropdown_footer {
  padding: 8px 15px;
  border-top: 1px solid #e2e2e2;
}
.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_notification_dropdown_footer
  .zu_notification_dropdown_footer_link {
  color: #5f0166;
  background: transparent;
  padding: 0;
  border: 0;
  display: block;
  width: fit-content;
  margin-left: auto;
  font-size: 13px;
  text-decoration: underline;
  font-family: "Proxima-Nova-Regular";
}

.zu_top_header_icon_menu_dropdown
  .dropdown-menu
  .zu_top_header_icon_menu_dropdown_content {
  max-height: 250px;
  /* height: 250px; */
  overflow-y: auto;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 20px;
}
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item:last-child {
  border-bottom: 0;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item h4 {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 4px;
  line-height: 1.1;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item p {
  color: #3d3d3d;
  font-size: 12px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 6px;
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item p a {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
}
.zu_top_header_icon_menu_dropdown_content .zu_notification_dropdown_item span {
  color: #3d3d3d;
  font-size: 10px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0;
  line-height: 1.2;
  display: block;
}

.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread {
  background: #2e4765;
}
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  h4,
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  span,
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  p
  a {
  color: #ffffff;
}
.zu_top_header_icon_menu_dropdown_content
  .zu_notification_dropdown_item.zu_notification_unread
  p {
  color: #e3e3e3;
}

.zu_top_header_user_dropdown_menu .zu_top_header_user_dropdown_nav_menu {
  padding: 0;
  overflow: hidden;
  margin-top: 10px;
}
.zu_top_header_user_dropdown_nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.zu_top_header_user_dropdown_nav .zu_top_header_user_dropdown_list {
  border-bottom: 1px solid #00000026;
}
.zu_top_header_user_dropdown_nav .zu_top_header_user_dropdown_list:last-child {
  border-bottom: 0;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  padding: 10px 20px;
  color: #707070;
  text-decoration: none;
  border: 0;
  width: 100%;
  background: transparent;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link
  svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #25265e;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list:last-child
  .zu_top_header_user_dropdown_link,
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list:last-child
  .zu_top_header_user_dropdown_link
  svg {
  color: #d64657;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link:hover {
  color: #ffffff;
  background: #5f0166;
}
.zu_top_header_user_dropdown_nav
  .zu_top_header_user_dropdown_list
  .zu_top_header_user_dropdown_link:hover
  svg {
  color: #ffffff;
  background: #5f0166;
}

/* top header css end */

/* common banner image css start */

.zu_common_banner_content {
  width: calc(100% + 80px);
  height: 350px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 40px 80px;
  margin: -30px -40px 0;
  position: relative;
  z-index: 9;
}
.zu_common_banner_content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000004f;
}
.zu_common_banner_content img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.zu_common_banner_content h1 {
  font-size: 50px;
  color: #ffffff;
  font-family: "Proxima-Nova-Alt-Light";
  margin: 0;
  position: relative;
  z-index: 9;
}

/* common banner image css end */

/* job column css start */

.zu_jobs_column {
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: stretch;
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px #52565c1a;
}
.zu_jobs_column:last-child {
  margin-bottom: 0px;
}
.zu_jobs_column .zu_jobs_column_left_content {
  padding: 30px 50px 35px 26px;
  display: flex;
  align-items: flex-start;
  width: calc(100% - 342px);
}
.zu_jobs_column .zu_jobs_column_left_content .zu_jobs_column_left_user_img {
  min-width: 45px;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 45px;
  /* border: 1px solid #e2e2e2; */
  box-shadow: 0px 2px 5px 0px #00000063;
  margin-right: 15px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text {
  width: 100%;
  max-width: 798px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  h3 {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 0px;
  margin-right: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  span {
  color: #2e4765;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  display: block;
  text-decoration: underline;
  margin-right: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #2e4765;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_name_row
  p
  svg {
  width: 12px;
  height: 16px;
  margin-right: 10px;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_description_text {
  width: 100%;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  margin-bottom: 20px;
}
.zu_jobs_column_brief_pre {
  white-space: pre-wrap;
  font-family: "Proxima-Nova-Regular";
}
.readmore_link {
  color: #5f0166;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
}

.readmore_link2 {
  color: #d4d4d4;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
}

.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_label_row {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  flex-wrap: wrap;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_label_row
  .zu_common_label {
  margin: 0 10px;
  min-width: 160px;
  margin-bottom: 10px;
}
.zu_jobs_column .zu_jobs_column_left_content .zu_jobs_column_price_more_detail {
  max-width: 160px;
  width: 100%;
  margin-left: auto;
}
.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_price_more_detail
  p,
.zu_jobs_column_price_time_text p {
  color: #2e4765;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 14px;
  text-align: right;
  line-height: 1.2;
}
.zu_jobs_column_price_time_text {
  display: none;
}
.zu_jobs_column_price_more_detail_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  color: #2e4765;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  text-decoration: none;
  line-height: 1.2;
}

.zu_find_jobs_column_more_detail {
  border: none;
  background: transparent;
  padding: 5px;
  background: #e7cee9a3;
  color: #5f0166 !important;
  border-radius: 3px;
}

.zu_find_jobs_column_more_detail:hover {
  color: #ffffff !important;
  background: #5f0166;
}

.zu_jobs_column_price_more_detail_btn svg {
  width: 18px;
  height: 10px;
  margin-left: 19px;
}

.zu_jobs_column .zu_jobs_column_right_content {
  padding: 30px 50px 35px 70px;
  max-width: 342px;
  width: 100%;
  border-left: 2px solid #f5f6fa;
  position: relative;
}
.zu_jobs_column
  .zu_jobs_column_right_content
  .zu_jobs_column_right_payment_text {
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  color: #2e4765;
  display: block;
  margin-bottom: 10px;
}
.zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_price_text {
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  color: #2e4765;
  margin-bottom: 0;
}

.zu_jobs_column .zu_jobs_column_right_content .zu_admin_cancel_text {
  color: #a41212;
  display: flex;
  justify-content: center;
}

.zu_job_status_crew_name {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 16px 7px !important;
}

.zu_job_crew_name {
  font-size: 12px !important;
  font-family: "Proxima-Nova-Regular";
}

.zu_jobs_column .zu_jobs_column_right_content .zu_jobs_column_right_text_row {
  margin-bottom: 15px;
}

.zu_jobs_column
  .zu_jobs_column_left_content
  .zu_jobs_column_name_description_text
  .zu_jobs_column_price_more_detail_btn {
  display: none;
}

.zu_jobs_column .zu_find_jobs_column_right_content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.zu_jobs_column .zu_find_jobs_column_right_content .zu_common_btn,
.zu_jobs_column .zu_jobs_column_right_content .zu_common_btn {
  padding: 16px 16px 17px;
  font-family: "Proxima-Nova-Bold";
}

.zu_cancel_link {
  background: transparent;
  border: none;
  text-decoration: underline;
}
.zu_jobs_column
  .zu_find_jobs_column_right_content
  .zu_jobs_column_right_payment_text {
  margin-top: 5px;
  text-align: center;
  margin-bottom: 10px;
}
.zu_jobs_column
  .zu_find_jobs_column_right_content
  .zu_jobs_column_price_more_detail_btn {
  justify-content: center;
  margin-top: auto;
}
.zu_jobs_column .zu_jobs_column_bookmark_btn {
  position: absolute;
  right: 25px;
  top: 36px;
  width: 15px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  outline: 0;
}
.zu_jobs_column .zu_jobs_column_bookmark_btn svg {
  width: 15px;
  height: 19px;
}
.zu_jobs_column
  .zu_jobs_column_bookmark_btn
  ~ .zu_jobs_column_right_text_row
  .zu_jobs_column_right_payment_text {
  max-width: calc(100% - 25px);
}
/* job column css end */

/* leads column css start */

.zu_leads_data_column {
  background: #2e4765;
  border: 1px solid #dde4ea;
  padding: 34px 53px 49px 38px;
  display: flex;
}
.zu_leads_data_column .zu_leads_data_column_left_text {
  max-width: 230px;
  min-width: 200px;
  width: 100%;
  border-right: 1px solid #dde4ea;
  padding-right: 20px;
}
.zu_leads_data_column_date_text {
  margin-bottom: 16px;
}
.zu_leads_data_column_date_text p {
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 4px;
  color: #ffffff;
  line-height: 1.2;
}
.zu_leads_data_column_date_text span {
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  color: #ffffff;
}
.zu_leads_data_column_type_text {
  margin-bottom: 20px;
}
.zu_leads_data_column_type_text p {
  color: #ffffff;
  font-size: 15px;
  font-family: "Proxima-Nova-Bold";
  line-height: 1.2;
  margin-bottom: 5px;
}
.zu_leads_data_column_type_text p:last-child {
  margin-bottom: 0px;
}
.zu_leads_data_column_lead_contact h4 {
  color: #ffffff;
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
}
.zu_leads_data_column_lead_contact .zu_leads_data_column_lead_contact_text {
  margin-bottom: 15px;
}
.zu_leads_data_column_lead_contact
  .zu_leads_data_column_lead_contact_text:last-child {
  margin-bottom: 0;
}
.zu_leads_data_column_lead_contact .zu_leads_data_column_lead_contact_text p {
  color: #ffffff;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
  line-height: 1.2;
}
.zu_leads_data_column_lead_contact
  .zu_leads_data_column_lead_contact_text
  span {
  color: #ffffff;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  word-break: break-word;
}

.zu_leads_data_column_lead_contact_text .zu_leads_data_column_lead_note_text {
  color: #5dff5d;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  word-break: break-word;
  font-style: italic;
}

.zu_leads_data_column .zu_leads_data_column_right_text {
  max-width: 100%;
  width: 100%;
  padding-left: 30px;
  display: flex;
}
.zu_leads_data_column
  .zu_leads_data_column_right_text
  .zu_leads_data_column_event_info {
  width: 100%;
  padding-right: 30px;
}
.zu_leads_data_column_event_info .zu_leads_data_column_event_info_heading {
  color: #f5f6fa;
  font-size: 24px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 20px;
}
.zu_leads_data_column_event_info .zu_leads_data_column_event_info_row {
  display: flex;
  align-items: flex-start;
}
.zu_leads_data_column_event_info
  .zu_leads_data_column_event_info_row
  .zu_leads_data_column_event_info_left_text {
  max-width: 160px;
  width: 100%;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content {
  margin-bottom: 25px;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content:last-child {
  margin-bottom: 0;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content
  h4 {
  color: #f5f6fa;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_leads_data_column_event_info_left_text
  .zu_leads_data_column_event_info_left_text_content
  p {
  color: #f5f6fa;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_leads_data_column_event_info
  .zu_leads_data_column_event_info_row
  .zu_leads_data_column_event_info_detail_text {
  width: 100%;
  padding-left: 30px;
}
.zu_leads_data_column_event_info_detail_text h4 {
  color: #f5f6fa;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
}
.zu_leads_data_column_event_info_detail_text pre {
  color: #f5f6fa;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 13px;
  letter-spacing: 1.26px;
  line-height: 1.56;
  white-space: pre-wrap;
}

.zu_leads_data_column_event_info_detail_text pre:last-child {
  margin-bottom: 0px;
}

.zu_leads_data_column_event_info_detail_text_svg {
  font-size: 3rem;
  color: #ffffff;
}

.zu_leads_data_column
  .zu_leads_data_column_right_text
  .zu_leads_data_column_btn_content {
  max-width: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn_heading {
  color: #f5f6fa;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  text-align: right;
  margin-bottom: 15px;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn {
  margin-top: auto;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn p {
  color: #f5f6fa;
  font-size: 16px;
  font-family: "Proxima-Nova-Thin";
  text-align: right;
  margin-bottom: 0;
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn .zu_common_btn {
  font-family: "Proxima-Nova-Bold";
}
.zu_leads_data_column_btn_content .zu_leads_data_column_btn p ~ .zu_common_btn {
  margin-top: 17px;
}

.zu_leads_data_column_btn_content .zu_leads_data_column_edit_archiv_btn {
  max-width: 188px;
  width: 100%;
  margin-left: auto;
  margin-top: 16px;
}
.zu_leads_data_column_btn_content
  .zu_leads_data_column_edit_archiv_btn
  .zu_common_btn {
  margin-bottom: 17px;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
}
.zu_leads_data_column_btn_content
  .zu_leads_data_column_edit_archiv_btn
  .zu_common_btn:last-child {
  margin-bottom: 0px;
}

/* leads column css end */

/* profile user detail css start */

.zu_profile_user_detail_section {
  margin-bottom: 40px;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_profile_user_detail_content .zu_profile_user_detail_row {
  display: flex;
  align-items: flex-start;
}
.zu_profile_user_detail_photo_container {
  min-width: fit-content;
}
.zu_profile_user_detail_content .zu_profile_user_detail_photo {
  height: 150px;
  width: 150px;
  min-width: 150px;
  border-radius: 150px;
  box-shadow: 0 4px 8px 1px #25265e35;
  overflow: hidden;
  margin-right: 25px;
  position: relative;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_photo
  .zu_open_image_picker {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #0000005c;
  border-radius: 7px;
  transform: translate(-50%, -50%) scale(0.75);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_photo
  .zu_open_image_picker
  label {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0 10px 20px 0 #00000035;
  display: flex;
  min-width: 30px;
  padding: 4px;
  cursor: pointer;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_photo:hover
  .zu_open_image_picker {
  transform: translate(-50%, -50%) scale(1);
  pointer-events: all;
  opacity: 1;
}

.zu_profile_user_detail_content .zu_profile_user_detail_photo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.zu_profile_user_detail_content .zu_profile_user_detail_text_content {
  margin-right: auto;
  width: 100%;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_sub_heading {
  letter-spacing: 0.11px;
  color: #000000;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  position: relative;
  /* padding: 2px 15px;
  background: #5f036630;
  border-radius: 100px;
  border: 1px solid #5f0366; */
  margin-bottom: 10px;
  /* display: block;
  width: fit-content;
  text-transform: uppercase; */
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_heading {
  letter-spacing: 0.3px;
  color: #000000;
  font-size: 32px;
  margin-bottom: 10px;
  font-family: "Proxima-Nova-Bold";
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_row
  svg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
  /* color: #ee9617; */
  color: #2e4765cc;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_row
  svg:last-child {
  margin-right: 0;
  color: #2e4765cc;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_profile_user_detail_rating_text {
  color: #9b9b9b;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  margin-bottom: 0;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text_content
  .zu_common_btn {
  margin-top: 15px;
  max-width: 237px;
  width: 100%;
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
}

.zu_profile_user_detail_content .zu_profile_user_detail_text {
  width: 100%;
}

.zu_profile_user_detail_content
  .zu_profile_user_detail_text
  .zu_profile_user_detail_text_row {
  display: flex;
  align-items: flex-start;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_text
  .zu_profile_user_detail_text_row
  .zu_profile_user_detail_message_text {
  max-width: 615px;
  width: 100%;
  margin-left: 15px;
}
.zu_profile_user_detail_content .zu_profile_user_detail_message_text h3 {
  color: #354052;
  font-size: 26px;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 10px;
}
.zu_profile_user_detail_content .zu_profile_user_detail_message_text p {
  color: #ffffff;
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0px;
  padding: 20px;
  background: #818181;
  border-radius: 10px;
  min-height: 206px;
  max-height: 206px;
  overflow-y: auto;
}
.zu_profile_user_detail_content .zu_profile_user_detail_about_text {
  margin-top: 20px;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_about_text
  .zu_profile_user_detail_about_text_heading {
  position: relative;
  letter-spacing: 0.16px;
  color: #000000;
  font-family: "Proxima-Nova-Bold";
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}
.zu_profile_user_detail_content
  .zu_profile_user_detail_about_text
  .zu_profile_user_detail_about_text_heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 43px;
  height: 2px;
  background: #000000;
}
.zu_public_bio_pre_content {
  white-space: pre-wrap;
}
.zu_profile_user_detail_content .zu_profile_user_detail_about_text p {
  letter-spacing: 0.9px;
  color: #000000;
  font-family: "Proxima-Nova-Regular";
  font-size: 16px;
  margin-bottom: 0;
}
.zu_profile_user_detail_content.zu_profile_user_detail_message
  .zu_profile_user_detail_about_text {
  margin-top: 44px;
}

.zu_profile_user_detail_content .zu_profile_user_bottom_btn_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  max-width: 550px;
  width: 100%;
  margin-top: 30px;
}
.zu_profile_user_detail_content .zu_profile_user_bottom_btn_row .zu_common_btn {
  /* border-radius: 8px; */
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
  /* padding: 19px 19px 16px; */
  /* line-height: 1.2; */
  max-width: 100%;
}
/* .zu_profile_user_detail_content
  .zu_profile_user_bottom_btn_row
  .zu_profile_user_send_msg_btn {
  font-size: 20px;
}
.zu_profile_user_detail_content
  .zu_profile_user_bottom_btn_row
  .zu_profile_user_offer_btn {
  font-size: 35px;
} */

.zu_send_offer_btn {
  margin-left: 0 !important;
}

/* profile user detail css end */

.zu_public_bio_applicant_detail {
  white-space: pre-wrap;
}

/* showcase gallery css start */

.zu_showcase_section {
  margin-bottom: 40px;
}

.zu_showcase_content .react-photo-gallery--gallery > div {
  display: grid !important;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 70px 60px;
}

.zu_showcase_content .react-photo-gallery--gallery > div img {
  width: 100%;
  height: 225px;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 !important;
  object-fit: cover;
  -o-object-fit: cover;
}

/* showcase gallery css end */

/* upload image section css start */

.zu_upload_image_section {
  margin-bottom: 40px;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_upload_image_content .zu_upload_image_heading {
  color: #4b4279;
  display: block;
  font-family: "Proxima-Nova-Bold";
  font-size: 22px;
  margin-bottom: 20px;
}
.zu_upload_image_dropbox .zu_upload_image_dropbox_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 63px 30px 59px;
  border: 2px dashed #c2cdda;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_shap {
  width: 61px;
  height: 49px;
  margin-bottom: 20px;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_heading {
  color: #555a6b;
  font-family: "Proxima-Nova-Bold";
  font-size: 18px;
  margin-bottom: 14px;
  text-align: center;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_text {
  color: #666666;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  margin-bottom: 14px;
  text-align: center;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_btn {
  background: #5f0166;
  border: 1px solid #5f0166;
  color: #f5f6fa;
  font-family: "Proxima-Nova-Bold";
  font-size: 16px;
  padding: 12px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  max-width: 150px;
  width: 100%;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 100%;
  background: #39003d;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.zu_upload_image_dropbox
  .zu_upload_image_dropbox_content
  .zu_upload_image_dropbox_content_btn:hover::before {
  width: 100%;
}

.zu_upload_image_dropbox .zu_upload_image_priview_row {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
  margin-top: 24px;
}
.zu_upload_image_dropbox
  .zu_upload_image_priview_row.zu_upload_image_priview_no_data {
  margin-top: 0;
}
.zu_upload_image_priview_column {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.zu_upload_image_priview_column::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000035;
}
.zu_upload_image_priview_column .zu_upload_image_priview_column_img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  background: #00000035;
}
.zu_images_close_btn {
  width: 20px;
  height: 20px;
  border: 2px solid #d64657;
  border-radius: 4px;
  background: #ffffff;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zu_images_close_btn:hover {
  background: #d64657;
}

.zu_images_close_btn svg {
  width: 10px;
  min-width: 10px;
  height: 10px;
}
.zu_images_close_btn svg path {
  fill: #d64657;
  transition: all 0.3s ease-in-out;
}
.zu_images_close_btn:hover svg path {
  fill: #ffffff;
}

/* upload image section css end */

/* common modal css start */

.zu_common_modal_bg,
.zu_sidemenu_bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d17cc;
  z-index: 999;
  opacity: 73%;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.zu_common_modal {
  /* position: fixed;
  left: 0;
  top: 0; */
  /* background: #FFFFFF; */
  /* z-index: 9999; */
  /* max-height: calc(100vh - 30px); */
  /* overflow-y: auto; */
}
.zu_common_modal .zu_common_modal_content {
  /* position: fixed;
  left: 0;
  top: 0; */
  background: #ffffff;
  /* z-index: 9999; */
  /* max-height: calc(100vh - 30px); */
  /* overflow-y: auto; */
  /* box-shadow: 0 10px 40px 0 #878f971a; */
  position: relative;
}
.zu_common_center_modal {
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* padding: 63px; */
  /* width: 100%;
  padding: 15px; */
  /* max-height: 100vh; */
  /* height: 100vh; */
  /* overflow-y: auto; */
  /* display: flex;
  align-items: flex-start; */
}
.zu_common_center_modal .zu_common_modal_content {
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* padding: 63px;
  border-radius: 30px; */
  /* max-width: 683px; */
  width: 100%;
  margin: auto;
  border: 1px solid #e2e2e2;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
}

.zu_common_modal .zu_common_modal_checkbox {
  display: flex;
  align-items: flex-start;
}
.zu_common_modal .zu_common_modal_checkbox input {
  min-width: 18px;
  width: 18px;
  height: 18px;
  border: 1px solid #efbff5;
  border-radius: 2px;
  background: #f8eff9;
  border-radius: 2px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 12px 10px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 3px;
}
.zu_common_modal .zu_common_modal_checkbox input:checked {
  background-image: url(../images/CheckBoxCheck.svg);
}
.zu_common_modal .zu_common_modal_checkbox label {
  padding-left: 10px;
  color: #9e9e9e;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  cursor: pointer;
}
.zu_common_modal .zu_common_modal_checkbox label a {
  color: #9e9e9e;
}

/* .zu_leave_review_modal .zu_common_modal_content {
  padding: 53px 63px 63px;
  border-radius: 30px;
} */
.zu_common_modal .zu_common_modal_heading_paragraph {
  border-bottom: 1px solid #707070;
  padding-bottom: 17px;
  margin-bottom: 11px;
}
.zu_common_modal .zu_common_modal_heading {
  color: #4b4279;
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_common_modal .zu_common_modal_heading_paragraph .zu_common_modal_paragraph {
  font-size: 16px;
  color: #343a40;
  font-family: "Proxima-Nova-Regular";
  margin: 0;
  text-align: center;
}

.zu_common_lead_purchase .zu_credit_card_container {
  justify-content: space-between;
}
.zu_common_lead_purchase .zu_credit_card_container .zu_card_input {
  width: 100%;
}
.zu_common_lead_purchase .zu_credit_card_container label {
  min-width: 55px;
}

.zu_common_lead_purchase .zu_credit_card_container #cvc {
  padding: 0 0 0 8px;
}
.zu_send_offer_paragraph {
  text-align: left !important;
}
.zu_common_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph
  a {
  color: #4b4279;
}
.zu_common_modal .zu_common_modal_second_heading {
  font-size: 30px;
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_common_modal .zu_common_modal_sub_heading {
  font-size: 19px;
  color: #5f0166;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_common_modal .zu_common_modal_rating_star_row {
  margin: 20px 0 25px;
}
.zu_common_modal .zu_common_modal_rating_star_row .react-stars span {
  line-height: 26px;
  margin-right: 10px;
}
.zu_common_modal .zu_common_modal_rating_star_row .react-stars span:last-child {
  margin-right: 0px;
}
.zu_common_modal .zu_common_modal_text_input .zu_common_modal_textarea {
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  background: #ffffff;
  padding: 16px 12px;
  font-size: 16px;
  width: 100%;
  max-height: 146px;
  height: 146px;
  line-height: 1.2;
  outline: 0;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none;
  resize: none;
}

.zu_extra_hour_div {
  margin-top: 12px;
}

.zu_add_extra_hour_input_label {
  color: #4b4279;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
}

.zu_add_custom_or_add_extra_hour_div {
  display: flex;
  justify-content: center;
}

.zu_add_hour_or_btn {
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
}

.zu_add_custom_or_add_extra_hour_btn {
  color: #5f0166 !important;
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  margin-top: 8px;
  z-index: 9;
  border-radius: 4px;
  overflow: hidden;
  background: #e7cee9a3;
  border: 0;
  padding: 4px;
}
.zu_card_input_for_custom_amount {
  margin-top: 20px;
}

.zu_extra_amount_payable_text {
  color: #4b4279;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  margin-top: 10px;
  margin-bottom: -12px;
}
.zu_extra_amount_payable_text_small {
  color: #4b4279;
  font-size: 15px;
  font-family: "Proxima-Nova-Regular";
  margin-top: 10px;
  margin-bottom: -12px;
}

/* font-size: 16px;
font-family: "Proxima-Nova-Regular";
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
background: #2e4765cc;
border-radius: 0px;
padding: 18px;
width: 100%;
text-decoration: none;
text-transform: uppercase;
border: 0;
position: relative;
z-index: 9;
border-radius: 4px;
overflow: hidden; */

.zu_add_extra_hour_input {
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  background: #ffffff;
  padding: 16px 12px;
  font-size: 16px;
  width: 100%;
  line-height: 1.2;
  outline: 0;
  font-family: "Proxima-Nova-Regular";
  box-shadow: none;
}

.zu_common_modal
  .zu_common_modal_text_input
  .zu_common_modal_textarea::placeholder {
  color: #d2d0da;
}
.zu_common_modal .zu_modal_common_btn_row {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}
.zu_find_job_modal_btn_each > button {
  max-width: 250px !important;
}
.zu_common_modal .zu_common_link_btn {
  color: #5f0166;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  background: transparent;
  border: 0;
  margin-right: auto;
  text-decoration: underline;
}
.zu_common_modal .zu_modal_common_btn_row .zu_modal_common_submit_btn {
  max-width: 275px;
  width: 100%;
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
}

.zu_review_complete_modal .zu_common_modal_heading_paragraph {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 7px;
}
.zu_review_complete_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_heading {
  margin-bottom: 14px;
}

.zu_common_modal .zu_common_modal_other_detail_row,
.zu_sidemenu_content .zu_sidemenu_other_detail_row {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: #ffffff;
  box-shadow: 0 4px 6px 0 #acb7c233;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
}

.zu_common_modal_other_detail_row_finalize {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: #ffffff;
  padding: 18px;
  margin-top: 1rem;
}

.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-top: 25px; */
}
/* .zu_common_modal .zu_common_modal_other_detail_row .zu_common_modal_other_detail_column:nth-child(1),
.zu_common_modal .zu_common_modal_other_detail_row .zu_common_modal_other_detail_column:nth-child(2),
.zu_sidemenu_content .zu_sidemenu_other_detail_row .zu_sidemenu_other_detail_column:nth-child(1),
.zu_sidemenu_content .zu_sidemenu_other_detail_row .zu_sidemenu_other_detail_column:nth-child(2){
  margin-top: 0px;
} */
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column::before,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 1px;
  background: #b4b7cc66;
}
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column:nth-child(1):before,
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column:nth-child(3):before,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column:nth-child(1):before,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column:nth-child(3):before {
  display: none;
}
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  svg,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  svg {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_icon {
  width: 40px;
}
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_text {
  text-align: center;
  width: 150px;
}

.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  h3,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_text
  h3 {
  font-size: 18px;
  color: #000000;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 5px;
}
.zu_common_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  p,
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column
  .zu_sidemenu_other_detail_text
  p {
  font-size: 20px;
  color: #061058;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}

.zu_modal_close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  padding: 10px;
  background: #5f0166;
  box-shadow: 0 10px 20px #f8eff9;
  border-radius: 9px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
}
.zu_modal_close_btn svg {
  width: 15px;
  height: 15px;
}

/* .zu_apply_modal .zu_common_modal_content {
  padding: 48px 64px 41px;
} */

.zu_apply_modal .zu_common_modal_content .zu_common_modal_heading {
  text-align: center;
  max-width: 464px;
  margin: 0 auto 10px;
}
.zu_apply_modal .zu_common_modal_content .zu_common_modal_paragraph {
  text-align: center;
  color: #4b4279;
  font-size: 22px;
  font-family: "Proxima-Nova-Regular";
  margin: 0 auto 20px;
  max-width: 464px;
}
.zu_apply_modal
  .zu_common_modal_content
  .zu_common_modal_text_input
  .zu_common_modal_textarea {
  max-height: 183px;
  height: 183px;
}
.zu_apply_modal .zu_common_modal_content .zu_common_btn {
  margin: 30px auto 0;
  max-width: 387px;
  font-family: "Proxima-Nova-Bold";
  /* padding: 26px 19px;
  border-radius: 8px;
  text-transform: capitalize; */
}

/* .zu_job_offer_modal .zu_common_modal_content {
  padding: 54px 50px 45px;
} */

.zu_job_offer_modal .zu_common_modal_heading {
  color: #2e4765;
  font-size: 28px;
  max-width: 400px;
  margin: 0 auto 20px;
  text-align: center;
}
.zu_job_offer_modal .zu_common_label {
  display: block;
  max-width: 162px;
  width: 100%;
  margin: 0 auto 10px;
}
.zu_job_offer_modal .zu_common_modal_second_heading {
  font-size: 24px;
  margin-bottom: 5px;
  text-align: center;
}
.zu_job_offer_modal .zu_common_modal_sub_heading {
  font-size: 16px;
  margin-bottom: 0px;
  display: block;
  text-align: center;
}
.zu_job_offer_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  h3 {
  font-size: 14px;
  margin-bottom: 7px;
}
.zu_job_offer_modal
  .zu_common_modal_other_detail_row
  .zu_common_modal_other_detail_column
  .zu_common_modal_other_detail_text
  p {
  font-size: 23px;
}
.zu_job_offer_modal .zu_common_modal_other_detail_row {
  margin-bottom: 30px;
}
.zu_job_offer_modal .zu_common_modal_checkbox {
  /* max-width: 400px; */
  margin-bottom: 30px;
}
.zu_job_offer_modal .zu_modal_common_btn_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}
.zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn {
  /* font-size: 20px; */
  font-family: "Proxima-Nova-Bold";
  /* padding: 26px 19px;
  margin-bottom: 25px;
  border-radius: 8px; */
}
.zu_job_offer_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-bottom: 0px;
}
.zu_job_offer_modal
  .zu_modal_common_btn_row
  .zu_common_btn.zu_modal_common_accetp_decline_btn {
  text-transform: capitalize;
}

.zu_send_offer_modal .modal-dialog {
  max-width: 850px;
  /* padding: 56px 80px; */
}
.zu_send_offer_modal .zu_common_modal_heading_paragraph {
  padding-bottom: 0;
  margin-bottom: 20px;
  border: 0;
}
.zu_send_offer_modal .zu_common_modal_heading {
  font-size: 32px;
  color: #2e4765;
}
.zu_send_offer_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph {
  font-size: 20px;
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
}
.zu_send_offer_modal .zu_common_modal_send_offer_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.zu_send_offer_modal
  .zu_common_modal_send_offer_row
  .zu_common_modal_send_offer_column {
  padding: 0 20px;
}
.zu_send_offer_modal
  .zu_common_modal_send_offer_row
  .zu_common_modal_how_work_column {
  max-width: 52.28%;
  width: 100%;
}
.zu_send_offer_modal
  .zu_common_modal_send_offer_row
  .zu_common_modal_the_offer_column {
  max-width: 47.72%;
  width: 100%;
}

.zu_common_modal_how_work_content {
  margin-bottom: 30px;
}
.zu_common_modal_send_offer_column .zu_common_modal_send_offer_heading {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
  font-size: 26px;
  margin-bottom: 15px;
}
.zu_common_modal_send_offer_card {
  display: flex;
  justify-content: space-between;
}
.zu_send_offer_show_card_btn {
  font-size: 14px;
  color: #ffffff;
  background: #2e4765cc;
  border-radius: 0px;
  padding: 4px 8px;
  border: 0;
  position: relative;
  border-radius: 4px;
  margin-bottom: 15px;
}
.zu_send_offer_show_card_btn:hover {
  opacity: 0.8;
}

.zu_send_offer_add_card_btn {
  font-size: 14px;
  color: #ffffff;
  background: #5f0166;
  border-radius: 0px;
  padding: 4px 8px;
  border: 0;
  position: relative;
  border-radius: 9px;
  max-height: 48px;
  max-width: 45px;
  margin-left: 7px;
}
.zu_send_offer_add_card_btn svg {
  width: 100%;
}
.zu_send_offer_add_card_btn:hover {
  opacity: 0.8;
}
.blink_me {
  animation: blinker 1s linear 2;
}

@keyframes blinker {
  50% {
    box-shadow: 0 0 20px #8b8000;
  }
}

.blink_me_two {
  animation: blinkertwo 1s linear 2;
}

@keyframes blinkertwo {
  50% {
    box-shadow: 0 0 20px #8b8000;
  }
}
.zu_common_modal_send_offer_column .zu_common_modal_send_offer_step_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item {
  margin-bottom: 20px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item:last-child {
  margin-bottom: 0px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item
  .zu_common_modal_send_offer_step_text {
  color: #000000;
  font-size: 20px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_send_offer_step_list
  .zu_common_modal_send_offer_step_item
  .zu_common_modal_send_offer_step_text
  span {
  color: #6ec49a;
  font-family: "Proxima-Nova-Bold";
}
/* .zu_common_modal_send_offer_column
  .zu_common_modal_card_info_content
  .zu_common_modal_send_offer_heading {
  margin-bottom: 16px;
} */
.zu_common_modal_send_offer_column .zu_common_modal_card_info_content {
  margin-bottom: 30px;
}
.zu_common_modal_send_offer_column .zu_common_modal_the_offer_content {
  background: #ffffff;
  box-shadow: 0 4px 6px 0 #acb7c233;
  border-radius: 10px;
  padding: 30px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_text {
  margin-bottom: 18px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_text
  h3 {
  color: #000000;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: "Proxima-Nova-Bold";
  text-transform: uppercase;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_text
  p {
  color: #061058;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_disabled_text
  h3,
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_disabled_text
  p {
  color: #b3b8bd;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_disabled_text
  p {
  font-size: 16px;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_note {
  font-size: 15px;
  color: #2e4765;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
}
.zu_common_modal_send_offer_column
  .zu_common_modal_the_offer_content
  .zu_common_modal_the_offer_note
  span {
  font-family: "Proxima-Nova-Bold";
}
.zu_send_offer_modal .zu_modal_common_btn_row {
  margin-top: 30px;
}
.zu_send_offer_modal .zu_modal_common_btn_row .zu_send_offer_modal_cancel_btn {
  max-width: 220px;
  margin-right: 35px;
}
.zu_send_offer_modal .zu_modal_common_btn_row .zu_common_btn {
  /* padding: 27px 19px 26px; */
  font-family: "Proxima-Nova-Bold";
  text-transform: capitalize;
}

.zu_send_msg_modal {
  padding: 38px 15px 15px;
}
/* .zu_send_msg_modal .zu_common_modal_content {
  padding: 54px 50px 60px;
} */
.zu_send_msg_modal .zu_common_modal_heading {
  /* color: #000000; */
  /* font-family: "Proxima-Nova-Bold";
  font-size: 30px; */
  text-align: center;
  max-width: calc(100% - 40px);
  margin: 0 auto 10px;
}
.zu_send_msg_modal .zu_common_modal_heading span {
  color: #6ec49a;
  display: block;
}
.zu_send_msg_modal .zu_common_modal_paragraph {
  color: #000000;
  font-family: "Proxima-Nova-Regular";
  font-style: italic;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
}
.zu_send_msg_modal .zu_common_modal_text_input {
  margin-top: 30px;
}
/* .zu_send_msg_modal .zu_common_modal_text_input .zu_common_modal_textarea {
  border-color: #2e4765;
  padding: 11px 21px;
} */
.zu_send_msg_modal
  .zu_common_modal_text_input
  .zu_common_modal_textarea::placeholder {
  color: #b3b8bd;
}
.zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn {
  text-transform: capitalize;
  font-family: "Proxima-Nova-Bold";
  margin-right: 26px;
}
.zu_send_msg_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-right: 0px;
}
.zu_send_msg_modal .zu_modal_common_btn_row .zu_send_msg_modal_cancel_btn {
  max-width: 160px;
}

.zu_purchase_lead_msg_modal .zu_common_modal_content {
  /* padding: 48px 80px 50px; */
  max-width: 700px;
}
.zu_purchase_lead_msg_modal .zu_common_modal_content > div,
.zu_purchase_lead_msg_modal .zu_common_card_input,
.zu_purchase_lead_msg_modal .zu_common_card_input > *:nth-child(2) {
  width: 100%;
}
.delete_container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.delete_container svg {
  width: 80px;
  color: #d64657;
  border: 3px solid #d64657;
  border-radius: 50%;
  padding: 15px;
}
.zu_purchase_lead_msg_modal .zu_common_card_input {
  border-color: #2e4765cc;
}
.zu_purchase_lead_msg_modal .zu_common_modal_heading_paragraph {
  padding: 0;
  margin-bottom: 30px;
  border: 0;
}
.zu_purchase_lead_msg_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_heading {
  color: #2e4765;
  margin-bottom: 30px;
  /* font-size: 45px; */
  text-align: center;
}
.zu_purchase_lead_msg_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph {
  color: #2e4765;
  margin-bottom: 16px;
  font-size: 18px;
  font-family: "Proxima-Nova-Regular";
}
.zu_purchase_lead_msg_modal
  .zu_common_modal_heading_paragraph
  .zu_common_modal_paragraph:last-child {
  margin-bottom: 0px;
}
/* .zu_purchase_lead_msg_modal .zu_modal_common_btn_row {
  margin-top: 52px;
} */
.zu_lead_btn {
  display: flex;
  flex-direction: column;
  padding: 9px 18px !important;
}

.zu_extra_hour_btn {
  height: 60px;
}

.zu_charge {
  font-size: small;
  font-family: "Proxima-Nova-Regular";
}

.zu_purchase_lead_msg_modal
  .zu_modal_common_btn_row
  .zu_send_msg_modal_cancel_btn {
  max-width: 178px;
}
.zu_purchase_lead_msg_modal
  .zu_modal_common_btn_row
  .zu_send_msg_modal_approve_btn {
  max-width: 178px;
}
.zu_purchase_lead_msg_modal .zu_modal_common_btn_row .zu_common_btn {
  font-family: "Proxima-Nova-Bold";
  margin-right: 30px;
}
.zu_purchase_lead_msg_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-right: 0;
}

.zu_common_text_modal .modal-dialog {
  max-width: 500px;
}
.zu_common_text_modal .zu_common_modal_heading {
  text-align: center;
}
.zu_common_text_modal .zu_common_modal_paragraph {
  font-size: 22px;
  text-align: center;
  font-family: "Proxima-Nova-Bold";
  color: #4b4279;
  margin: 0;
}
.zu_common_text_modal .zu_modal_common_btn_row {
  margin-top: 30px;
}
.zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn {
  text-transform: capitalize;
  /* border-radius: 8px; */
  /* padding: 27px 19px; */
  /* font-size: 20px; */
  font-family: "Proxima-Nova-Bold";
}

.zu_offer_accepted_modal .zu_common_modal_paragraph {
  max-width: 400px;
  margin: 0 auto;
}

/* .zu_decline_offer_modal .zu_common_modal_heading {
  max-width: 330px;
  margin: 0 auto;
} */
.zu_common_text_modal .zu_modal_common_btn_row .zu_decline_offer_back_btn {
  max-width: 153px;
}
.zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn {
  margin-right: 20px;
}
.zu_common_text_modal .zu_modal_common_btn_row .zu_common_btn:last-child {
  margin-right: 0px;
}

.zu_common_heading_paragraph_modal .modal-dialog {
  max-width: 500px;
}
.zu_common_heading_paragraph_modal .zu_common_modal_heading {
  color: #2e4765;
  text-align: center;
  margin-bottom: 16px;
}
.zu_common_heading_paragraph_modal .zu_common_modal_paragraph {
  color: #2e4765;
  font-size: 20px;
  text-align: center;
  margin-bottom: 18px;
  line-height: 1.2;
}
.zu_common_heading_paragraph_modal .zu_modal_common_btn_row {
  max-width: 225px;
  margin: 30px auto 0;
}
.zu_credit_card_modal .zu_modal_common_btn_row {
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}
.zu_common_heading_paragraph_modal .zu_modal_common_btn_row .zu_common_btn {
  /* padding: 16px 19px; */
  font-family: "Proxima-Nova-Bold";
  /* font-size: 20px; */
}
.zu_common_heading_paragraph_modal
  .zu_common_heading_paragraph_modal_text
  .zu_common_modal_paragraph {
  margin-bottom: 20px;
}
.zu_common_heading_paragraph_modal
  .zu_common_heading_paragraph_modal_text
  .zu_common_modal_paragraph:last-child {
  margin-bottom: 0px;
}

.modal_first_paragraph {
  font-family: "Proxima-Nova-Bold" !important;
  font-size: 18px !important;
}

.div_for_extra_hour_btn {
  text-align: end;
}
.extra_hour_btn {
  text-align: end;
  border-radius: 4px;
  padding: 2px;
  background-color: #4b4279;
  border: 1px solid #4b4279;
  color: #ffffff;
  margin-top: 5px;
}

/* common modal css end */

/* sidemenu modal css start */

.zu_sidemenu_bg {
  background: #171d17cc;
  opacity: 38%;
}

.zu_sidemenu_section {
  /* position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999; */
  padding: 110px 15px 15px;
  width: 100%;
  /* height: 100vh; */
  /* max-height: calc(100vh - 106px); */
  /* overflow-y: auto; */
  /* max-width: 683px;
  margin-left: auto; */
}

.zu_sidemenu_content {
  background: #ffffff;
  border-radius: 10px;
  /* max-width: 683px;
  width: 100%; */
  padding: 72px 30px 30px;
  position: relative;
  margin-left: auto;
}
.zu_sidemenu_content .zu_sidemenu_user_img {
  position: absolute;
  left: 50%;
  top: -65px;
  transform: translateX(-50%);
  border: 1px solid #4364f7;
  border-radius: 500px;
  width: 130px;
  height: 130px;
}
.zu_sidemenu_content .zu_sidemenu_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: none;
}
.zu_sidemenu_content .zu_sidemenu_label_other_name_row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.zu_sidemenu_content .zu_sidemenu_label_other_name_row .zu_sidemenu_label_text {
  background: #efbff5;
  color: #000000;
  padding: 8px 35px 6px;
  font-size: 12px;
  font-family: "Proxima-Nova-Bold";
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
}
.zu_sidemenu_content
  .zu_sidemenu_label_other_name_row
  .zu_sidemenu_other_name_text {
  margin-left: auto;
  margin-bottom: 0;
  color: #2e4765;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  text-decoration: underline;
}
.zu_sidemenu_content .zu_sidemenu_heading {
  color: #5f0166;
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 27px;
}
.zu_sidemenu_content .zu_sidemenu_paragraph {
  color: #707070;
  font-size: 20px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 30px;
}
.zu_sidemenu_content .zu_common_label {
  padding: 8px 35px 6px;
  border: 1px solid #f8eff9;
}

.zu_sidemenu_content .zu_sidemenu_other_detail_row {
  margin-top: 30px;
}
.zu_sidemenu_content
  .zu_sidemenu_other_detail_row
  .zu_sidemenu_other_detail_column {
  margin-top: 0;
}
.zu_sidemenu_content .zu_sidemenu_bottom_btn {
  margin: 30px auto 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
  /* max-width: 387px; */
}
.zu_sidemenu_content .zu_sidemenu_bottom_btn .zu_common_btn {
  /* border-radius: 8px;
  padding-top: 27px;
  padding-bottom: 26px;
  font-size: 20px;
  font-family: "Proxima-Nova-Bold"; */
}

.zu_my_jobs_no_longer_msg_content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.zu_my_jobs_no_longer_msg {
  display: flex;
  flex-direction: column;
  background-color: #b50000;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;
  color: #ffffff;
}
.zu_my_jobs_no_longer_msg_content .find-available-job-btn {
  margin-top: 10px;
  width: fit-content;
  padding: 10px;
  font-size: 14px;
  background: #6ec49a;
}
.zu_my_jobs_no_longer_msg_content .find-available-job-btn:hover {
  background: #58a17e !important;
}

.zu_cancelled_job_in_findjob {
  background: #a41212;
  color: #ffffff;
  font-size: 17px;
  padding: 3px 3px;
  width: 100%;
  border-radius: 5px;
}

/* sidemenu modal css end */

.creditcard-detail_container {
  max-height: 13rem;
  overflow: auto;
  padding: 0 3px;
}

.creditcard_detail {
  display: flex;
  background: #ffffff;
  padding: 8px 14px;
  border-radius: 10px;
  margin: 5px 0;
  border: 1px solid #e2e2e2;
  align-items: center;
}

/* .creditcard_detail-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #000000;
} */

.creditcard_detail-radio {
  cursor: pointer;
  margin-left: auto;
  width: 32px;
  height: 20px;
}

.creditcard_detail .creditcard_detail_img {
  min-width: 50px;
  width: 50px;
  height: 30px;
  margin-right: 15px;
}
.creditcard_detail .creditcard_detail_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.creditcard_detail .creditcard_detail_number_date h4 {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
}
.creditcard_detail .creditcard_detail_number_date p {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.creditcard_detail .creditcard_detail_delete_btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a4121233;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 2px 1px #00000012;
  cursor: pointer;
  margin-left: auto;
}
.creditcard_detail .creditcard_detail_delete_btn svg {
  width: 20px;
  height: 20px;
  color: #a41212;
}

.zu_applicant_detail_modal {
  padding: 30px;
}

.zu_applicant_detail_content .zu_applicant_detail_img_name_content {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_img {
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  object-fit: cover;
  box-shadow: 0px 2px 5px 0px #00000063;
  margin-right: 15px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_user_name {
  font-size: 22px;
  font-family: "Proxima-Nova-Bold";
  color: #000000;
  margin-bottom: 10px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_business_name {
  font-size: 14px;
  font-family: "Proxima-Nova-Regular";
  color: #000000;
  margin-bottom: 2px;
  display: block;
}

/* .zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating
  svg {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  color: #2e4765cc;
}
.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating
  svg:last-child {
  margin-right: 0;
  color: #2e4765cc;
}

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_stars_rating_text {
  color: #9b9b9b;
  font-family: "Proxima-Nova-Regular";
  font-size: 14px;
  margin-bottom: 6px;
} */

.zu_applicant_detail_content
  .zu_applicant_detail_img_name_content
  .zu_applicant_detail_user_name_btn
  .zu_applicant_detail_view_website_link {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  text-decoration: underline;
}

.zu_applicant_detail_star_rating_content {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-top: 4px;
}
.zu_applicant_detail_star_rating_content .zu_applicant_detail_star_ratings {
  display: flex;
  gap: 4px;
}
.zu_applicant_detail_star_rating_content .zu_applicant_detail_star_ratings svg {
  width: 15px;
  height: 15px;
}
.zu_applicant_detail_star_rating_content
  .zu_applicant_detail_star_rating_display {
  color: #9b9b9b;
  font-family: "Proxima-Nova-Regular";
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 4px;
}

.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_heading {
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  color: #000000;
  margin-bottom: 10px;
}
.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_message_heading {
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  color: #000000;
  margin-bottom: 8px;
}
.zu_applicant_detail_content .zu_applicant_detail_user_about_text {
  margin-bottom: 20px;
}
.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_paragraph {
  margin-bottom: 0;
  overflow: hidden;
  font-family: "Proxima-Nova-Regular";
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}

.zu_applicant_detail_user_message {
  margin-bottom: 0;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-word;
  font-family: "Proxima-Nova-Regular";
}

.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_paragraph
  > * {
  white-space: break-spaces;
}
.zu_applicant_detail_content
  .zu_applicant_detail_user_about_text
  .zu_applicant_detail_user_about_paragraph
  p:last-child {
  margin-bottom: 0;
}
.zu_applicant_detail_content .zu_applicant_detail_user_more_detail_link {
  margin-bottom: 20px;
}
.zu_applicant_detail_content .zu_applicant_detail_user_more_detail_link a {
  color: #5f0166;
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0;
  text-decoration: underline;
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.zu_applicant_detail_content .zu_applicant_detail_bottom_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin: 0 -10px;
}
.zu_applicant_detail_content .zu_applicant_detail_bottom_btn .zu_common_btn {
  max-width: 50%;
  width: 100%;
  margin: 0 10px;
}

.zu_website_sendOffer_btn_togther {
  display: flex;
  align-items: center;
}

.zu_website_sendOffer_btn_togther div {
  width: 100%;
  margin-left: 1.5rem;
}
.invoice_button_section {
  display: flex;
}
.bussiness_invoice_btn {
  width: 200px !important;
  right: 15px;
}
.crew_invoice_btn {
  width: 150px !important;
}

/* ---------------------- */

.zu_loginas-btn {
  padding: 10px 20px;
  color: #000000;
  background: #00000008;
  border-radius: 12px;
  text-decoration: none;
  font-size: 15px;
}

.zu_loginas-btn[disabled] {
  background: #5f0166;
  color: #ffffff;
  pointer-events: none;
}
